import {  call, put, takeLatest } from "redux-saga/effects";
import { setAssociatedStatus, setError, setListLoading, setLoading, setOnboardingSummaryData, setProductList, setProductSizingDetails, setTechpackProductSizing } from "./reducer";
import { addToast } from "store/toaster/reducer";
import { TOAST_TYPES } from "enum/enums";
import { handleErrorMessage } from "lib/utils";
import {
	HANDLE_GET_PRODUCTS_LIST,
	HANDLE_GET_TECHPACK_PRODUCT_SIZING,
	HANDLE_ONBOARDING_SUMMARY,
	HANDLE_PRODUCT_ASSOCIATED_CALCULATION,
	HANDLE_PRODUCT_ONBOARDING,
	HANDLE_PRODUCT_SIZING_DETAILS,
} from "./action.types";
import productService from "services/productservice";
import { HANDLE_GET_UPLOADED_FILE_URL } from "store/techpack/action.types";
import { onBoardedData } from "interface/interface";
import fitModelService from "services/fitModelService";
import { setFitModelsData } from "store/fitModel/reducer";
import { setTechpackData } from "store/techpack/reducer";
import techpackService from "services/techpackservice";
import groupService from "services/groupService";
import { setGroupList } from "store/group/reducer";

function* fetchProductList(action: { type: string; payload?: boolean }): Generator {
	yield put(setLoading(true));
	try {
		if (action.payload) {
			const fitModelRes: any = yield call(fitModelService.getListFitModels);
			yield put(setFitModelsData(fitModelRes?.data ?? []));
			const techPackRes: any = yield call(techpackService.getTechpackList);
			yield call(fetchTechpackProductSizing);
			yield put(setTechpackData(techPackRes?.data));
			const groupRes: any = yield call(groupService.getGroupList);
			yield put(setGroupList(groupRes?.data));
		}
		const res: any = yield call(productService.getProductList);
		yield put(setProductList(res?.data?.productInfo));
	} catch (error) {
		yield put(addToast({ type: TOAST_TYPES.ERROR, messsage: handleErrorMessage(error) }));
	} finally {
		yield put(setLoading(false));
	}
}

function* fetchTechpackProductSizing(): Generator {
	try {
		const res: any = yield call(productService.getProductTechpackSizing);
		yield put(setTechpackProductSizing(res?.data));
	} catch (error) {
		yield put(addToast({ type: TOAST_TYPES.ERROR, messsage: handleErrorMessage(error) }));
	}
}

function* fetchAssociateCalculation(action: { type: string; payload: any }): Generator {
	try {
		yield put(setAssociatedStatus("In-Progress"));
		yield call(productService.associatedCalculation, action.payload);
		yield put(setAssociatedStatus("Done"));
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
		yield put(setAssociatedStatus("Error"));
	}
}

function* getProductSizingDetails(action: { type: string; payload: string }): Generator {
	yield put(setError(false));
	try {
		yield put(setListLoading(true));
		const res: any = yield call(productService.getProductSizingDetails, action.payload);
		if (res?.data?.techpackFileId) {
			yield put({ type: HANDLE_GET_UPLOADED_FILE_URL, payload: { techpackFileId: res?.data?.techpackFileId, fileName: res?.data?.fileName } });
		}
		yield put(setProductSizingDetails(res?.data));
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
		yield put(setError(true));
	} finally {
		yield put(setListLoading(false));
	}
}

function* getProductOnBoard(action: { type: string; payload: onBoardedData[] }): Generator {
	try {
		yield call(productService.onboardProduct, action.payload);
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
	}
}
function* getOnboardingSummary(): Generator {
	yield put(setListLoading(true));
	try {
		const res: any = yield call(productService.getOnboardingSummary);
		yield put(setOnboardingSummaryData(res?.data));
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
	} finally {
		yield put(setListLoading(false));
	}
}
function* productSaga() {
	yield takeLatest(HANDLE_GET_PRODUCTS_LIST, fetchProductList);
	yield takeLatest(HANDLE_GET_TECHPACK_PRODUCT_SIZING, fetchTechpackProductSizing);
	yield takeLatest(HANDLE_PRODUCT_ASSOCIATED_CALCULATION, fetchAssociateCalculation);
	yield takeLatest(HANDLE_PRODUCT_SIZING_DETAILS, getProductSizingDetails);
	yield takeLatest(HANDLE_PRODUCT_ONBOARDING, getProductOnBoard);
	yield takeLatest(HANDLE_ONBOARDING_SUMMARY, getOnboardingSummary);
}

export default productSaga;
