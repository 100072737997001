import styles from "./FitModel.module.scss";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import CustomTableMui from "component/customTableMui/CustomTableMui";
import { columns } from "component/customTableMui/tableData";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useDispatch } from "react-redux";
import { setEditToFitmodel, setFitModelsData } from "store/fitModel/reducer";
import { deepCopy, generateUuid, makeMeasurementRowSelected, measurmentDataMod, modifyMeasurementRow, sumOfNumbersFromId } from "lib/utils";
import { HANDLE_DEBOUNCE_UPDATE_FIT_MODEL_MEASUREMENT, HANDLE_DELETE_FIT_MODEL, HANDLE_UPDATE_FIT_MODEL_NAME } from "store/fitModel/action.types";
import { BGCOLOURS, COLOURS, MeasurementRowOptions, MUI_TABLE_TYPE, regex } from "lib/Constants";
import CustomDialog from "component/CustomDialog";
import CustomButton from "atoms/customButton/CustomButton";
import Trash from "assets/icons/trash";
import Capsule from "atoms/capsule/Capsule";
import { MEASUREMENT_CONVERSION_TYPE } from "enum/enums";

interface FitModelTable {
	data: Record<string, any>;
	showDelete?: boolean;
}
const FitModelTable = ({ data, showDelete = true }: FitModelTable) => {
	const [header, setHeader] = useState<string>(data?.name ?? "");
	const { data: fitModelData, deleteLoading, editToFitmodel } = useSelector((state: RootState) => state.fitModel);
	const { measurementList } = useSelector((state: RootState) => state.measurement);
	const IndexOf = useRef<number>(-1);
	const [deleteModal, setDeleteModal] = useState<boolean>(false);
	const dispatch = useDispatch();
	const [measurementUnit, setMeasurementUnit] = useState<MEASUREMENT_CONVERSION_TYPE | string>(MEASUREMENT_CONVERSION_TYPE.CM);
	const [rowOptions, setRowOptions] = useState<any[]>(deepCopy(MeasurementRowOptions));
	const [rowsArray, setRowsArray] = useState<{ name: string; id: string }[]>([]);
	const inputRef = useRef<HTMLInputElement>(null);
	const copyFitModelData = useMemo(() => deepCopy(fitModelData), [fitModelData]);
	const fitModelId = useMemo(() => data?.id, [data]);
	
	const handleMeasurementUnit = useCallback(
		(value: MEASUREMENT_CONVERSION_TYPE | string) => {
			if (IndexOf.current === -1) {
				updateIndexOf();
			}			
			setMeasurementUnit(value);
			if (copyFitModelData[IndexOf.current].measurements) {
				copyFitModelData[IndexOf.current].measurements = measurmentDataMod(copyFitModelData[IndexOf.current].measurements, value);
				dispatch(setFitModelsData([...copyFitModelData]));
			}
		},
		[IndexOf.current, copyFitModelData],
	);

	const handleInitialRowsOptions = useCallback(() => {
		const keys: string[] = data?.measurements ? Object.keys(data?.measurements) ?? [] : [];
		if (keys.length) {
			setRowsArray(modifyMeasurementRow({ key: "presentData", rowArray: rowsArray, measurementData: data?.measurements }));
			setRowOptions([...makeMeasurementRowSelected({ options: "keysMatch", rowOptions, beRowOptions: keys, beValues: data?.measurements })]);
		} else {
			setRowsArray(modifyMeasurementRow({ key: "noData", rowArray: rowsArray, measurementList }));
			setRowOptions([...makeMeasurementRowSelected({ options: "new", rowOptions })]);
		}
	}, [data, rowOptions, rowsArray, measurementList]);

	const updateMeasurementValue = useCallback(
		(name: string, value: null | number) => {
			copyFitModelData[IndexOf.current].measurements = { ...copyFitModelData[IndexOf.current].measurements, [name]: value };
			dispatch(setFitModelsData([...copyFitModelData]));
			return copyFitModelData;
		},
		[fitModelData, IndexOf.current],
	);
	const handleHeader = (value: string) => {
		if (value.length > 50) {
			return;
		}
		setHeader(value);
	};
	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = e.target;
			if (IndexOf.current === -1) {
				updateIndexOf();
			}
			if (+value < 0 || !regex.test(value)) {
				return;
			}
			const abc = updateMeasurementValue(name, +value);
			const payload = {
				fitmodelId: fitModelId,
				measurements: measurementUnit === MEASUREMENT_CONVERSION_TYPE.CM ? abc[IndexOf.current].measurements : measurmentDataMod(abc[IndexOf.current].measurements, MEASUREMENT_CONVERSION_TYPE.CM),
			};
			dispatch({ type: HANDLE_DEBOUNCE_UPDATE_FIT_MODEL_MEASUREMENT, payload });
		},
		[IndexOf.current, fitModelData, measurementUnit],
	);

	const updateName = useCallback(() => {
		const payload = { fitmodelId: fitModelId, fitmodelName: header };
		dispatch({ type: HANDLE_UPDATE_FIT_MODEL_NAME, payload });
		dispatch(setEditToFitmodel(""));
		if (IndexOf.current === -1) {
			updateIndexOf();
		}
		copyFitModelData[IndexOf.current].name = header;
		dispatch(setFitModelsData([...copyFitModelData]));
	}, [inputRef.current, editToFitmodel, header]);

	const handleEditName = useCallback(() => {
		setTimeout(() => {
			inputRef.current?.focus();
		});
	}, [inputRef.current, editToFitmodel, header]);

	const updateIndexOf = useCallback(() => {
		IndexOf.current = fitModelData.findIndex((el) => el.id === fitModelId) ?? -1;
	}, [fitModelData]);

	const handleSelectChange = (name: string, index: number, skip: boolean) => {
		if (skip) {
			rowsArray.push({ name, id: generateUuid() });
			setRowsArray([...rowsArray]);
			setRowOptions([...makeMeasurementRowSelected({ options: "selection", rowOptions, key: name })]);
		} else {
			const prevKey = rowsArray[index].name;
			rowsArray[index].name = name;
			if (IndexOf.current === -1) {
				updateIndexOf();
			}
			updateMeasurementValue(prevKey, null);
			setRowsArray([...rowsArray]);
			setRowOptions([...makeMeasurementRowSelected({ options: "selection", rowOptions, key: prevKey, key2: name })]);
		}
	};


	useEffect(() => {
		if (data) {
			handleInitialRowsOptions();
		}
	}, []);
	useEffect(() => {
		if (data) {
			setHeader(data?.name);
		}
	}, [editToFitmodel, data]);
	useEffect(() => {
		setDeleteModal(false);
	}, [fitModelData]);
	
	return (
		<>
			<div className={`${styles.fitModal__grid}`}>
				<div className={`${styles.fitModal__gridHeader} flex justify-between`}>
					<span className={`${styles.fitModal__gridName} flex align-center`} style={{ backgroundColor: BGCOLOURS[sumOfNumbersFromId(fitModelId) % 5] }}>
						{editToFitmodel !== fitModelId ? (
							<Capsule colour={COLOURS[sumOfNumbersFromId(fitModelId) % 5]} bgColor={BGCOLOURS[sumOfNumbersFromId(fitModelId) % 5]} name={header} />
						) : (
							<input style={{ color: COLOURS[sumOfNumbersFromId(fitModelId) % 5] }} ref={inputRef} value={header} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleHeader(e.target.value)} />
						)}
					</span>
					<div className={`${styles.fitModal__editBtn} flex align-center`}>
						{editToFitmodel === fitModelId ? (
							<span
								role={`${!header.trim() ? `none` : `button`}`}
								className={`${styles.fitModal__gridEdit} ${!header.trim() ? "disabled" : ""}`}
								onClick={() => {
									updateName();
								}}
							>
								Done
							</span>
						) : (
							<span
								role="button"
								className={styles.fitModal__gridEdit}
								onClick={() => {
									dispatch(setEditToFitmodel(fitModelId));
									handleEditName();
								}}
							>
								Edit
							</span>
						)}
						{showDelete && (
							<span
								role="button"
								className={`${styles.fitModal__gridDelete}`}
								onClick={() => {
									dispatch(setEditToFitmodel(""));
									setDeleteModal(true);
								}}
							>
								<Trash />
							</span>
						)}
					</div>
				</div>
				<CustomTableMui
					type={MUI_TABLE_TYPE.FITMODEL}
					columns={columns}
					data={data?.measurements}
					rows={rowOptions}
					handleChange={handleChange}
					handleSelectChange={handleSelectChange}
					rowArray={rowsArray}
					handleMeasurementUnit={handleMeasurementUnit}
					measurementUnit={measurementUnit}
				/>
			</div>
			<CustomDialog open={deleteModal}>
				<div className={`${styles.fitModal__confirm}`}>
					<h2>Are you sure want to delete?</h2>
					<p>Please click on yes for delete {data?.name || `this`}.</p>
					<div className={`${styles.fitModal__confirmBtn} flex justify-center align-center`}>
						<CustomButton className="rounded outline" buttonText="No" disabled={deleteLoading} handleFunc={() => setDeleteModal(false)} />
						<CustomButton buttonText="Yes" className="rounded" loading={deleteLoading} disabled={deleteLoading} handleFunc={() => dispatch({ type: HANDLE_DELETE_FIT_MODEL, payload: fitModelId })} />
					</div>
				</div>
			</CustomDialog>
		</>
	);
};
export default memo(FitModelTable);
