
import product from "../../assets/images/product.svg";
import category from "../../assets/images/list.svg";
import color from "../../assets/images/colors-icon-gray.svg";
import Style from "../../assets/images/style-icon-gray.svg";
import img from "../../assets/images/img-icon.svg";
import desc from "../../assets/images/description-icon.svg";
import base from "../../assets/images/status-icon.svg";
import calculator from "../../assets/images/calculator-icon.svg";
import fit from "../../assets/images/fit-modal.svg";


export const product_Columns = [
  {
    id: "srNo",
    label: "",
    minWidth: 38,
    type: "",
    checkbox:true,
  },
  {
    id: "productName",
    label: "Product",
    minWidth: 200,
    type: "name",
    icon: product
  },
  {
    id: "category",
    label: "Category",
    minWidth: 200,
    type: "",
   icon: category
  },
  {
    id: "color",
    label: "Color",
    minWidth: 200,
    type: "pills",
    icon:color
  },
  {
    id: "style",
    label: "Style",
    minWidth: 200,
    type: "pills",
    icon:Style,
  },
  {
    id: "techpack",
    label: "Tech pack",
    minWidth: 200,
    type: "dropdown",
    icon:img,
  },
  {
    id: "fitModel",
    label: "Fit model",
    minWidth: 200,
    type: "dropdown",
    icon:fit,
  },
  {
    id: "baseSize",
    label: "Base size",
    minWidth: 200,
    type: "dropdown",
    icon:base,
  },
  {
    id: "measurementUsed",
    label: "Measurement used",
    minWidth: 200,
    type: "pills",
    icon:desc,
  },
  // {
  //   id: "associated",
  //   label: "Associated",
  //   minWidth: 200,
  //   type: "",
  // },
  {
    id: "easeCalculations",
    label: "Ease calculations",
    minWidth: 200,
    type: "",
    icon:calculator,
  },
];
