export const HANDLE_GET_TRYON_LIST ="HANDLE_GET_TRYON_LIST";
export const HANDLE_TRY_ON_FILE_UPLOAD = "HANDLE_TRY_ON_FILE_UPLOAD";
export const HANDLE_GET_TRY_ON_IMAGES_APPROVE = "HANDLE_GET_TRY_ON_IMAGES_APPROVE";
export const HANDLE_GET_SAMPLE_IMAGES = "HANDLE_GET_SAMPLE_IMAGES";
export const HANDLE_GET_TRY_ON_TRIALS = "HANDLE_GET_TRY_ON_TRIALS";
export const HANDLE_GET_TRY_ON_PRODUCT_DETAILS = "HANDLE_GET_TRY_ON_PRODUCT_DETAILS";
export const HANDLE_GET_TRY_ON_REVIEW_IMAGES = "HANDLE_GET_TRY_ON_REVIEW_IMAGES";
export const HANDLE_GET_TRY_ON_FINE_TUNE = "HANDLE_GET_TRY_ON_FINE_TUNE";
export const HANDLE_GET_TRY_ON_APPOVE_AND_FINE_TUNE = "HANDLE_GET_TRY_ON_APPOVE_AND_FINE_TUNE"
export const HANDLE_TRY_ON_ONBAORD = "HANDLE_TRY_ON_ONBAORD";
export const HANDLE_TRY_ON_ONBAORD_ELIGIBILITY ="HANDLE_TRY_ON_ONBAORD_ELIGIBILITY";
export const HANDLE_DELETE_TRY_ON_UPLOADED_FILE ="HANDLE_DELETE_TRY_ON_UPLOADED_FILE";
export const HANDLE_TRY_ON_UPLOADED_FILE ="HANDLE_TRY_ON_UPLOADED_FILE";
export const HANDLE_TRY_ON_SUBMIT ="HANDLE_TRY_ON_SUBMIT";