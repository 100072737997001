import { createSlice } from "@reduxjs/toolkit";
import { TryOnImageUploadInterface, TryOnReviewImagesInterface } from "interface/interface";

export interface TryOnInitialStateInterface {
	loading: boolean;
	data: Record<string, any>[];
	error: boolean;
	selectedData: string[];
	tryOnUploadImage: TryOnImageUploadInterface[];
	fileUploading: boolean;
	approveLoading: boolean;
	sampleImages: { filename: string; method: string; selected: boolean; url: string }[];
	detailsLoading: boolean;
	detailsError: boolean;
	detailsData: {
		[key: string]: {
			[name: string]: string;
		};
	};
	reviewImages: TryOnReviewImagesInterface;
	progressLoading: boolean;
	tryonTrialRequestSent: boolean;
	isApproved: boolean;
	isFineTuned: boolean;
	checkEligibilityLoading: boolean;
	showModal:boolean;
	selectedProduct:string;
	submitLoading:boolean
}
const InitialState: TryOnInitialStateInterface = {
	loading: false,
	data: [],
	error: false,
	selectedData: [],
	tryOnUploadImage: [],
	fileUploading: false,
	approveLoading: false,
	sampleImages: [],
	detailsLoading: false,
	detailsError: false,
	detailsData: {},
	reviewImages: {},
	progressLoading: false,
	tryonTrialRequestSent: false,
	isApproved: false,
	isFineTuned: false,
	checkEligibilityLoading: false,
	showModal:false,
	selectedProduct:"",
	submitLoading:false
};

const tryOnSlice = createSlice({
	name: "tryOn",
	initialState: InitialState,
	reducers: {
		setLoading: (state, { payload }) => {
			state.loading = payload;
		},
		setData: (state, { payload }) => {
			state.data = payload;
		},
		setError: (state, { payload }) => {
			state.error = payload;
		},
		setSelectedData: (state, { payload }) => {
			state.selectedData = payload;
		},
		setTryOnUploadImage: (state, { payload }) => {
			state.tryOnUploadImage = payload;
		},
		setFileUplading: (state, { payload }) => {
			state.fileUploading = payload;
		},
		setApproveLoading: (state, { payload }) => {
			state.approveLoading = payload;
		},
		setSampleImages: (state, { payload }) => {
			state.sampleImages = payload;
		},
		setDetailsLoading: (state, { payload }) => {
			state.detailsLoading = payload;
		},
		setDetailsError: (state, { payload }) => {
			state.detailsError = payload;
		},
		setDetailsData: (state, { payload }) => {
			state.detailsData = payload;
		},
		setReviewImages: (state, { payload }) => {
			state.reviewImages = payload;
		},
		setProgressLoading: (state, { payload }) => {
			state.progressLoading = payload;
		},
		setTryonTrialRequestSent: (state, { payload }) => {
			state.tryonTrialRequestSent = payload;
		},
		setIsApproved: (state, { payload }) => {
			state.isApproved = payload;
		},
		setIsFineTuned: (state, { payload }) => {
			state.isFineTuned = payload;
		},
		setCheckEligibilityLoading: (state, { payload }) => {
			state.checkEligibilityLoading = payload;
		},
		setShowModal: (state, { payload }) => {
			state.showModal = payload;
		},
		setSelectedProduct: (state, { payload }) => {
			state.selectedProduct = payload;
		},
		setSubmitLoading: (state, { payload }) => {
			state.submitLoading = payload;
		}
	},
});

export const {
	setLoading,
	setData,
	setError,
	setSelectedData,
	setTryOnUploadImage,
	setFileUplading,
	setApproveLoading,
	setSampleImages,
	setDetailsLoading,
	setDetailsError,
	setDetailsData,
	setReviewImages,
	setProgressLoading,
	setTryonTrialRequestSent,
	setIsApproved,
	setIsFineTuned,
	setCheckEligibilityLoading,
	setShowModal,
	setSelectedProduct,
	setSubmitLoading
} = tryOnSlice.actions;
export default tryOnSlice.reducer;
