import React from 'react'
import styles from './MenuCard.module.scss';

interface MenuCardProps{
    title:string;
    imageUrl:string;
    para:string;
    blue?:boolean;
    green?:boolean;
    pink?:boolean;
    purple?:boolean;
    imageEnd?:boolean;
    imageRight?:boolean;

}

const MenuCard = ({title,imageUrl,para,blue,green, pink, purple,imageEnd, imageRight}:MenuCardProps) => {
  return (

    <div className={`${styles.card} flex-wrap justify-between`}>
    <figcaption>
       <h3>{title}</h3>
       <p>{para}</p>

    </figcaption>
    <figure className={`flex  
        ${blue ? styles.blue : ``}
        ${green ? styles.green : ``}
        ${purple ? styles.purple : ``}
        ${pink ? styles.pink : ``}
        ${imageEnd ? `align-end` : `align-center`}
         ${imageRight ? `justify-end ` : `justify-center `}
        `}>
    <img src={imageUrl} alt='menu' />
    </figure>
   </div>
  )
}

export default MenuCard