import { forwardRef, useImperativeHandle } from "react";
import styles from "./Upload.module.scss";
import upload from "assets/images/upload.svg";


interface UploadProps {
	accept?: string;
	handleFileOnChange: (files: File[]) => void;
	subHeading: string;
	isDragAndDrop?: boolean;
	multiple?: boolean;
	id?: string;
}

const Upload = forwardRef<any, UploadProps>((props, ref) => {
	const { accept = "image/*", handleFileOnChange, subHeading, isDragAndDrop = true, multiple = false, id = "fileInput" } = props;

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files) {
			handleFileOnChange(Array.from(files));
			clearFileInput();
		} else {
			console.log("No files selected!");
		}
	};

	function handleDragOver(event: any) {
		event.preventDefault();
		event.dataTransfer.dropEffect = "copy";
	}

	const handleDrop = (event: any) => {
		event.preventDefault();
		const files = event.dataTransfer.files;
		handleFileOnChange(Array.from(files));
		clearFileInput();
	};

	function clearFileInput() {
		const fileInput: any = document.getElementById(id);
		if (fileInput) {
			fileInput.value = "";
		}
	}

	useImperativeHandle(
		ref,
		() => {
			return {
				clearFileInput,
			};
		},
		[],
	);

	return (
		<>
			{isDragAndDrop ? (
				<label role="button" htmlFor={id} className={`${styles.upload} flex align-center justify-center`} onDragOver={handleDragOver} onDrop={handleDrop}>
					<div className={`${styles.upload__info} `}>
						<img src={upload} alt="icon" />
						<h2>
							<span>Click to upload</span> or drag and drop
						</h2>
						<p>{subHeading}</p>
					</div>
					<input multiple={multiple} id={id} name="userFile" type="file" accept={accept} onChange={handleOnChange} style={{ visibility: "hidden", width: "0px", height: "0px", color: "#fff" }} />
				</label>
			) : (
				<label htmlFor={id} className={`flex align-center`}>
					<div className={`${styles.upload__info}`}>
						{/* <img src={upload} alt="icon" /> */}

						<h2>
							<span>{subHeading}</span>
						</h2>

						{/* <p>{subHeading}</p> */}
					</div>
					<input id={id} name="userFile" type="file" accept={accept} onChange={handleOnChange} style={{ visibility: "hidden", width: "0px", height: "0px", color: "#fff" }} />
				</label>
			)}
		</>
	);
});

Upload.displayName = "Upload";
export default Upload;
