
import React, { useState } from "react";
 import ArrowDown from "assets/icons/arrowDown";
import { TextField, Autocomplete } from "@mui/material";
import { CustomSelectProps } from "../../interface/interface";

// const getNameFromValue = (val: string,data:any) => {
//   if(data && data.length){
//     const selectedItem = data?.find((el:any) => el.value === val);
//   return selectedItem ? selectedItem.name : "";
//   }
//   return ""
// };
const CustomSearchDropdown = ({
  placeholder,
  outline,
//   bgGray,
//   bgWhite,
//   bgGreen,
//   outline,
  icon,
//   iconSpace,
  handleChange,
  disabled = false,
  value = "",
  data = [],
  setShowBlank,
  label,
}: CustomSelectProps) => {    
  const [ownValue, setOwnValue] = useState<string | null>(value);
  const handleOnChange = (event: any, newValue: string | null) => {
    const selectedItem = data?.find((el) => el.name === newValue);
    const selectedValue = selectedItem ? selectedItem.value : "";
    setOwnValue(selectedItem?.name); 
    handleChange?.(selectedValue);
    setShowBlank?.(false);

  };

  return (
    <div
      className={`custom-auto-complete ${outline ? `outlined` : ``}`}
    >
       {label && <label className="label">{label}</label>} 
      <Autocomplete
        value={ownValue}
        onChange={handleOnChange}
        options={data?.map((el) => el.name)}
        getOptionDisabled={(option) =>
          data?.find((el) => el.name === option)?.disabled || false
        }
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: icon ? (
                <span role="button" className="select-icon">
                  <img src={icon} alt="icon" />
                </span>
              ) : null,
              endAdornment: (
                <span role="button" className="custom-select__arrow">
                  <ArrowDown />
                </span>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default CustomSearchDropdown;
