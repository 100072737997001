
import { NoteProps } from '../../interface/interface';
import styles from './Note.module.scss';



const Note = ({ title, icon}: NoteProps) => {
    return (<>
        <div className={`${styles.note}`}>
      {icon && <img src={icon} alt='logo' />} {title}
 </div>

    </>)
}
export default Note;