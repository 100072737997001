import React, { useEffect } from "react";
import styles from "./SignupSteps.module.scss";
import Input from "atoms/input/Input";
import CustomButton from "atoms/customButton/CustomButton";
import { SubmitHandler, useForm } from "react-hook-form";
import { UserFormData } from "interface/interface";
import { useDispatch } from "react-redux";
import { HANDLE_USER_REGISTER } from "store/auth/action.types";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { SIGN_UP_TAB_VALUE, TOAST_TYPES } from "enum/enums";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "lib/Constants";
import { setStoreDetails } from "store/auth/reducer";
import { isValidStoreUrl } from "lib/utils";
import { addToast } from "store/toaster/reducer";

const SignupFirstStep = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { email, step, customerId,storeName,websiteUrl,loginLoading } = useSelector((store: RootState) => store.auth);
	const {
		register,
		formState: { errors },
		handleSubmit,
		setError,
		getValues
	} = useForm<UserFormData>({
		defaultValues: {
			name: storeName,
			websiteUrl: websiteUrl
		},
	});
	const onSubmit: SubmitHandler<UserFormData> = (data) => {
		const { websiteUrl, name } = data;
		if(!name.trim()) {
			setError("name", { type: "custom", message: "Please enter a valid name" });
		}  else if(!isValidStoreUrl(websiteUrl.trim())) {
			setError("websiteUrl", { type: "custom", message: "Please enter a valid url" });
		}  else {
			const payload = {
				email,
				websiteUrl,
				name,
				step: SIGN_UP_TAB_VALUE.ONE,
			};
			dispatch(setStoreDetails({storeName:name,websiteUrl}))
			dispatch({ type: HANDLE_USER_REGISTER, payload });
		}
	};

	useEffect(() => {
		if (step === SIGN_UP_TAB_VALUE.ONE) {
			dispatch(addToast({message:"Registered successfully!",type:TOAST_TYPES.SUCCESS}))
			navigate(`${ROUTES.ONBOARDING_SECOND_STEP}?email=${email}&customerId=${customerId}&websiteUrl=${getValues("websiteUrl")}`);
		}
	}, [step, customerId]);
	return (
        <div className={`${styles.steps}`}>
        <div className={`${styles.steps__info}`}>
            <div className={`${styles.steps__wrapper}`}>
			<ul className={`${styles.steps__step}  flex align-center justify-center`}>
				<li>
					<span className={styles.active}>1</span>
				</li>
				<li>
					<span>2</span>
				</li>
				{/* <li>
					<span>3</span>
				</li> */}
			</ul>
            <div className={`${styles.steps__box}`}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<h2>What’s your store details?</h2>
				<Input
					type="text"
					label="Brand Name"
					register={register}
					inputName="name"
					errorMessage={errors.name ? (errors.name.message ? errors.name.message : "This Field is required") : ""}
				/>
				<Input
					type="text"
					label="Store Url"
					register={register}
					inputName="websiteUrl"
					errorMessage={errors.websiteUrl ? (errors.websiteUrl.message ? errors.websiteUrl.message : "This Field is required") : ""}
				/>
				<div className={` ${styles.step__button}`}>
					<CustomButton type="submit" className="full rounded semibold"  buttonText="Next" disabled={loginLoading} loading={loginLoading} />
				</div>
			</form>
            </div>
		</div>
        </div>
        </div>
	);
};

export default SignupFirstStep;
