import CsvDownloader from "react-csv-downloader";

const ExportToCSV = ({
  icon,
  headers,
  label,
  filename,
  loadData,
}: any) => {
  return (
    <CsvDownloader
    className="flex align-center gap-10"
      filename={filename}
      datas={loadData}
      columns={headers}
    >
      <img src={icon} alt="google sheet" />  {label}
    </CsvDownloader>
  );
};

export default ExportToCSV;
