import axios from "axios";
import {  TOKEN_DATA_TYPE } from "enum/enums";
import { getLocalStorageData } from "lib/utils";

const interceptor = (handleAuth: () => void) => {
  axios.interceptors.request.use(
    (req: any) => {
      const headerToken = req?.headers?.headerToken;
      if (headerToken === TOKEN_DATA_TYPE.ACCESS) {
        const token = getLocalStorageData("JWTToken");
        req.headers = req.headers !== null ? { ...req.headers, Authorization: "" } : { Authorization: "" };
        req.headers.Authorization = `Bearer ${token}`;
      } else if (headerToken === TOKEN_DATA_TYPE.INTERNAL) {
        const token = getLocalStorageData("InternalJWTToken");
        req.headers = req.headers !== null ? { ...req.headers, Authorization: "" } : { Authorization: "" };
        req.headers.Authorization = `Bearer ${token}`;
      }
      return req;
    },
    (error: any) => {
      void Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (res: any) => {
      return res;
    },
    (error: any) => {
      if (error?.response?.status === 403) {
        error.response.data.message = "";
        handleAuth();
      }
      return Promise.reject(error);
    }
  );
};
export default interceptor;
