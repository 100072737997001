import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import ArrowDown from "assets/icons/arrowDown";
import React from "react";

interface CustomMultiSelectProps {
	value: number[] | string[];
	handleValue: (value: number[] | string[]) => void;
	data: number[] | string[];
	selectError?: string;
}

const CustomMultiSelect = ({ value, handleValue, data = [], selectError }: CustomMultiSelectProps) => {
	
	const handleChange = (event: SelectChangeEvent<any>) => {
		const {
			target: { value },
		} = event;

		handleValue(value);
	};


	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};
	return (
		<div className={`custom-select bgGray`}>
			<div className={`custom-select-wrapper`}>
				<Select
					labelId="demo-multiple-name-label"
					id="demo-multiple-name"
					multiple
					displayEmpty
					value={value}
					onChange={handleChange}
					MenuProps={MenuProps}
					renderValue={(selected) => {
						if (selected.length === 0) {
							return <>Select</>;
						}
						return selected.join(", ");
					}}
					IconComponent={() => (
						<span className="custom-select__arrow">
							<ArrowDown />
						</span>
					)}
				>
					<MenuItem disabled value="">
						Select
					</MenuItem>
					{data && data?.length> 0 && data?.map((name) => (
						<MenuItem key={name} value={name}>
							{name}
						</MenuItem>
					))}
				</Select>
			</div>
			{selectError && <div className="select-error">{selectError}</div>}
		</div>
	);
};

export default CustomMultiSelect;
