import React from "react";
import styles from "./FileCard.module.scss";
import { Close } from "@mui/icons-material";
import { TryOnImageUploadInterface } from "interface/interface";

interface FileCardProps{
    file:TryOnImageUploadInterface | File;
    handleDelete?:(name:string)=>void
	
}


const FileCard = ({file,handleDelete}:FileCardProps) => {
	return (
		<div className={styles.file} >
			<span>{file?.name ?? ""}</span>
			<Close onClick={()=>{handleDelete?.(file?.name)}} />
		</div>
	);
};

export default FileCard;
