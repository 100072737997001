import product from "assets/images/product.svg";
import category from "assets/images/list.svg";
import img from "assets/images/img-icon.svg";
import status from "assets/images/status-icon.svg";
import desc from "assets/images/description-icon.svg";
import AnkleGirthMale from "assets/modal-images/ankle.jpg";
import ArmLengthMale from "assets/modal-images/arm-length.jpg";
import BustGirthMale from "assets/modal-images/bust-under-bust.jpg";
import CalfGirthMale from "assets/modal-images/calf.jpg";
import ElbowGirthMale from "assets/modal-images/elbow.jpg";
import ForeArmGirthMale from "assets/modal-images/forearm.jpg";
import HeadGirth from "assets/modal-images/head-girth.jpg";
import HipGirthMale from "assets/modal-images/high-hip-hip.jpg";
import HighHipGirthMale from "assets/modal-images/high-hip-hip.jpg";
import InseamToFloorMale from "assets/modal-images/inseam-floor-ankle.jpg";
import InseamToAnkleMale from "assets/modal-images/inseam-floor-ankle.jpg";
import KneeGirthMale from "assets/modal-images/knee.jpg";
import NapeToWaistMale from "assets/modal-images/nape-to-waist.jpg";
import NeckBaseGirthMale from "assets/modal-images/neck-neck-base.jpg";
import ShoulderWidthMale from "assets/modal-images/shoulder-width.jpg";
import ThighGirthMale from "assets/modal-images/thigh.jpg";
import TorsoLengthMale from "assets/modal-images/torso.jpg";
import TotalRiseMale from "assets/modal-images/seat.jpg";
import UnderBustGirthMale from "assets/modal-images/bust-under-bust.jpg";
import UpperArmCircumMale from "assets/modal-images/bicep.jpg";
import WaistGirthMale from "assets/modal-images/waist.jpg";
import WristGirthMale from "assets/modal-images/wrist.jpg";
import AnkleGirthFemale from "assets/modal-images/ankle.jpg";
import ArmLengthFemale from "assets/modal-images/arm-length.jpg";
import BustGirthFemale from "assets/modal-images/bust-under-bust.jpg";
import CalfGirthFemale from "assets/modal-images/calf.jpg";
import ElbowGirthFemale from "assets/modal-images/elbow.jpg";
import ForeArmGirthFemale from "assets/modal-images/forearm.jpg";
import HipGirthFemale from "assets/modal-images/high-hip-hip.jpg";
import HighHipGirthFemale from "assets/modal-images/high-hip-hip.jpg";
import InseamToFloorFemale from "assets/modal-images/inseam-floor-ankle.jpg";
import InseamToAnkleFemale from "assets/modal-images/inseam-floor-ankle.jpg";
import KneeGirthFemale from "assets/modal-images/knee.jpg";
import NapeToWaistFemale from "assets/modal-images/nape-to-waist.jpg";
import NeckGirthFemale from "assets/modal-images/neck-neck-base.jpg";
import NeckBaseGirthFemale from "assets/modal-images/neck-neck-base.jpg";
import ShoulderWidthFemale from "assets/modal-images/shoulder-width.jpg";
import ThighGirthFemale from "assets/modal-images/thigh.jpg";
import TorsoLengthFemale from "assets/modal-images/torso.jpg";
import TotalRiseFemale from "assets/modal-images/seat.jpg";
import UnderBustGirthFemale from "assets/modal-images/bust-under-bust.jpg";
import UpperArmCircumFemale from "assets/modal-images/bicep.jpg";
import WaistGirthFemale from "assets/modal-images/waist.jpg";
import WristGirthFemale from "assets/modal-images/wrist.jpg";
import AcrossShoulderFemale from "assets/modal-images/across-back-shoulder.jpg";
import Gender from "assets/images/gender.svg";
import emailIcon from "assets/images/email-icon.svg";
import shieldIcon from "assets/images/shield-tick.svg";
import calendarIcon from "assets/images/calendar-date.svg";




export const columns: { name: string; id: string; center: boolean; showSelect?: boolean }[] = [
	{
		id: "1",
		name: "Measurement",
		center: false,
	},
	{
		id: "2",
		name: "cm",
		center: false,
		showSelect: true,
	},
];

export const rows: Record<string, string | number>[] = [
	{
		id: 1,
		name: "waist",
	},
	{
		id: 2,
		name: "hip",
	},
	{
		id: 3,
		name: "bust",
	},
];

export const selectData: Record<string, string | number>[] = [
	{
		id: 1,
		name: "cm",
	},
	{
		id: 2,
		name: "inch",
	},
];

export const measurementColumns: { name: string; id: string; center: boolean; showSelect?: boolean }[] = [
	{
		id: "1",
		name: "Measurement",
		center: false,
	},
	{
		id: "2",
		name: "Definition",
		center: false,
	},
];

export const tryOnColumns: { name: string; id: string; center: boolean; icon?: string }[] = [
	{
		id: "1",
		name: "Product",
		center: false,
		icon: product,
	},
	{
		id: "2",
		name: "Category",
		center: false,
		icon: category,
	},
	{
		id: "3",
		name: "Status",
		center: false,
		icon: status,
	},
	{
		id: "4",
		name: "Ghost images",
		center: false,
		icon: img,
	},
	{
		id: "5",
		name: "Garment description",
		center: false,
		icon: desc,
	},
];

export const customScanHeadCell: { name: string; id: string; center: boolean;icon?:string; customClass?:string; }[] = [
	{
		name: "Sno",
		id: "17c1329a-2f61-4636-99c8-cbbab1fc90d79033999",
		center: false,
       customClass:"checkboxTd"
	},
	{
		name: "Customer",
		id: "17c1329a-2f61-4636-99c8-cbbab1fc90d790334",
		center: false,
        icon: Gender,
	},
	{
		name: "Email",
		id: "17c1329a-2f61-4636-99c8-cbbab1fc90d7",
		center: false,
        icon: emailIcon,
	},
//   {
// 		id: "b7c12b83-2e8d-4945-8d5b-c3d3966f268a85",
// 		name: "Status",
// 		center: false,
//         icon: status,
// 	},
	{
		id: "b7c12b83-2e8d-4945-8d5b-c3d3966f268a",
		name: "Gender",
		center: true,
        icon: shieldIcon,
	},
	{
		id: "49df43d4-823e-4ad6-9f6e-2b44e3d780e13",
		name: "Measurement Taken",
		center: true,
        icon: Gender,
	},
	{
		id: "f1984e65-2a41-4cb6-8349-2193b7333f3b005",
		center: false,
		name: "Date",
        icon: calendarIcon,
	},
	// {
	// 	id: "f1984e65-2a41-4cb6-8349-2193b7333f3b555",
	// 	center: false,
	// 	name: "Notes",
	// },
	
];

export const reviewHeadCells = [
	{
		id: "0001",
		name: "Measurement",
		center: false,
	},
	{
		id: "0002",
		name: "Value",
		center: false,
	},
];

export const ModalTableData: any = {
	male: {
		ankle: {
			title: "Ankle Girth",
			path: AnkleGirthMale,
			desc: "Minimum girth of the lower leg, horizontally just above the ankle.",
		},
		armLength: {
			title: "Arm Length",
			path: ArmLengthMale,
			desc: "From outer wrist bone to elbow, and continued until the top of the shoulder, to the point where the collar bone ends and the shoulder begin. Taken as if the arm is bent.",
		},
		armLengthStraight: {
			title: "Arm Length Straight",
			path: ArmLengthMale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		bicep: {
			title: "Bicep", 
			path: UpperArmCircumMale,
			desc: "Circumference of bicep right below the deltoid.",
		},
		calf: {
			title: "Calf Girth",
			path: CalfGirthMale,
			desc: "Maximum girth of the calf measured horizontally at the fullest part of the calf with legs slightly apart.",
		},
		bust: {
			title: "Bust Girth",
			path: BustGirthMale,
			desc: "Maximally horizontal circumference measured under the armpits and at the level of the maximum projection of the bust with regular breathing.",
		},
		forearm: {
			title: "Forearm Girth",
			path: ForeArmGirthMale,
			desc: "Girth of the forearm in the middle between elbow and wrist, orthogonal to the axis of the forearm.",
		},
		head: {
			title: "Head Girth",
			path: HeadGirth,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		hip: {
			title: "Hip Girth",
			path: HipGirthMale,
			desc: "Maximum horizontal circumference of the trunk measured at hip height at the fullest part of the buttocks.",
		},
		inseamToAnkle: {
			title: "Inseam To Ankle",
			path: InseamToAnkleMale,
			desc: "Distance between the crotch level and the ankle, taken along the inner leg with legs slightly apart; the mass equally distributed on both legs.",
		},
		inseam: {
			title: "Inseam",
			path: InseamToAnkleMale,
			desc: "Distance between the crotch level and the ground, taken along the inner leg with legs slightly apart; the mass equally distributed on both legs",
		},
		knee: {
			title: "Knee Girth",
			path: KneeGirthMale,
			desc: "Circumference of the knee",
		},
		neckCirc: {
			title: "Neck Girth",
			path: NeckBaseGirthMale,
			desc: "Circumference of the neck, measured close to the prominent larynx cartilage (higher than neck base) and the 7th cervical vertebra.",
		},
		shoulder: {
			title: "Shoulder Width",
			path: ShoulderWidthMale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		thigh: {
			title: "Thigh Girth",
			path: ThighGirthMale,
			desc: "Horizontal girth at the highest thigh position.",
		},
		torso: {
			title: "Torso Length",
			path: TorsoLengthMale,
			desc: "From the 7th Cervical Vertebra following the centre back contour to the crotch.",
		},
		underBust: {
			title: "UnderBust Girth",
			path: UnderBustGirthMale,
			desc: "Circumference of the chest under the bust.",
		},
		wrist: {
			title: "Wrist Girth",
			path: WristGirthMale,
			desc: "Girth of the wrist measured over the wrist bones.",
		},
		back: {
			title: "Back Width",
			path: AcrossShoulderFemale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		elbow: {
			title: "Elbow Girth",
			path: ElbowGirthMale,
			desc: "Circumference of the arm at the elbow with the arms hanging down and slightly tilted elbows.",
		},
		highHip: {
			title: "High-Hip Girth",
			path: HighHipGirthMale,
			desc: "Horizontal circumference of the trunk, measured midway between waist level and hip level.",
		},
		inseamToFloor: {
			title: "Inseam To Floor",
			path: InseamToFloorMale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		napeToWaist: {
			title: "Nape To Waist",
			path: NapeToWaistMale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		neckBase: {
			title: "Neck-Base Girth",
			path: NeckBaseGirthMale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		totalRise: {
			title: "Total-Rise",
			path: TotalRiseMale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		upperArm: {
			title: "Upper-Arm Circumference",
			path: UpperArmCircumMale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		acrossBackShoulder: {
			title: "Across Back Shoulder",
			path: AcrossShoulderFemale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		waist: {
			title: "Waist Girth",
			path: WaistGirthMale,
			desc: "Horizontal circumference at natural waistline between the highest part of the hip bones and the lower ribs",
		},
		seat: {
			title: "Seat",
			path: TotalRiseMale,
			desc: "Distance from the centre of the waist level at the front of the body, through the crotch, to the centre of the back waist level following the contour of the body.",
		},
	},
	female: {
		ankle: {
			title: "Ankle Girth",
			path: AnkleGirthFemale,
			desc: "Minimum girth of the lower leg, horizontally just above the ankle.",
		},
		acrossBackShoulder: {
			title: "Across Back Shoulder",
			path: AcrossShoulderFemale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		armLength: {
			title: "Arm Length",
			path: ArmLengthFemale,
			desc: "From outer wrist bone to elbow, and continued until the top of the shoulder, to the point where the collar bone ends and the shoulder begin. Taken as if the arm is bent.",
		},
		armLengthStraight: {
			title: "Arm Length Straight",
			path: ArmLengthFemale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		bicep: {
			title: "Bicep", 
			path: UpperArmCircumFemale,
			desc: "Circumference of bicep right below the deltoid.",
		},
		calf: {
			title: "Calf Girth",
			path: CalfGirthFemale,
			desc: "Maximum girth of the calf measured horizontally at the fullest part of the calf with legs slightly apart.",
		},
		bust: {
			title: "Bust Girth",
			path: BustGirthFemale,
			desc: "Maximally horizontal circumference measured under the armpits and at the level of the maximum projection of the bust with regular breathing.",
		},
		seat: {
			title: "Seat",
			path: TotalRiseFemale,
			desc: "Distance from the centre of the waist level at the front of the body, through the crotch, to the centre of the back waist level following the contour of the body.",
		},
		forearm: {
			title: "Forearm Girth",
			path: ForeArmGirthFemale,
			desc: "Girth of the forearm in the middle between elbow and wrist, orthogonal to the axis of the forearm.",
		},
		head: {
			title: "Head Girth",
			path: HeadGirth,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		hip: {
			title: "Hip Girth",
			path: HipGirthFemale,
			desc: "Maximum horizontal circumference of the trunk measured at hip height at the fullest part of the buttocks.",
		},
		inseamToAnkle: {
			title: "Inseam To Ankle",
			path: InseamToAnkleFemale,
			desc: "Distance between the crotch level and the ankle, taken along the inner leg with legs slightly apart; the mass equally distributed on both legs.",
		},
		inseam: {
			title: "Inseam",
			path: InseamToAnkleFemale,
			desc: "Distance between the crotch level and the ground, taken along the inner leg with legs slightly apart; the mass equally distributed on both legs",
		},
		knee: {
			title: "Knee Girth",
			path: KneeGirthFemale,
			desc: "Circumference of the knee",
		},
		neckCirc: {
			title: "Neck Girth",
			path: NeckGirthFemale,
			desc: "Circumference of the neck, measured close to the prominent larynx cartilage (higher than neck base) and the 7th cervical vertebra.",
		},
		shoulder: {
			title: "Shoulder Width",
			path: ShoulderWidthFemale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		thigh: {
			title: "Thigh Girth",
			path: ThighGirthFemale,
			desc: "Horizontal girth at the highest thigh position.",
		},
		torso: {
			title: "Torso Length",
			path: TorsoLengthFemale,
			desc: "From the 7th Cervical Vertebra following the centre back contour to the crotch.",
		},
		underBust: {
			title: "UnderBust Girth",
			path: UnderBustGirthFemale,
			desc: "Circumference of the chest under the bust.",
		},
		wrist: {
			title: "Wrist Girth",
			path: WristGirthFemale,
			desc: "Girth of the wrist measured over the wrist bones.",
		},
		back: {
			title: "Back Width",
			path: AcrossShoulderFemale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		elbow: {
			title: "Elbow Girth",
			path: ElbowGirthFemale,
			desc: "Circumference of the arm at the elbow with the arms hanging down and slightly tilted elbows.",
		},
		highHip: {
			title: "High-Hip Girth",
			path: HighHipGirthFemale,
			desc: "Horizontal circumference of the trunk, measured midway between waist level and hip level.",
		},
		inseamToFloor: {
			title: "Inseam To Floor",
			path: InseamToFloorFemale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		napeToWaist: {
			title: "Nape To Waist",
			path: NapeToWaistFemale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		neckBase: {
			title: "Neck-Base Girth",
			path: NeckBaseGirthFemale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		totalRise: {
			title: "Total-Rise",
			path: TotalRiseFemale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		upperArm: {
			title: "Upper-Arm Circumference",
			path: UpperArmCircumFemale,
			desc: "Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.",
		},
		waist: {
			title: "Waist Girth",
			path: WaistGirthFemale,
			desc: "Horizontal circumference at natural waistline between the highest part of the hip bones and the lower ribs",
		},
	},
};
