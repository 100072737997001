import React, { useState } from "react";
import ArrowDown from "assets/icons/arrowDown";
import { MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { CustomSelectProps } from "../../interface/interface";
import { camelCaseToWords } from "lib/utils";
import { SELECT_TYPE } from "lib/Constants";

const CustomSelect = ({
	placeholder,
	bgGray,
	bgWhite,
	bgGreen,
	outline,
    outlineShadow,
    full,
	icon,
	iconSpace,
	handleChange,
	disabled = false,
	value = "",
	data = [],
	setShowBlank,
	type=SELECT_TYPE.NORMAL,
}: CustomSelectProps) => {
	const [ownValue, setOwnValue] = useState<string>(value);
	const handleOnChange = (e: SelectChangeEvent) => {
		const {value} = e.target
		setOwnValue(value);
		handleChange?.(value);
		setShowBlank?.(false)
	};
	return (
		<div
			className={`custom-select ${bgGray ? "bgGray" : ""} ${bgWhite ? "bgWhite" : ""} ${bgGreen ? "bgGreen" : ""} ${outline ? "outline" : ""} ${outlineShadow ? "outlineShadow" : ""} ${
				iconSpace ? "iconSpace" : ""
			} ${full ? "full" : ""}`}
		>
			<Select
				value={ownValue}
				onChange={handleOnChange}
				displayEmpty
				disabled={disabled}
				inputProps={{ "aria-label": "Without label" }}
				IconComponent={() => (
					<span className="custom-select__arrow">
						<ArrowDown />
					</span>
				)}
			>
				{icon && (
					<MenuItem value="">
						{/* <span className="select-icon">
							<img src={icon} alt="icon" />
						</span> */}

						{placeholder}
					</MenuItem>
				)}
				{ type===SELECT_TYPE.FITMODEL &&  data?.length > 0 && (
					data.map((el) => (
						<MenuItem disabled={el.selected} key={el.id} value={el.value || el.name}>
						{camelCaseToWords(el.name)}
						</MenuItem>
					))
				)}
				{
					type=== SELECT_TYPE.NORMAL &&  data?.map((el) => (
						<MenuItem disabled={el?.disabled} key={el.id} value={el.value || el.name}>
						{icon && (	<span className="select-icon">
							<img src={icon} alt="icon" />
						</span>)}	<span className="select-value">{el.name}</span>
						</MenuItem>
					)) 
				}
				
			
			</Select>
		</div>
	);
};
export default CustomSelect;
