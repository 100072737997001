import { TOKEN_DATA_TYPE } from "enum/enums";
import UseApiService from "./apiService";
import { API_END_POINTS } from "lib/Constants";
import { onBoardedData } from "interface/interface";


class ProductService {
	getProductList(): Promise<any> {
		const body = {
			url: API_END_POINTS.PRODUCT.getList,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
		};
		return UseApiService().get(body);
	}
	getProductTechpackSizing(): Promise<any> {
		const body = {
			url: API_END_POINTS.PRODUCT.techpackProductSizing,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
		};
		return UseApiService().get(body);
	}
	associatedCalculation(data:Record<string,string>){
        const body ={
            url:API_END_POINTS.PRODUCTSIZE.postData,
            headerToken: TOKEN_DATA_TYPE.ACCESS,
            data
        }
        return UseApiService().post(body)
    }
	commitAssociatedCalculation(data:Record<string,string>){
        const body ={
            url:API_END_POINTS.PRODUCTSIZE.commitData,
            headerToken: TOKEN_DATA_TYPE.ACCESS,
            data
        }
        return UseApiService().post(body)
    }
	getProductSizingDetails(name:string){
        const body ={
            url:`${API_END_POINTS.PRODUCT.techpackProductSizing}/${name}`,
            headerToken: TOKEN_DATA_TYPE.ACCESS,
            
        }
        return UseApiService().get(body)
    }
    onboardProduct(data: onBoardedData[]){
        const body = {
          url: API_END_POINTS.PRODUCT.productOnboard,
          data,
          headerToken: TOKEN_DATA_TYPE.ACCESS,
        };
        return UseApiService().post(body);
      }
      getOnboardingSummary(){
        const body ={
            url:API_END_POINTS.PRODUCT.onboardingSummary,
            headerToken: TOKEN_DATA_TYPE.ACCESS,
            
        }
        return UseApiService().get(body)
    }
}

const productService = new ProductService();
export default productService;
