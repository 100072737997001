import CustomButton from "atoms/customButton/CustomButton";
import styles from "./Installed.module.scss";
import Logo from "assets/images/logo-icon-white.svg";
import { useDispatch } from "react-redux";
import { HANDLE_APP_INSTALL_CHECK } from "store/auth/action.types";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { SIGN_UP_TAB_VALUE } from "enum/enums";
import { setShowModal } from "store/auth/reducer";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "lib/Constants";
import Install from "assets/icons/install";

const Installed = () => {
	const dispatch = useDispatch();
	const { storeUrl, step, loginLoading,isAppInstalled } = useSelector((state: RootState) => state.auth);
	const navigate = useNavigate();
	const handleAppInstallCheck = () => {
		window.open("https://accounts.shopify.com/", "_blank");
		dispatch({ type: HANDLE_APP_INSTALL_CHECK, payload: storeUrl });
	};
	const handleRedirectLogin = () => {
		dispatch(setShowModal(false));
		navigate(`${ROUTES.LOGIN}/?appInstalled=true`);
	};
	return (
		<>
			<div className={`${styles.install}`}>
				{step === SIGN_UP_TAB_VALUE.FOUR ? (
					<div className={`${styles.install__loginBox} flex  align-center`}>
                        <div>
                        <h2>All Set!</h2>
						<p>Now you can login to access dashboard </p>
                        <div className={` flex justify-center ${styles.step__install}`}>
									<span className="flex align-center justify-center">
										{" "}
										<Install /> App installed
									</span>
								</div>
								<br/>
						<CustomButton buttonText="Login" handleFunc={handleRedirectLogin} />
                        </div>
					</div>
				) : (
					<>
						<div className={`${styles.install__box}`}>
							<div className={`flex  align-center ${styles.install__logo}`}>
								<figure>
									<img src={Logo} alt="logo" />
								</figure>
								<figcaption>Swan AI Fitting Room</figcaption>
							</div>
						</div>
						<div className={`${styles.install__button}`}>
							<CustomButton className="rounded full" buttonText="Install Shopify app" handleFunc={handleAppInstallCheck}loading={loginLoading} disabled={loginLoading}  />
						</div>
						<div className={`${styles.install__info}`}>
							{isAppInstalled ? <p>Please ensure you have subscribed and got an active plan on shopify.</p> :<p>
								Your trial will begin once Swan’s app is installed. This screen will update then too. <span role="button">Talk to Swan</span> about
								your onboarding or <span role="button">Read the FAQs.</span>
							</p>}
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default Installed;
