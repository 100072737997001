import CircularLoader from "component/circularLoader/CircularLoader";
import { ROUTES } from "lib/Constants";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "store/store";

const LoggedOutWrapper = ({ children }: { children: React.ReactNode }) => {
  const {isAuth,loading} = useSelector((store:RootState)=>store.auth)
  if (loading) {
    return <CircularLoader />;
  } else if (!isAuth) {
    return <>{children}</>;
  } else {
    return <Navigate to={ROUTES.HOME} replace />;
  }
};

export default LoggedOutWrapper;
