import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./TryOnOnboarding.module.scss";
import CustomButton from "atoms/customButton/CustomButton";
import searchIcon from "../../assets/images/status.svg";
import { useDispatch } from "react-redux";
import { HANDLE_DELETE_TRY_ON_UPLOADED_FILE, HANDLE_GET_TRY_ON_IMAGES_APPROVE, HANDLE_GET_TRY_ON_PRODUCT_DETAILS, HANDLE_GET_TRYON_LIST, HANDLE_TRY_ON_ONBAORD_ELIGIBILITY, HANDLE_TRY_ON_SUBMIT, HANDLE_TRY_ON_UPLOADED_FILE } from "store/tryOn/action.types";
import { useNavigate } from "react-router-dom";
import { MUI_TABLE_TYPE, ROUTES, SELECT_TYPE, TRY_ON_STATUS_ARRAY } from "lib/Constants";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import LoadingScreen from "component/LoadingScreen";
import NoDataPlaceHolder from "component/NoDataPlaceHolder";
import CustomTableMui from "component/customTableMui/CustomTableMui";
import { tryOnColumns } from "component/customTableMui/tableData";
import BackButton from "component/backButton/BackButton";
import { setIsApproved, setIsFineTuned, setSelectedData, setShowModal, setTryOnUploadImage } from "store/tryOn/reducer";
import CustomResizeDrawer from "component/customResizeDrawer/CustomResizeDrawer";
import TryOnProductDetails from "pages/tryOnProduct/TryOnProductDetails";
import CustomSelect from "atoms/customSelect/CustomSelect";
import CustomDialog from "component/CustomDialog";
import { Close } from "@mui/icons-material";
import TryOnImageUpload from "pages/tryOnSelection/TryOnImageUpload";
import { TRY_ON_FILE_UPLOAD_SHOW_TYPE } from "enum/enums";
import { generateUuid } from "lib/utils";
import { TryOnImageUploadInterface } from "interface/interface";

const TryOnOnboarding = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showDrawer, setShowDrawer] = useState<boolean>(false);
	const { loading, error, data, selectedData, approveLoading, isFineTuned, isApproved, checkEligibilityLoading,tryOnUploadImage,showModal,selectedProduct } = useSelector((state: RootState) => state.tryOn);
	const [filteredData, setFilteredData] = useState<Record<string, any>[]>([]);
	const [statusValue, setStatusValue] = useState<string>("all");
	const [productInfo, setProductInfo] = useState<{ name: string; type: string; id: string }>({ name: "", type: "", id: "" });
	const [messageOpen, setMessageOpen] = useState<boolean>(false);
	const goWithProduct = useMemo(() => selectedData.length || filteredData.length, [filteredData, selectedData]);

	const checkEligibleArray = useMemo(() => {
		return filteredData?.reduce((acc: string[], el) => {
			if (!el?.tryonLaunchStatus) {
				acc.push(el?.handle);
			}
			return acc;
		}, []);
	}, [filteredData]);

	const handleClose = useCallback(() => {
		setMessageOpen((prev) => !prev);
	}, [messageOpen]);

    const handleCloseUpload=useCallback(()=>{
        dispatch(setShowModal(false))
    },[showModal])

	const handleSelectedData = useCallback(
		(value: string[]) => {
			dispatch(setSelectedData(value));
		},
		[selectedData],
	);

	const handleProductDetails = (name = "", type = "", id = "") => {
		setShowDrawer(true);
		const obj = { name, type, id };
		setProductInfo({ ...obj });
		dispatch({ type: HANDLE_GET_TRY_ON_PRODUCT_DETAILS, payload: name });
	};

	const handleStatusChange = useCallback(
		(value: string) => {
			let modData = data;
			if (value !== "all") {
				modData = data?.filter((el) => el?.tryonLaunchStatus === value);
				dispatch(setSelectedData([]));
			}
			if (value === "approved" || value === "onboarded") {
				const name = modData.map((el) => el?.handle);
				handleSelectedData(name);
			}
			setFilteredData(modData);
		},
		[data],
	);
	const checkSelectProduct = useCallback(() => {
		if (!selectedData.length) {
			const name = filteredData.map((el) => el?.handle);
			handleSelectedData(name);
		}
		navigate(ROUTES.TRY_ON_SELECTION);
	}, [selectedData, filteredData]);
    const handleFileOnChange = useCallback(
		(files: File[]) => {
			const imageFiles:TryOnImageUploadInterface[] = files?.reduce((acc:TryOnImageUploadInterface[],file:File)=>{
				if (file.type.startsWith("image/")){
					const a = {name:file.name,id:generateUuid(),file}
				acc.push(a)
				}
				return acc
			},[])
			if (imageFiles.length > 0) {
				dispatch({ type: HANDLE_TRY_ON_UPLOADED_FILE, payload: { data:imageFiles,productHandle:selectedProduct } });
			} else {
				console.error("No valid image files selected.");
			}
		},
		[selectedProduct],
	);
    const handleDeleteImage = useCallback(
		(name: string) => {
			const filteredData = tryOnUploadImage?.filter((el) => el.name !== name);
			dispatch(setTryOnUploadImage(filteredData));
			dispatch({type:HANDLE_DELETE_TRY_ON_UPLOADED_FILE,payload:{fileName:name,productHandle:selectedProduct}})
		},
		[tryOnUploadImage,selectedProduct],
	);
	const handleSubmit =useCallback(()=>{
		dispatch({type:HANDLE_TRY_ON_SUBMIT,payload:selectedProduct})
	},[selectedProduct])
	useEffect(() => {
		handleStatusChange(statusValue);
	}, [data, statusValue]);

	useEffect(() => {
		if (isFineTuned) {
			setShowDrawer(false);
			dispatch(setIsFineTuned(false));
			dispatch({ type: HANDLE_GET_TRYON_LIST });
		}
	}, [isFineTuned]);

	useEffect(() => {
		if (isApproved) {
			handleClose();
			dispatch(setIsApproved(false));
		}
	}, [isApproved]);

	useEffect(() => {
		dispatch(setSelectedData([]));
		dispatch({ type: HANDLE_GET_TRYON_LIST });
		return()=>{
			dispatch(setTryOnUploadImage([]))
		}
	}, []);

	return (
		<div className={`${styles.tryOnBoarding} flex-column`}>
			<div className={styles.tryOnBoarding__back}>
				<BackButton route={ROUTES.HOME} />
			</div>
			{loading ? (
				<div className={`${styles.tryOnBoarding__info}`}>
					<div className="flex align-center justify-center h-full">
						<LoadingScreen height={false} />
					</div>
				</div>
			) : error ? (
				<div className={`${styles.tryOnBoarding__info}`}>
					<div className="flex align-center justify-center h-full">
						<NoDataPlaceHolder message="No data to show" showOnlyMessage />
					</div>
				</div>
			) : (
				<div className={`${styles.tryOnBoarding__info}  flex-column`}>
					<div className={`${styles.tryOnBoarding__header} flex-wrap align-center`}>
						<CustomSelect outline icon={searchIcon} data={TRY_ON_STATUS_ARRAY} value={statusValue} type={SELECT_TYPE.NORMAL} handleChange={(value: string) => setStatusValue(value)} />
						{statusValue !== "approved" && statusValue !== "onboarded" && <CustomButton className="sm rounded" buttonText="Recent results" handleFunc={() => navigate(ROUTES.TRY_ON_RECENT_IMAGES)} />}
						{checkEligibleArray.length > 0 && (
							<CustomButton
								className="sm rounded"
								buttonText="Eligible for all"
								loading={checkEligibilityLoading}
								disabled={checkEligibilityLoading}
								handleFunc={() => dispatch({ type: HANDLE_TRY_ON_ONBAORD_ELIGIBILITY, payload: { products: checkEligibleArray, type: "all" } })}
							/>
						)}
					</div>

					{filteredData?.length > 0 ? (
						<div className={`${styles.tryOnBoarding__grid}`}>
							<CustomTableMui
								secondColSticky
								columns={tryOnColumns}
								rows={filteredData}
								type={MUI_TABLE_TYPE.TRY_ON}
								handleChange={() => console.log(1)}
								requiredCheckBox={statusValue !== "all"}
								setSelected={handleSelectedData}
								selected={selectedData}
								customRowCount={filteredData?.length}
								handleMeasurementDetails={handleProductDetails}
							/>
						</div>
					) : (
						<div className={`${styles.tryOnBoarding__noData} flex align-center justify-center h-full`}>
							<NoDataPlaceHolder message="No data to show" showOnlyMessage />
						</div>
					)}
				</div>
			)}
			{!loading && !["all", "approved", "onboarded"].includes(statusValue) && filteredData.length > 0 && (
				<div className={`${styles.tryOnBoarding__button} flex justify-end`}>
					<CustomButton
						className="rounded"
						handleFunc={checkSelectProduct}
						disabled={loading || (!filteredData.length && approveLoading)}
						loading={approveLoading}
						buttonText={`Continue with ${goWithProduct} product${goWithProduct > 1 ? "s" : ""}`}
					/>
				</div>
			)}
			{!loading && ["approved", "onboarded"].includes(statusValue) && selectedData?.length > 0 && (
				<div className={`${styles.tryOnBoarding__button} flex justify-end`}>
					<CustomButton
						className="rounded"
						handleFunc={() => dispatch({ type: HANDLE_GET_TRY_ON_IMAGES_APPROVE, payload: { data: selectedData, info: true } })}
						disabled={loading || approveLoading}
						loading={approveLoading}
						buttonText="Launch Approved"
					/>
				</div>
			)}
			<CustomDialog className="match-modal" open={messageOpen} handleShowModal={handleClose}>
				<div className={styles.tryOnBoarding__modal}>
					<span className={`${styles.tryOnBoarding__close}`} role="button" onClick={handleClose}>
						<Close />
					</span>
					<article>
						<h3>Try-On is live!</h3>
						<p>Please allow a few minutes for Swan to update the button on your product pages.</p>
					</article>
				</div>
			</CustomDialog>
			{showDrawer && (
				<CustomResizeDrawer mobileFull noImage={error}>
					<TryOnProductDetails
						onClose={() => {
							dispatch({ type: HANDLE_GET_TRYON_LIST });
							setShowDrawer(false);
						}}
						statusValue={productInfo?.type}
						productName={productInfo?.name}
					/>
				</CustomResizeDrawer>
			)}
			<CustomDialog open={showModal} handleShowModal={()=>{handleCloseUpload()
				dispatch(setTryOnUploadImage([]))
			}}>
				<TryOnImageUpload handleDeleteImage={handleDeleteImage} handleFileOnChange={handleFileOnChange} showType={TRY_ON_FILE_UPLOAD_SHOW_TYPE.MODAL} handleClose={handleCloseUpload} handleSubmit={handleSubmit} />
			</CustomDialog>
		</div>
	);
};

export default TryOnOnboarding;
