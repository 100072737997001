import Chip from 'atoms/chip/Chip';
import styles from './TryOnOnOnboarding.module.scss';
import tryOn from 'assets/images/tryOn.png';
import arrow from 'assets/images/blue-arrow.svg';
import { Link } from 'react-router-dom';
import { ROUTES } from 'lib/Constants';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const TryOnOnBoarding = () => {
  const {onboardingSummaryData} = useSelector((state:RootState)=>state.product)
    return (<>
        <div className={`${styles.tryOn} flex-wrap justify-between`}>
         <figcaption>
            <h3>TRY-ON <Chip title="NEW" success/></h3>
            <p>Grow sales with Try-On for any customer.</p>
            <Link to={ROUTES.TRY_ON_ONBOARDING}>
            <>
          {onboardingSummaryData?.tryonOnboardedCount > 0 ?<span  className={`${styles.tryOn__button}`} role='button'> 
            <span>
              Open <img src={arrow} alt='arrow' />
            </span></span> :<span  className={`${styles.tryOn__button}`} role='button'> 1 min onboarding 
            <span>
            Start   <img src={arrow} alt='arrow' />
            </span></span> }
            </>
            </Link>
         </figcaption>
         <figure className='flex align-center justify-center'>
         <img src={tryOn} alt='tryOn' />
         </figure>
        </div>

    </>)
}
export default TryOnOnBoarding;