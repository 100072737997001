import { Close } from '@mui/icons-material';
import React from 'react';
import styles from "./MeasurementDescriptionModal.module.scss";

interface ModalTableProps {
  setShowModalTable: any;
  open: boolean;
  image: any;
  title: string;
  desc: string;
}

const MeasurementDescriptionModal = ({ setShowModalTable, image, title, desc }: ModalTableProps) => {
  const handleCloseModal = () => {
    setShowModalTable(false);
  };
  return (
    <>
    <div className={`${styles.MeasurementModal}`}>
      <span className={`${styles.MeasurementModal__close}`} onClick={handleCloseModal}>
       <Close/>
      </span>
      <div className={`${styles.MeasurementModal__modal}`} >
        <div className={`${styles.MeasurementModal__img}`} >
          <img src={image} alt="image" />
        </div>
      </div>
      <div className={`${styles.MeasurementModal__info}`}>
        <h2>{title}</h2>
        {/* <h3>We take:</h3> */}
        <p>{desc}</p>
      </div>
      {/* <div className={`${styles.MeasurementModal__footer}`}>
        <p>
          Do you take yours differently? <span>Let us know!</span>
        </p>
      </div> */}
      </div>
      </>
  );
};

export default MeasurementDescriptionModal;
