import React from "react";
// import noData from "assets/images/no-data.svg";
// image to be added
interface NoDataPlaceHolderProps {
  message?: string;
  children?: JSX.Element;
  title?: string;
  imgUrl?: any;
  showOnlyMessage?: boolean;
}
const NoDataPlaceHolder = ({ message = "", children, title = "No data found", imgUrl, showOnlyMessage = false }: NoDataPlaceHolderProps) => {
  return (
    <div className="noData">
      {showOnlyMessage ? (
        <p>{message}</p>
      ) : (
        <>
        {imgUrl && <img className={`${imgUrl ? `noDataImgLarge` : ``}`} src={imgUrl} alt="users" />}  
          <h5>{title}</h5>
          <p>{message}</p>
          {children && children}
        </>
      )}
    </div>
  );
};

export default NoDataPlaceHolder;
