import React, { useCallback, useEffect, useState } from "react";
import styles from "./Products.module.scss";
// import CustomButton from "atoms/customButton/CustomButton";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { HANDLE_GET_PRODUCTS_LIST, HANDLE_PRODUCT_SIZING_DETAILS } from "store/products/action.types";
import { useDispatch } from "react-redux";
import LoadingScreen from "component/LoadingScreen";
import CustomResizeDrawer from "component/customResizeDrawer/CustomResizeDrawer";
import ProductDetails from "component/productDetails/ProductDetails";
import CustomTable from "component/customTable/CustomTable";
import { product_Columns } from "./Constants";
import productService from "services/productservice";
import Capsule from "atoms/capsule/Capsule";
import SizingBatchUpload from "component/sizingOnboardingChild/SizingBatchUpload";
import PlusIcon from "../../assets/icons/plusIcon";
import CustomDialog from "component/CustomDialog";
import { Close } from "@mui/icons-material";
import { setProductList } from "store/products/reducer";
import CustomButton from "atoms/customButton/CustomButton";
import { HANDLE_TECHPACK_PARSE } from "store/techpack/action.types";
import { setUploadedFiles } from "store/techpack/reducer";

const Products = () => {
	const dispatch = useDispatch();
	const { productList, techpackProductSizing, error, loading: productLoading } = useSelector((state: RootState) => state.product);
	const { groupList } = useSelector((state: RootState) => state.group);
	const { data: fitModelData } = useSelector((state: RootState) => state.fitModel);
	const { data: techPackData, batchFileUploaded, techpackFileBatchId, loading, techpackParseDone } = useSelector((state: RootState) => state.techpack);
	const [tableRowData, setTableRowData] = useState<Record<string, any>[]>([]);
	const [selectedValues, setSelectedValues] = useState<any>({});
	const [showDrawer, setShowDrawer] = useState<boolean>(false);
	const [allValuesSelected, setAllValuesSelected] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);
	const [messageOpen, setMessageOpen] = useState<boolean>(false);

	const handleGetProductSizingDetails = useCallback(
		(value: any) => {
			dispatch({ type: HANDLE_PRODUCT_SIZING_DETAILS, payload: value });
			setShowDrawer(true);
		},
		[showDrawer],
	);
	const handleClose = useCallback(() => {
		setMessageOpen((prev) => !prev);
	}, [messageOpen]);
	const handleFitModelChange = useCallback(
		(rowId: string, newValue: string) => {
			const aggregatedMeasurements: string[] = [];
			const selectedFitModel = fitModelData?.find((model: any) => model?.id === newValue);

			if (selectedFitModel && selectedFitModel?.measurements) {
				Object.entries(selectedFitModel.measurements).forEach(([key, value]) => {
					if (value !== null && value !== undefined) {
						aggregatedMeasurements.push(key);
					}
				});
			}
			setTableRowData((prev) =>
				prev.map((row) =>
					row.id === rowId
						? {
								...row,
								measurementUsed: aggregatedMeasurements,
						}
						: row,
				),
			);
			return aggregatedMeasurements;
		},
		[fitModelData, tableRowData],
	);

	const handleDropdownChange = useCallback(
		(rowId: string, columnId: string, newValue: string) => {
			setSelectedValues((prev: any) => {
				const updatedValues = {
					...prev,
					[rowId]: {
						...prev[rowId],
						[columnId]: newValue,
					},
				};
				if (columnId === "fitModel") {
					handleFitModelChange(rowId, newValue);
				}
				const allSelected = Object.keys(updatedValues).every((id) => areAllRequiredValuesPresent(id, updatedValues));
				setAllValuesSelected(allSelected);
				if (areAllRequiredValuesPresent(rowId, updatedValues)) {
					fetchCalculation(rowId, updatedValues);
				}
				return updatedValues;
			});
		},
		[selectedValues],
	);

	const areAllRequiredValuesPresent = useCallback((rowId: string, values: any) => {
		const rowValues = values[rowId] || {};
		return !!rowValues["fitModel"] && !!rowValues["techpack"] && !!rowValues["baseSize"];
	}, []);

	const fetchCalculation = useCallback(
		async (rowId: any, values: any) => {
			const rowValues = values[rowId];
			const payload = {
				parsedTableKey: rowValues.techpack,
				productHandle: rowValues.handle,
				shopifyProductId: rowId,
				fitmodelId: rowValues.fitModel,
				baseSize: rowValues.baseSize,
			};
			setTableRowData((prev) =>
				prev.map((row) =>
					row.id === rowId
						? {
								...row,
								// associated: <Capsule name="In Progress" bgColor="#fff5b5" colour="#e67426" />,
								easeCalculations: <Capsule name="In Progress" bgColor="#fff5b5" colour="#e67426" />,
						}
						: row,
				),
			);
			try {
				await productService.associatedCalculation(payload);
				await productService.commitAssociatedCalculation({
					productHandle: rowValues.handle,
					shopifyProductId: rowId,
				});
				setTableRowData((prev) =>
					prev.map((row) =>
						row.id === rowId
							? {
									...row,
									// associated: <Capsule name="Done" bgColor="#e7ffe7" colour="green" />,
									easeCalculations: <Capsule name="Done" bgColor="#e7ffe7" colour="green" />,
							}
							: row,
					),
				);
			} catch (error) {
				setTableRowData((prev) =>
					prev.map((row) =>
						row.id === rowId
							? {
									...row,
									// associated: <Capsule name="Error" bgColor="#ffe7e7" colour="red" />,
									easeCalculations: <Capsule name="Error" bgColor="#ffe7e7" colour="red" />,
							}
							: row,
					),
				);
			}
		},
		[tableRowData],
	);

	const tableDisplay2 = useCallback(() => {
		let obj = {};
		const data = productList?.map((list: any, index: number) => {
			const matchedTechpackSizing = techpackProductSizing?.find((sizing: any) => sizing?.shopifyProductId === list?.shopifyProductId);
			const mergedProductData = matchedTechpackSizing ? { ...list, ...matchedTechpackSizing } : list;
			const selectedFitModelId = mergedProductData?.fitmodelId;
			const selectedTechpackFileId = mergedProductData?.techpackId;
			const selectedBaseSize = mergedProductData?.baseSize;
			const showFlag = !!(selectedBaseSize && selectedTechpackFileId && selectedFitModelId);
			const showOpen = mergedProductData?.techpackId || matchedTechpackSizing?.tryonLaunchStatus==="approved" || matchedTechpackSizing?.tryonOnboarded  ;
			if (selectedBaseSize || selectedTechpackFileId || selectedFitModelId) {
				obj = {
					...obj,
					[mergedProductData?.shopifyProductId]: {
						fitModel: selectedFitModelId,
						techpack: selectedTechpackFileId,
						baseSize: selectedBaseSize,
						// associateStatus: "Pending",
						handle: mergedProductData?.handle,
					},
				};
			} else {
				obj = {
					...obj,
					[mergedProductData?.shopifyProductId]: {
						fitModel: "",
						techpack: "",
						baseSize: "",
						handle: mergedProductData?.handle,
						// associateStatus: "Pending",
					},
				};
			}
			const fitModelOptions =
				fitModelData?.map((model: any) => ({
					name: model?.name,
					value: model?.id,
					selected: model?.id === selectedFitModelId,
				})) || [];
			const techPackOptions = [
				...(Array.isArray(techPackData)
					? techPackData.map((techPack: any) => ({
							name: `${techPack?.productHandle || "-"} from ${techPack?.fileName || "-"}`,
							value: techPack?.parsedTableKey,
							selected: techPack?.parsedTableKey === selectedTechpackFileId,
					}))
					: []),
				...(Array.isArray(groupList)
					? groupList.reduce((acc:any[], group: any) => {
							if (group?.parsedTableKey || group?.id === selectedTechpackFileId) {
								acc.push({
									name: group?.name,
									value: group?.id,
									selected: group?.id === selectedTechpackFileId,
									disabled: !group?.parsedTableKey,
								});
							}
							return acc;
					}, [])
					: []),
			];
			const sizeOptions =
				mergedProductData?.size?.map((listSize: any) => ({
					name: listSize,
					value: listSize,
					selected: listSize === selectedBaseSize,
				})) || [];
			return {
				id: mergedProductData?.shopifyProductId,
				srNo: index + 1,
				productName: mergedProductData?.title,
				category: mergedProductData?.category ? mergedProductData?.category : "-",
				color: mergedProductData?.color ? mergedProductData?.color : [],
				style: mergedProductData?.style ? mergedProductData?.style : [],
				techpack: techPackOptions,
				fitModel: fitModelOptions,
				baseSize: mergedProductData?.size ? sizeOptions : "",
				measurementUsed: handleFitModelChange(mergedProductData?.shopifyProductId, selectedFitModelId),
				// associated: "Pending",
				showOpen,
				easeCalculations: showFlag ? "Done" : "Pending",
				handle: mergedProductData?.handle,
				showFlag,
			};
		});
		const allSelected = Object.keys(obj).every((id) => areAllRequiredValuesPresent(id, obj));
		setAllValuesSelected(allSelected);
		setSelectedValues({ ...obj });
		setTableRowData(data);
	}, [productList, techPackData, fitModelData, techpackProductSizing, groupList, selectedValues]);

	useEffect(() => {
		tableDisplay2();
	}, [productList, techPackData, fitModelData, techpackProductSizing, groupList]);

	useEffect(() => {
		dispatch(setUploadedFiles([]))
		dispatch({ type: HANDLE_GET_PRODUCTS_LIST, payload: true });
		return () => {
			dispatch(setProductList([]));
			dispatch(setUploadedFiles([]))
		};
	}, []);

	useEffect(() => {
		if (techpackParseDone) {
			handleClose();
		}
	}, [techpackParseDone]);

	return (
		<div className={`${styles.product} flex-column`}>
             {tableRowData.length > 0 && !productLoading &&(
					<div className={`${styles.product__filter} flex-wrap justify-between align-center`}>
						<div className={`${styles.product__filters} flex-wrap align-center`}></div>
						<div className={`${styles.product__filtersRight} flex-wrap  align-center`}>
							<span className={`${styles.product__filterBtn} flex align-center justify-center`} role="button" onClick={() => setOpen(true)}>
								<PlusIcon />
								Batch upload
							</span>
						</div>
					</div>
				)}
			<div className={`${styles.product__main}`}>
       
			
			
				{/* <div className={`${styles.product__filter} flex-wrap justify-between align-center`}>
					<div className={`${styles.product__filters} flex-wrap align-center`}>
						<CustomSelect outline placeholder="Status" icon={selectIcon} value="" type={SELECT_TYPE.NORMAL} />
						<CustomSelect outline placeholder="Status" icon={selectIcon} value="" type={SELECT_TYPE.NORMAL} />
						<CustomSelect outline placeholder="Gender" iconSpace value="" type={SELECT_TYPE.NORMAL} />
						<CustomSelect outline placeholder="Category" iconSpace value="" type={SELECT_TYPE.NORMAL} />
						<CustomSelect outline placeholder="Styles" iconSpace value="" type={SELECT_TYPE.NORMAL} />
						<CustomSelect outline placeholder="Sizes" iconSpace value="" type={SELECT_TYPE.NORMAL} />
						<CustomSelect outline placeholder="Colours" iconSpace value="" type={SELECT_TYPE.NORMAL} />
					</div>
					<div className={`${styles.product__filtersRight} flex-wrap  align-center`}>
						<span className={`${styles.product__filterBtn} flex align-center justify-center`} role="button">
							<img src={filter} alt="filter" />
							Filter
						</span>
						<Search placeholder="Search your dashboard" />
					</div>
				</div> */}
				<div className={`${styles.product__grid}`}>
					{tableRowData.length === 0 || productLoading ? (
						<div className="flex align-center justify-center h-full">
							<LoadingScreen height={false} />
						</div>
					) : (
						<CustomTable
							secondColSticky
							rows={tableRowData}
							columns={product_Columns}
							handleNameClicked={handleGetProductSizingDetails}
							handleValueChange={handleDropdownChange}
							showFlag={allValuesSelected}
						/>
					)}
				</div>
			</div>
			<div className={`${styles.product__button} flex justify-end`}>
				<CustomButton
					handleFunc={() => dispatch({ type: HANDLE_TECHPACK_PARSE, payload: { techpackFileBatchId } })}
					className="rounded semibold"
					buttonText="Send for Review"
					disabled={!batchFileUploaded || loading}
					loading={loading}
				/>
			</div>
			{showDrawer && (
				<CustomResizeDrawer mobileFull noImage={error}>
					<ProductDetails onClose={() => setShowDrawer(false)} />
				</CustomResizeDrawer>
			)}
			<SizingBatchUpload open={open} handleClose={() => {
				dispatch(setUploadedFiles([]))
				setOpen(false)}} showRedirect={false} isToParse={false} showFiles showDone />
			<CustomDialog className="match-modal" open={messageOpen} handleShowModal={handleClose}>
				<div className={styles.product__modal}>
					<span className={`${styles.product__close}`} role="button" onClick={handleClose}>
						<Close />
					</span>
					<article>
						<h3>Sent for Parsing & Matching</h3>
						<p>Swan will process them & email you when ready for review. </p>
					</article>
				</div>
			</CustomDialog>
		</div>
	);
};

export default Products;
