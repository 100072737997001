import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import { useEffect, useState } from "react";
import CustomSearchDropdown from "atoms/customSearchDropdown/CustomSearchDropdown";
import { SELECT_TYPE } from "lib/Constants";
import Chip from "atoms/chip/Chip";
// import CheckIcon from "component/customDataGrid/CheckIcon";
import openIcon from "../../assets/images/open-icon.svg";
import { Tooltip } from "@mui/material";
import { camelCaseToWords } from "lib/utils";

const CustomTable = ({
    rows,
    columns,
    handleNameClicked,
    handleValueChange,
    firstColSticky,
    secondColSticky,
    showFlag
}: any) => {
    const toShowColor = React.useMemo(() => rows?.some((el: Record<string, any>) => el?.color?.length > 0), [rows]);
    const toShowStyle = React.useMemo(() => rows?.some((el: Record<string, any>) => el?.style?.length > 0), [rows])


    return (
        <div className={`custom-table product-table ${firstColSticky ? `firstColSticky` : ``} ${secondColSticky ? `secondColSticky` : ``}`}>
            <div className="custom-table__inner">
                <Table stickyHeader aria-label="sticky table">
                    {rows?.length > 0 && (
                        <TableHead>
                            <TableRow>
                                {columns?.map((column: Record<string, any>) => {
                                    if (column.id === "color" && !toShowColor) {
                                        return null
                                    }
                                    if (column.id === "style" && !toShowStyle) {
                                        return null
                                    }
                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            className={`${column.checkbox ? "checkboxTd" : ""}
                                            ${column.id === "techpack" && !showFlag ? "fitModel-border" : ""}
                                         ${column.id === "baseSize" && !showFlag ? "baseSize-border" : ""}
                                         ${column.id === "fitModel" && !showFlag ? "baseSize-border-noBorder baseSize-border" : ""}
                                           ${((column.id === "techpack" || column.id === "fitModel" || column.id === "baseSize") && !showFlag)
                                                    ? "fitModel-top-border"
                                                    : ""}
                                    `}
                                            style={{
                                                minWidth: column.minWidth,
                                            }}

                                        >
                                            {column.checkbox ? <>
                                                <span className=" customCheckBox flex">
                                                    <label>
                                                        <input type="checkbox" disabled />
                                                        <span></span>
                                                    </label>
                                                </span>
                                            </> : <>
                                                <span className="head-with-icon flex">
                                                    <img src={column?.icon} />

                                                    {column.label}
                                                </span>
                                            </>}


                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        {rows?.length > 0 ? (
                            rows.map((row: any, rowIndex: number) => {
                                const isLastRow = rowIndex === rows.length - 1;
                                return (
                                    <React.Fragment key={rowIndex}>
                                        {row?.isLoading ? (
                                            <TableRow>
                                                <TableCell colSpan={columns?.length}>
                                                    <div className="table-loader">loader....</div>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            <TableRow hover role={"link"} tabIndex={-1} key={row?.id}>
                                                {columns.map((column: any) => {
                                                    const value: any = row[column?.id];
                                                    showFlag === false ? {
                                                        borderLeft: column.id === "fitModel" ? "2px solid red" : "",
                                                        borderRight: column.id === "baseSize" ? "2px solid red" : "",
                                                        borderBottom: (isLastRow && (column.id === "fitModel" || column.id === "baseSize"))
                                                            ? "2px solid red"
                                                            : ""
                                                    } : {};

                                                    switch (column.type) {
                                                        case "name":
                                                            return (
                                                                <TableCell
                                                                    key={column?.id}
                                                                    align={column?.align}
                                                                    className={`${column.id === "techpack" && !showFlag ? "fitModel-border" : ""}
                                                                    ${column.id === "baseSize" && !showFlag ? "baseSize-border" : ""}
                                                                      ${(isLastRow && (column.id === "techpack" || column.id === "fitModel" || column.id === "baseSize") && !showFlag)
                                                                            ? "fitModal-boarder-bottom"
                                                                            : ""}
                                                               `}
                                                                >
                                                                    <div className={`flex justify-between align-start productCell`}>
                                                                        <span className="flex align-center gap-4"> 
                                                                            
                                                                           {!row?.showFlag ? <Tooltip title="Please add missing details" placement="right-start">
                                                                            <span className="blue-dot"> </span>
                                                                        </Tooltip>: <span className="blue-dot transparent"> </span>}
                                                                        {value} 
                                                                        </span>
                                                                        {row?.showOpen && <span className={`openButton`}
                                                                            onClick={() => {
                                                                                handleNameClicked(row?.handle);
                                                                            }}
                                                                            role="button"
                                                                        >
                                                                            <img src={openIcon} alt="icon" /> OPEN
                                                                        </span>}

                                                                    </div>


                                                                </TableCell>
                                                            );
                                                        case "dropdown": {
                                                            const val = value?.find((item: any) => item?.selected === true)?.name || ""
                                                            return (
                                                                <TableCell
                                                                    key={column?.id}
                                                                    align={column?.align}
                                                                    //style={cellStyle}
                                                                    className={`${column.id === "techpack" && !showFlag ? "fitModel-border" : ""}
                                                                    ${column.id === "baseSize" && !showFlag ? "baseSize-border" : ""}
                                                                      ${(isLastRow && (column.id === "techpack" || column.id === "fitModel" || column.id === "baseSize") && !showFlag)
                                                                            ? "fitModal-boarder-bottom"
                                                                            : ""}
                                                               `}
                                                                >
                                                                    {(val && column.id === "techpack") ? <Chip title={val} /> : <CustomSearchDropdown
                                                                        bgGray
                                                                        placeholder={"Select"}
                                                                        value={value ? value?.find((item: any) => item.selected === true)?.name || "" : ""}
                                                                        handleChange={(newValue: string) => handleValueChange(row.id, column.id, newValue)}
                                                                        data={value || []}
                                                                        type={SELECT_TYPE.NORMAL}
                                                                    />}
                                                                </TableCell>
                                                            );
                                                        }
                                                        case "pills":
                                                            if (column.id === "color" && !toShowColor) {
                                                                return null
                                                            }
                                                            if (column.id === "style" && !toShowStyle) {
                                                                return null
                                                            }
                                                            return (
                                                                <TableCell
                                                                    key={column?.id}
                                                                    align={column?.align}
                                                                    className={`${column.id === "fitModel" && !showFlag ? "fitModel-border" : ""}
                                                                   ${column.id === "baseSize" && !showFlag ? "baseSize-border" : ""}
                                                                     ${(isLastRow && (column.id === "fitModel" || column.id === "baseSize") && !showFlag)
                                                                            ? "fitModal-boarder-bottom"
                                                                            : ""} 
                                                              `}
                                                                >
                                                                    {value?.length > 0 ? (
                                                                        <div className="productChip">
                                                                            <Chip title={value?.[0] || ""} />
                                                                            {value?.length > 2 && (
                                                                                <Tooltip title={value?.slice(1)?.map((el: string) => camelCaseToWords(el)).join(", ")} enterTouchDelay={0} leaveTouchDelay={3000}>
                                                                                    <span>
                                                                                        <Chip title={`+${value?.length - 1}`} />
                                                                                    </span>
                                                                                </Tooltip>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        "-"
                                                                    )}
                                                                </TableCell>
                                                            );

                                                        default:
                                                            return (
                                                                <TableCell key={column.id} align={column.align}
                                                                    //style={cellStyle}
                                                                    className={`${column.id === "fitModel" && !showFlag ? "fitModel-border" : ""}
                                                                ${column.id === "baseSize" && !showFlag ? "baseSize-border" : ""}
                                                                  ${(isLastRow && (column.id === "fitModel" || column.id === "baseSize") && !showFlag)
                                                                            ? "fitModal-boarder-bottom"
                                                                            : ""} 
                                                           `}
                                                                >
                                                                    <div>
                                                                        <span>{value ?? "-"}</span>
                                                                    </div>
                                                                </TableCell>
                                                            );
                                                    }
                                                })}
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

export default CustomTable;
