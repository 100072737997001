import styles from "./Sidebar.module.scss";
import logo from "assets/images/swan.svg";
// import Data from "../../assets/icons/data";
// import Chart from "assets/icons/chart";
// import Book from "../../assets/icons/book";
// import Send from "../../assets/icons/send";
// import UserGroup from "assets/icons/userGroup";
import CreditCard from "assets/icons/creditCard";
import LogOut from "assets/icons/logOut";
// import { Tooltip } from "@mui/material";
import { AUTH_TYPE } from "enum/enums";
import { Link, NavLink } from "react-router-dom";
import { ROUTES } from "lib/Constants";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useDispatch } from "react-redux";
import { HANDLE_AUTH } from "store/auth/action.types";
import HomeIcon from "assets/icons/homeIcon";
import PackageIcon from "assets/icons/package";
import Chart from "assets/icons/chart";
import CustomNewIcon from "assets/icons/customIcon";

const Sidebar = () => {
  const {isInternalUser,hasCustomAddon} = useSelector((store:RootState)=>store.auth)
  const dispatch = useDispatch()

  return (
    <>
      <div className={`${styles.sidebar}`}>
        <div>
          <div className={`${styles.sidebar__logo}`}>
        
            <Link to={ROUTES.HOME}>
            <>
            <span className={`${styles.sidebar__menu}`}>
            <span className={`${styles.sidebar__menuIcon}`}>
            <img className={`${styles.sidebar__logoImg}`} src={logo} alt="swan" title="swan" />
            </span>
            <span className={`${styles.sidebar__menuText}`}><strong>SWAN</strong></span>
            </span>
            </>
            </Link>
          </div>
          <ul>
          { hasCustomAddon && <>
            <li>
            <NavLink to={ROUTES.HOME}>
            {({ isActive }) => (
              <>
                   <span className={`${isActive ? styles.active:""} ${styles.sidebar__menu}`}>
                 <span role="button" className={` ${styles.sidebar__menuIcon}`}>
                   <CustomNewIcon />
                 </span>
                 <span className={`${styles.sidebar__menuText}`}>
                    Custom
                 </span>
                 </span>
                 </>
                )}
                  </NavLink>
            </li>
            </>
}
           { !hasCustomAddon && <>
            <li>
            <NavLink to={ROUTES.HOME}>
            {({ isActive }) => (
              <>
                   <span className={`${isActive ? styles.active:""} ${styles.sidebar__menu}`}>
                 <span role="button" className={` ${styles.sidebar__menuIcon}`}>
                   <HomeIcon />
                 </span>
                 <span className={`${styles.sidebar__menuText}`}>
                    Home
                 </span>
                 </span>
                 </>
                )}
                  </NavLink>
            </li>
            <li>
            <NavLink to={ROUTES.PRODUCTS}>
            {({ isActive }) => (
              <>
                   <span className={`${isActive ? styles.active:""} ${styles.sidebar__menu}`}>
                 <span role="button" className={` ${styles.sidebar__menuIcon}`}>
                   <PackageIcon />
                 </span>
                 <span className={`${styles.sidebar__menuText}`}>
                 Products
                 </span>
                 </span>
                 </>
                )}
                  </NavLink>
            </li>
            <li>
            <NavLink to={ROUTES.ANALYTICS}>
            {({ isActive }) => (
              <>
                   <span className={`${isActive ? styles.active:""} ${styles.sidebar__menu}`}>
                 <span role="button" className={` ${styles.sidebar__menuIcon}`}>
                 <Chart />
                 </span>
                 <span className={`${styles.sidebar__menuText}`}>
                 Analytics
                 </span>
                 </span>
                 </>
                )}
                  </NavLink>
            </li>
            </>}
           
            {/* <li>
            <NavLink to={ROUTES.ANALYTICS}>
                {({ isActive }) => (
                 <Tooltip title="Metrics">
                 <span role="button" className={isActive ? styles.active:""}>
                   <Chart />
                 </span>
               </Tooltip>
                )}
              </NavLink>
            </li> */}
          </ul>
        </div>
        <ul>
        {/* <li>
            
                   <span className={` ${styles.sidebar__menu}`}>
                 <span role="button" className={` ${styles.sidebar__menuIcon}`}>
                 <UserGroup />
                 </span>
                 <span className={`${styles.sidebar__menuText}`}>
                {userDetails?.store_name || "store"} 
                 </span>
                 </span>
                
               
            </li> */}
        
          {isInternalUser && (
              <li>
             <Link to={ROUTES.CUSTOMERS_LIST}>
            
             
                     <span className={` ${styles.sidebar__menu}`}>
                   <span role="button" className={` ${styles.sidebar__menuIcon}`}>
                   <CreditCard />
                   </span>
                   <span className={`${styles.sidebar__menuText}`}>
                   Change store
                   </span>
                   </span>
                 
                 
                 </Link>
              </li>
    
          )}
           <li>
             <Link to={ROUTES.CUSTOMERS_LIST}>
            
             
                     <span className={` ${styles.sidebar__menu}`} onClick={() => dispatch({type:HANDLE_AUTH,payload:AUTH_TYPE.LOGOUT})}>
                   <span role="button" className={` ${styles.sidebar__menuIcon}`}>
                   <LogOut />
                   </span>
                   <span className={`${styles.sidebar__menuText}`}>
                   Logout
                   </span>
                   </span>
                 
                 
                 </Link>
              </li>
   
        </ul>
      </div>
    </>
  );
};
export default Sidebar;
