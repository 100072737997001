import { Dialog } from "@mui/material";
import React from "react";

interface CustomDialogProps {
  children: React.ReactNode;
  open: boolean;
  handleShowModal?: () => void;
  className?: string;
}

const CustomDialog = ({ children, open, handleShowModal, className = "custom-dialog" }: CustomDialogProps) => {
  return (
    <Dialog className={className} open={open} onClose={ handleShowModal && handleShowModal} aria-labelledby="responsive-dialog-title">
      {children}
    </Dialog>
  );
};

export default CustomDialog;
