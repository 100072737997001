
const HomeIcon = () => {
    return (
        <>
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
<path d="M6.39597 15.753V10.203C6.39597 9.78294 6.39597 9.57292 6.47341 9.41249C6.54153 9.27136 6.65023 9.15663 6.78392 9.08472C6.93591 9.00298 7.13488 9.00298 7.53281 9.00298H9.52229C9.92022 9.00298 10.1192 9.00298 10.2712 9.08472C10.4049 9.15663 10.5136 9.27136 10.5817 9.41249C10.6591 9.57292 10.6591 9.78294 10.6591 10.203V15.753M7.8296 2.07599L3.01059 6.03233C2.68846 6.2968 2.52739 6.42903 2.41136 6.59463C2.30857 6.74132 2.232 6.90658 2.18541 7.08228C2.13281 7.28063 2.13281 7.49601 2.13281 7.92678V13.353C2.13281 14.1931 2.13281 14.6131 2.2877 14.934C2.42394 15.2162 2.64133 15.4457 2.90872 15.5895C3.2127 15.753 3.61063 15.753 4.4065 15.753H12.6486C13.4445 15.753 13.8424 15.753 14.1464 15.5895C14.4138 15.4457 14.6312 15.2162 14.7674 14.934C14.9223 14.6131 14.9223 14.1931 14.9223 13.353V7.92678C14.9223 7.49601 14.9223 7.28063 14.8697 7.08228C14.8231 6.90658 14.7465 6.74132 14.6437 6.59463C14.5277 6.42903 14.3666 6.2968 14.0445 6.03234L9.2255 2.07599C8.97588 1.87105 8.85106 1.76858 8.71324 1.72919C8.59164 1.69444 8.46346 1.69444 8.34186 1.72919C8.20403 1.76858 8.07922 1.87105 7.8296 2.07599Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        </>
    );
};

export default HomeIcon;