/* eslint-disable @typescript-eslint/no-unused-vars */
import { DATE_PICKER_VALUE, Metrics } from "enum/enums";
import { ApexChartProps } from "interface/interface";
import { EVENT_TIMESTAMP_KEY, TODAY_DATE } from "lib/Constants";
import { formatTime, getDaysSinceMonthStart } from "lib/utils";
import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import lockIcon from "../assets/images/lock.svg";

const toolTipDateFormat = (data: string) => {
	const date = new Date(data);
	return date.toLocaleDateString(undefined, { day: "2-digit", month: "short" });
};
const AreaApexChart = ({ dataOne = [], dataTwo = [], datePickerValue, showYaxis = true, height = 350, showXaxis = true, type, locked }: ApexChartProps) => {
	const maxi = useMemo(() => {
		const arr1 = dataOne?.map((el) => el?.count ?? 0);
		const arr2 = dataTwo?.map((el) => el?.count ?? 0);
		const val1 = arr1.length ? Math.max(...arr1) : 0;
		const val2 = arr2.length ? Math.max(...arr2) : 0;
		return Math.max(val1, val2);
	}, [dataOne, dataTwo]);

	const labelsCount = useMemo(() => {
		switch (datePickerValue) {
			case DATE_PICKER_VALUE.THIRTY_DAYS: {
				return 30;
			}
			case DATE_PICKER_VALUE.FOURTEEN_DAYS: {
				return 14;
			}
			case DATE_PICKER_VALUE.SEVEN_DAYS: {
				return 7;
			}
			case DATE_PICKER_VALUE.THIS_QUATER: {
				return 120;
			}
			case DATE_PICKER_VALUE.THIS_MONTH: {
				return getDaysSinceMonthStart(TODAY_DATE);
			}
		}
		return 7;
	}, [datePickerValue]);

	const labels = useMemo(() => {
		const label = [];
		for (let i = 0; i < labelsCount; i++) {
			const today = new Date();
			const date = new Date(today.setDate(today.getDate() - i)).toISOString().split("T")[0];
			label.push(date);
		}
		return label;
	}, [labelsCount]);

	const modDataOne = useMemo(
		() =>
			dataOne?.reduce((acc, key, index: number) => {
				if (index === 0 && key?.[EVENT_TIMESTAMP_KEY]?.split("T")?.[0] !== labels[0]) {
					acc.push({ x: new Date(labels[0]), y: 0 });
				}
				acc.push({ x: new Date(key?.[EVENT_TIMESTAMP_KEY]), y: key?.count ?? 0 });
				if (index === dataOne?.length - 1 && key?.[EVENT_TIMESTAMP_KEY]?.split("T")?.[0] !== labels[labelsCount - 1]) {
					acc.push({ x: new Date(labels[labelsCount - 1]), y: 0 });
				}
				return acc;
			}, []),
		[dataOne, labels, labelsCount],
	);

	const modDataTwo = useMemo(
		() =>
			dataTwo?.reduce((acc, key, index: number) => {
				if (index === 0 && key?.[EVENT_TIMESTAMP_KEY]?.split("T")?.[0] !== labels[0]) {
					acc.push({ x: new Date(labels[0]), y: 0 });
				}
				acc.push({ x: new Date(key?.[EVENT_TIMESTAMP_KEY]), y: key?.count ?? 0 });
				if (index === dataTwo?.length - 1 && key?.[EVENT_TIMESTAMP_KEY]?.split("T")?.[0] !== labels[labelsCount - 1]) {
					acc.push({ x: new Date(labels[labelsCount - 1]), y: 0 });
				}
				return acc;
			}, []),
		[dataTwo, labels, labelsCount],
	);

	const series = useMemo(() => {
		const arr = [
			{
				name: "",
				data: modDataOne,
			},
		];
		if (modDataTwo.length) {
			arr.push({
				name: "",
				data: modDataTwo,
			});
		}
		return arr;
	}, [modDataOne, modDataTwo]);


	const minDate1 = useMemo(
		() => (modDataOne.length > 0 ? new Date(Math.min(...modDataOne.map((item: any) => item.x.getTime()))) : null),
		[modDataOne],
	);

	const maxDate1 = useMemo(
		() => (modDataOne.length > 0 ? new Date(Math.max(...modDataOne.map((item: any) => item.x.getTime()))) : null),
		[modDataOne],
	);

	const minDate2 = useMemo(
		() => (modDataTwo.length > 0 ? new Date(Math.min(...modDataTwo.map((item: any) => item.x.getTime()))) : null),
		[modDataTwo],
	);

	const maxDate2 = useMemo(
		() => (modDataTwo.length > 0 ? new Date(Math.max(...modDataTwo.map((item: any) => item.x.getTime()))) : null),
		[modDataTwo],
	);

	const overallMinDate = useMemo(() => {
		if (minDate1 !== null && minDate2 !== null) {
			return new Date(Math.min(minDate1.getTime(), minDate2.getTime()));
		}
		return minDate1 || minDate2; // Return the non-null date
	}, [minDate1, minDate2]);

	const overallMaxDate = useMemo(() => {
		if (maxDate1 !== null && maxDate2 !== null) {
			return new Date(Math.max(maxDate1.getTime(), maxDate2.getTime()));
		}
		return maxDate1 || maxDate2; // Return the non-null date
	}, [maxDate1, maxDate2]);

	const options = {
		chart: {
			height: height,
			type: "line",
			zoom: {
				enabled: false,
			},
			toolbar: {
				show: false,
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "straight",
			width: 1.5,
		},
		colors: ["#5932F3", "#F35B32"],
		grid: {
			row: {
				colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
				opacity: 0.5,
			},
			show: showYaxis,
		},
		legend: {
			show: false,
		},
		xaxis: {
			type: "datetime",
			categories: labels,
			decimalsInFloat: false,
			labels: {
				showDuplicates: false,
				offsetX: 10,
				offsetY: 10,
				style: {
					fontSize: "12px",
					colors: "#828282",
				},
				show: showXaxis,
				hideOverlappingLabels: true,
				formatter: function (value: any, timestamp: any): string {
					const date = new Date(timestamp);
					if (date.getTime() === overallMinDate?.getTime()) {
						return date.toLocaleDateString(undefined, { day: "2-digit", month: "short" });
					} else if (date.getTime() === overallMaxDate?.getTime()) {
						return "Today";
					}
					return "";
				},
			},
			axisTicks: {
				show: false,
			},
			tooltip: {
				enabled: false,
			},
		},
		yaxis: {
			min: 0,
			tickAmount: maxi + 1,
			max: maxi + 1,

			labels: {
				show: showYaxis,
			},
			floating: true,
		},
		tooltip: {
			marker: {
				show: true,
			},
			custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
				const dataPointOne = modDataOne[dataPointIndex];
				const dataPointTwo = modDataTwo[dataPointIndex];
				const dateValue = dataPointOne?.x || dataPointTwo?.x;
				const date = new Date(dateValue);

				const formattedDate =
					date.getTime() === overallMaxDate?.getTime() ? "Today" : date.toLocaleDateString(undefined, { day: "2-digit", month: "short" });

				let yValueOne = dataPointOne?.y ?? 0;
				let yValueTwo = dataPointTwo?.y ?? 0;

				if (
					type === Metrics.TRY_ON_INTERACTION_TIME ||
					type === Metrics.AVERAGE_TIME_SPENT_PER_SCAN ||
					type === Metrics.AVERAGE_TIME_SPENT_PER_TRY_ON
				) {
					yValueOne = formatTime(Math.round(yValueOne));
					yValueTwo = formatTime(Math.round(yValueTwo));
				}
				if (modDataTwo.length) {
					return `
					<div class="tooltip-graph">
						<strong>${formattedDate}:</strong>
						<div>success: <span>${yValueOne}</span></div>
						<div>fail: <span>${yValueTwo}</span></div>
					</div>
				`;
				}
				return `
					<div class="tooltip-graph">
						<strong>${formattedDate}:</strong>
						<span>${yValueOne}</span>
					</div>
				`;
			},
			fixed: {
				enabled: false,
				position: "topRight",
				offsetX: 0,
				offsetY: 0,
			},
		},
	} as any;

	return (
		<div className={`w-full ${!maxi && "graph-line"}`}>
			{!maxi ? (
				<>
					<div className="graph-date">
						<span>{toolTipDateFormat(labels[labelsCount - 1])}</span>
						<span>Today</span>
					</div>
				</>
			) : (
				<ReactApexChart options={options} series={series} type="line" width="100%" height={height} />
			)}
            {locked && <>
                <div className="graph-lock">
                    <img src={lockIcon} alt="icon" />
                </div>
            </>}
		</div>
	);
};

export default AreaApexChart;
