import React from "react";
import { CircularProgress } from "@mui/material";

const CircularLoader = ({ size = 40 }: { size?: number; height?: boolean }) => {
  return (
    <div>
      <CircularProgress size={size} />
    </div>
  );
};

export default CircularLoader;
