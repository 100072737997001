import { ROUTES } from 'lib/Constants';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from 'store/store'
import styles from "./CustomDetails.module.scss";
import NoDataPlaceHolder from 'component/NoDataPlaceHolder';
import CustomNameDetailLeft from 'component/customDetailsChild/CustomNameDetailLeft';
import CustomNameDetailRight from 'component/customDetailsChild/CustomNameDetailRight';
// import topHeader from "../../assets/images/custom-header.png"
import BackButton from 'component/backButton/BackButton';

const CustomDetails = () => {
  const {selectedValue} = useSelector((state:RootState)=>state.custom);
  const navigate = useNavigate()
  useEffect(()=>{    
    if(!Object.keys(selectedValue).length){
      navigate(ROUTES.HOME)
    }
  },[])

  return (
    <div className={` ${styles.custom}`}>
    <div className={`flex-wrap align-center justify-between ${styles.custom__header}`}>
     <Link to={ROUTES.HOME}>
     <div className={`flex-wrap align-center  ${styles.custom__back}`}>
        <BackButton/>
      </div>
     </Link>
    </div>

    <div className={` ${styles.custom__info}`}>
      {Object.keys(selectedValue)?.length > 0 ? (
        <>
          <CustomNameDetailLeft  />
          <CustomNameDetailRight  />
        </>
      ) : (
        <div className={`flex-wrap alignCenter justifyCenter w-full ${styles.custom__noData}`}>
          <NoDataPlaceHolder message="No Data Found." />
        </div>
      )}
    </div>
  </div>
  )
}

export default CustomDetails