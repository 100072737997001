export const HANDLE_GET_PRODUCTS_LIST="HANDLE_GET_PRODUCTS_LIST";
export const HANDLE_GET_TECHPACK_PRODUCT_SIZING="HANDLE_GET_TECHPACK_PRODUCT_SIZING";
export const HANDLE_PRODUCT_ASSOCIATED_CALCULATION="HANDLE_PRODUCT_ASSOCIATED_CALCULATION";
export const HANDLE_PRODUCT_SIZING_DETAILS="HANDLE_PRODUCT_SIZING_DETAILS";
export const HANDLE_PRODUCT_ONBOARDING="HANDLE_PRODUCT_ONBOARDING";
export const HANDLE_ONBOARDING_SUMMARY="HANDLE_ONBOARDING_SUMMARY";




