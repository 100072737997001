import CustomButton from "atoms/customButton/CustomButton";
import Input from "atoms/input/Input";
import React, { useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styles from "./MeasurementGroups.module.scss";
import { useDispatch } from "react-redux";
import { HANDLE_CUSTOMISE_MESUREMENT_UPDATE } from "store/measurement/action.types";
import Upload from "atoms/upload/Upload";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import closeIcon from "../../assets/images/x-close.svg";
import { Close } from "@mui/icons-material";
interface FormData {
    measurementName: string;
    definedAs: string;
}
interface CustomiseMeasurementProps{
    data:Record<string,string>|undefined;
    handleClose:()=>void
}

const CustomiseMeasurement = ({data={},handleClose}:CustomiseMeasurementProps) => {
    const [file,setFile] = useState<File|undefined>()
    const {customiseMeasurementLoading}  = useSelector((state:RootState)=>state.measurement)
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            measurementName: "",
            definedAs: "",
        },
    });

    const dispatch= useDispatch()
    const handleFileOnChange=useCallback((files:File[])=>{
        setFile(files[0])
        },[file])
    const onSubmit:SubmitHandler<FormData> = (values) => { 
        let payload:any={action:"override",overrideName:values.measurementName.trim(),overrideDefinition:values.definedAs.trim()}
        if (file) {
            payload ={...payload,file:file}
        }    
        dispatch({type:HANDLE_CUSTOMISE_MESUREMENT_UPDATE, payload:{data:payload, measureDefKey:data?.measureDefKey}})
    }
 
    return (
        <div className={`${styles.measurementModal}`}>
             <span className={`${styles.measurementModal__close}`} role="button" onClick={handleClose}><img src={closeIcon} alt="close" /></span>
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.measurementModal__header}`}>
                <h2>Customise measurement</h2>
                <p> If you use a different name or definition, share it below. </p>
            </div>
            <div className={`flex-wrap ${styles.measurementModal__info}`}>
                <div className={`${styles.measurementModal__infoLeft}`}>
                    <h5>{data?.measurementName}</h5>
                    <p>{data?.definition} </p>
                </div>
                <div className={`${styles.measurementModal__infoRight}`}>
                    <Input
                        placeholder=""
                        type="text"
                        label="We call this measurement"
                        register={register}
                        required={false}
                        inputName="measurementName"
                        errorMessage={errors.measurementName && "Measurement name is required "}
                        smallInput
                    />
                    <Input
                        type="textarea"
                        label="Defined as"
                        register={register}
                        inputName="definedAs"
                        required={false}
                        errorMessage={errors.definedAs && "Definition is required "}
                        smallInput
                    />
                    <div className={`${styles.measurementModal__infoRightText}`}>
                        <p>Add supporting image/file</p>
                      <span className="inline-flex" role="button"> 
                        {file ? <><div className={styles.uploadFileInfo}>
							<span>{file?.name}</span>
							<Close onClick={()=>setFile(undefined)} />
						</div></> :<Upload accept="image/*" handleFileOnChange={handleFileOnChange}  subHeading="Click to upload" isDragAndDrop={false}/>}
                        </span>
                    </div>
                </div>
            </div>
            <div className={`${styles.measurementModal__button} flex justify-end`}>
            <CustomButton type="submit" buttonText="Submit" className="button xsm rounded full" disabled={customiseMeasurementLoading || !(watch("definedAs").trim()||watch("measurementName").trim()|| file)} loading={customiseMeasurementLoading}/>
            </div>
            </form>
        </div>
    );
};

export default CustomiseMeasurement;
