import MenuCard from 'atoms/menuCard/MenuCard'
import React from 'react'
import styles from './HomeMenu.module.scss';
import product from 'assets/images/product-dashboard.jpg';
import metric from 'assets/images/metric.png';
// import playground from 'assets/images/playground-image.png';
// import invites from 'assets/images/invites.png';
import { Link } from 'react-router-dom';
import { ROUTES } from 'lib/Constants';




const HomeMenu = () => {
  return (
    <div className={`${styles.homeMenu} flex-column`}>
        <Link to={ROUTES.PRODUCTS}><MenuCard blue title="Products" para='Full database view of all your product info' imageUrl={product}/></Link>
        <Link to={ROUTES.ANALYTICS}><MenuCard green title='Metrics' para='Deep analytics to track engagement and success' imageUrl={metric}/></Link>
        {/* <MenuCard pink title='Playground' para='Test before launch' imageUrl={playground}/>
        <MenuCard purple imageEnd title='Invites' para='Share' imageUrl={invites}/> */}
    </div>
  )
}

export default HomeMenu