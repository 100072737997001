import { TOAST_TYPES } from "enum/enums";
import React, { useCallback } from "react";
import ErrorIcon from "assets/images/error-toast-icon.svg";
import SuccessIcon from "assets/images/success-toast-icon.svg";
import WarningIcon from "assets/images/warning-toast-icon.svg";


interface CustomToastProps {
  message: string;
  type: string;
  description?:string;
  handleDismiss?: () => void;
  handleViewChanges?:() => void; 
}

const CustomToast: React.FC<CustomToastProps> = ({ message, type,description,handleDismiss,handleViewChanges }) => {
  const handleImage = useCallback(() => {
    switch (type) {
      case TOAST_TYPES.ERROR:
        return ErrorIcon //replace by error icon
      case TOAST_TYPES.WARNING:
        return WarningIcon //replace by info icon
      case TOAST_TYPES.SUCCESS:
        return SuccessIcon //replace by success icon
    }
  },[type]);

  return (
    <div className="custom-toaster">
      <div className="custom-toaster__info">
        <figure>
          <img src={handleImage()} alt="icon" />
        </figure>
        <figcaption>
          <h5>{message}</h5>
          <p>{description && description}</p>
          {handleDismiss && handleViewChanges && (
            <div className="custom-toaster__action">
             {handleDismiss && <a className="custom-toaster__actionDismiss" onClick={handleDismiss}>Dismiss</a>}
             {handleViewChanges && <a className="custom-toaster__actionView" onClick={handleViewChanges}>View changes</a>}
            </div>
          )}
        </figcaption>
      </div>
    </div>
  );
};

export default CustomToast;
