import React from "react";
import styles from "./MeasurementDetails.module.scss";
import { Close } from "@mui/icons-material";
import Dummy from "../../assets/images/fit-modal.jpg";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import LoadingScreen from "component/LoadingScreen";
import NoDataPlaceHolder from "component/NoDataPlaceHolder";
interface MeasurementDetailsPorps {
  onClose: () => void;
}

const MeasurementDetails = ({ onClose }: MeasurementDetailsPorps) => {
  const { individualMeasurementdef, individualMeasurementDefLoading,error} =
    useSelector((state: RootState) => state.measurement);
    
  return (
      <div className={`${styles.details}`}>
        <span
          className={`${styles.details__close}`}
          role="button"
          onClick={onClose}
        >
          <Close />
        </span>
        {individualMeasurementDefLoading ? (
          <div className="flex align-center justify-center h-full">
					<LoadingScreen height={false} />
				</div>) : error ? <div className="flex align-center justify-center h-full">
					<NoDataPlaceHolder message="No data to show" showOnlyMessage />
				</div>  :
          (
          <div className={`${styles.details__wrapper}`}>
            <div className={`${styles.details__header}`}>
              <h2>Measurement Details</h2>
            </div>

            <div className={`${styles.details__Other}`}>
              <h3>{individualMeasurementdef?.measurementName}</h3>
              <div className={`${styles.details__Images}`}>
                <ul className="flex-wrap">
                  <li>
                    <figure>
                      <img
                        src={individualMeasurementdef?.measurementImageUrl ? individualMeasurementdef?.measurementImageUrl : Dummy}
                        alt="image"
                      />
                    </figure>
                  </li>
                </ul>
              </div>
              <div className={`${styles.details__ImagesDesc}`}>
                <h4>With image description</h4>
                <p>{individualMeasurementdef?.definition}</p>
              </div>
            </div>
          </div>
        )}
      </div>
  );
};

export default MeasurementDetails;
