export const HANDLE_GET_FIT_MODEL_LIST="HANDLE_GET_FIT_MODEL_LIST";
export const HANDLE_DELETE_FIT_MODEL="HANDLE_DELETE_FIT_MODEL";
export const HANDLE_CREATE_FIT_MODEL="HANDLE_CREATE_FIT_MODEL";
export const HANDLE_UPDATE_FIT_MODEL_MEASUREMENT="HANDLE_UPDATE_FIT_MODEL_MEASUREMENT";
export const HANDLE_DEBOUNCE_UPDATE_FIT_MODEL_MEASUREMENT="HANDLE_DEBOUNCE_UPDATE_FIT_MODEL_MEASUREMENT";
export const HANDLE_UPDATE_FIT_MODEL_NAME="HANDLE_UPDATE_FIT_MODEL_NAME";




