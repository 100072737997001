// toast reducer
import { createSlice } from "@reduxjs/toolkit";
import { TOAST_TYPES } from "enum/enums";
import { toast } from "react-toastify";
import CustomToast from "component/customToast/CustomToast";

const toasterSlice = createSlice({
  name: "toaster",
  initialState: null,
  reducers: {
    addToast: (state: any, action) => {  
      if (!action.payload.message) return state;
      const CustomComponent = (
        <CustomToast
          message={action.payload.message}
          type = {action.payload.type}
        />
      );
      switch (action.payload.type) {
        case TOAST_TYPES.ERROR:
          toast.error(CustomComponent);
          break;
        case TOAST_TYPES.WARNING:
          toast.info(CustomComponent);
          break;
        case TOAST_TYPES.SUCCESS:
          toast.success(CustomComponent);
          break;
        default:
          toast(CustomComponent);
          break;
      }
    },
  },
});

export const { addToast } = toasterSlice.actions;
export default toasterSlice.reducer;

