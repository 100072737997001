import React, { useEffect } from "react";
import "./assets/scss/style.scss";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { HANDLE_AUTH, HANDLE_TOKEN } from "store/auth/action.types";
import interceptor from "services/interceptor";
import { AUTH_TYPE } from "enum/enums";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';



function App() {
 const dispatch = useDispatch()
  useEffect(()=>{
    dispatch({type:HANDLE_TOKEN})
    interceptor(()=>{dispatch({type:HANDLE_AUTH,payload:AUTH_TYPE.LOGOUT})});
  },[dispatch])


  return (
    <>
        <Outlet />
        <ToastContainer  hideProgressBar icon={false} />
    </>
  );
}

export default App;
