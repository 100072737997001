import CustomTableMui from "component/customTableMui/CustomTableMui";
import { measurementColumns } from "component/customTableMui/tableData";
import { MUI_TABLE_TYPE, ROUTES } from "lib/Constants";
import { getConfirmedMeasurements } from "lib/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HANDLE_GET_MEASUREMENT_DEF, HANDLE_GET_MEASUREMENT_LIST } from "store/measurement/action.types";
import { RootState } from "store/store";
import styles from "./MeasurementTable.module.scss";
import plus from "assets/images/plus-icon.svg";
import CustomResizeDrawer from "component/customResizeDrawer/CustomResizeDrawer";
import MeasurementDetails from "component/measurementDetails/MeasurementDetails";
import BackButton from "component/backButton/BackButton";

const MeasurementTable = () => {
	const [showDrawer, setShowDrawer] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { measurementList, error } = useSelector((state: RootState) => state.measurement);
	const [rowsArray, setRowsArray] = useState<{ id: string; name: string; definition: string }[]>([]);
	const handleMeasurementDetails = (value: any) => {
		dispatch({ type: HANDLE_GET_MEASUREMENT_DEF, payload: value });
		setShowDrawer(true);
	};
	useEffect(() => {
		if (Object.keys(measurementList).length > 0) {
			const confirmed = getConfirmedMeasurements(measurementList);
			setRowsArray(confirmed);
		}
	}, [measurementList]);

	useEffect(() => {
		dispatch({ type: HANDLE_GET_MEASUREMENT_LIST });
	}, []);

	return (
		<div className={`${styles.measurementTable}`}>
              <BackButton route={ROUTES.SIZING_START}/>
			<div className="flex justify-end">
              
				<Link className={`${styles.measurementTable__add}`} to={ROUTES.MEASUREMENT_GROUPS}>
					<img src={plus} alt="plus" /> Add Measurement
				</Link>
			</div>
			<div className={`${styles.measurementTable__table}`}>
				<CustomTableMui
					type={MUI_TABLE_TYPE.MEASUREMENT}
					rows={rowsArray}
					columns={measurementColumns}
					handleChange={() => console.log(1)}
					handleMeasurementDetails={handleMeasurementDetails}
				/>
				{showDrawer && (
					<CustomResizeDrawer mobileFull noImage={error}>
						<MeasurementDetails onClose={() => setShowDrawer(false)} />
					</CustomResizeDrawer>
				)}
			</div>
		</div>
	);
};

export default MeasurementTable;
