import { all, call, put, takeLatest } from "redux-saga/effects";
import { setCustomerList, setCustomerSearchList, setListLoading } from "./reducer";
import { getCustomersList } from "services/authServices";
import { addToast } from "store/toaster/reducer";
import { TOAST_TYPES } from "enum/enums";
import { handleErrorMessage } from "lib/utils";
import { CUSTOMER_LIST } from "./action.types";




function*  fetchCustomersList():Generator {
    yield put(setListLoading(true));
    try {
      const res:any = yield call(getCustomersList);
      yield all([put(setCustomerList(res?.data)),put(setCustomerSearchList(res?.data)) ])
    } catch (error) {
      yield put(addToast({type:TOAST_TYPES.ERROR,messsage: handleErrorMessage(error)}));
    } finally {
      yield put(setListLoading(false));
    }
  }




function* customerSaga() {
    yield takeLatest(CUSTOMER_LIST, fetchCustomersList);

  }
export default customerSaga