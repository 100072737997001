import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./ProductDetails.module.scss";
import { FormControlLabel, Switch } from "@mui/material";
import link from "assets/images/link.svg";
import user from "assets/images/user-01.svg";
import list from "assets/images/list-icon.svg";
import scissors from "assets/images/scissors.svg";
import layers from "assets/images/layers-three-02.svg";
import colors from "assets/images/colors.svg";
import image from "assets/images/image-01.svg";
import file from "assets/images/paperclip.svg";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import LoadingScreen from "component/LoadingScreen";
import { MeasurementRowOptions, MUI_TABLE_TYPE, TECHPACK_FILE_TYPE } from "lib/Constants";
import PdfViewer from "component/pdfViewer/PdfViewer";
import XlsxViewer from "component/xlsxViewer/XlsxViewer";
import NoDataPlaceHolder from "component/NoDataPlaceHolder";
import CustomTableMui from "component/customTableMui/CustomTableMui";
import { createReviewData, deepCopy, generateUuid, getColumnsDataTechpack, handleErrorMessage, makeMeasurementRowSelected, modifyMeasurementRow } from "lib/utils";
import FitModelTable from "component/fitModelTable/FitModelTable";
import { useDispatch } from "react-redux";
import { HANDLE_DEBOUCE_UPDATE_TECHPACK_PARSE } from "store/techpack/action.types";
import { HANDLE_PRODUCT_ONBOARDING } from "store/products/action.types";
import {  HANDLE_TRY_ON_ONBAORD } from "store/tryOn/action.types";
import ReviewImages from "component/reviewImages/ReviewImages";
import tryOnService from "services/tryOnService";
import { addToast } from "store/toaster/reducer";
import { TOAST_TYPES } from "enum/enums";
import { setProgressLoading } from "store/tryOn/reducer";
// import { ReviewData } from "interface/interface";

const ProductDetails = ({ onClose }: { onClose: () => void }) => {
    const { productSizingDetails, listLoading, error } = useSelector((state: RootState) => state.product);
    const { downloadUrl, uploadedFileType } = useSelector((state: RootState) => state.techpack);
    const {progressLoading} = useSelector((state:RootState)=>state.tryOn)
    const { data: fitmodelData } = useSelector((state: RootState) => state.fitModel);
    const [rowOptions, setRowOptions] = useState<any[]>(deepCopy(MeasurementRowOptions));
    const [rowsArray, setRowsArray] = useState<{ name: string; id: string }[]>([]);
    const [sizingOnBoarded,setSizingOnBoarded] = useState<boolean>(false);
    const [tryonOnBoarded,setTryonOnBoarded] = useState<boolean>(false);
    const [reviewData,setReviewData] = useState<any[]>([]);
    const dispatch = useDispatch(); 
    const [techpackData, setTechpackData] = useState(productSizingDetails?.techpackMeasurements);
    const fitData = useMemo(() => fitmodelData?.find((el) => el.id === productSizingDetails?.fitmodelId), [productSizingDetails, fitmodelData]);
    const handleInitialRowsOptions = useCallback(() => {
        const keys: string[] = productSizingDetails?.techpackMeasurements?.sizes ? Object.keys(productSizingDetails?.techpackMeasurements?.sizes) ?? [] : [];
        if (keys.length) {
            setRowsArray([...modifyMeasurementRow({ key: "techpack", measurementData: productSizingDetails?.techpackMeasurements?.sizes, keys, rowOptions })]);
            setRowOptions([...makeMeasurementRowSelected({ options: "techpack", rowOptions, beRowOptions: keys, beValues: productSizingDetails?.techpackMeasurements?.sizes })]);
        }
    }, [productSizingDetails, rowOptions, rowsArray, techpackData]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleSelectChange = (name: string, index = 0, skip = false) => {
        rowsArray.push({ name, id: generateUuid() });
        setRowsArray([...rowsArray]);
        setRowOptions([...makeMeasurementRowSelected({ options: "selection", rowOptions, key: name })]);
    };

    const handleProductSizingOnBoard=useCallback((value:boolean)=>{
        setSizingOnBoarded(value);
        dispatch({type:HANDLE_PRODUCT_ONBOARDING,payload:[{
            shopifyProductId: productSizingDetails?.product?.shopifyProductId,
            onboarded: value
        }]})
    },[sizingOnBoarded,productSizingDetails])

    const handleProducttryonOnBoard=useCallback((value:boolean)=>{
        setTryonOnBoarded(value);
        dispatch({type:HANDLE_TRY_ON_ONBAORD,payload:{
            selectedProductHandles: [productSizingDetails?.product?.productHandle],
            isOnboarded:value
        }})
    },[tryonOnBoarded,productSizingDetails])

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, size: string | undefined, index: number | undefined,type="sizes") => {
            const { name, value } = e.target;
            if (size && name) {
                if (type==="ease") {
                    size = (techpackData?.base_size) as string
                }
                const newMeasurements = { ...techpackData };
                if (newMeasurements?.[type][size][name]) {
                    newMeasurements[type][size][name] = +value;
                } else {
                    newMeasurements[type][size] = {
                        ...newMeasurements?.[type]?.[size],
                        [name]: +value,
                    };
                }
                setTechpackData(newMeasurements);
                const payload = {
                    data: newMeasurements,
                    type: "details",
                };
                dispatch({ type: HANDLE_DEBOUCE_UPDATE_TECHPACK_PARSE, payload });
            }
        },
        [techpackData],
    );


	const handleFinetune = useCallback(
		(value: boolean, index: number) => {
			reviewData[index].isFinetune = value;
			setReviewData([...reviewData]);
		},
		[reviewData],
	);

	const handleFeedaback = useCallback(
		(value: string, index: number) => {
			reviewData[index].feedback = value;
			setReviewData([...reviewData]);
		},
		[reviewData],
	);

    const handleSubmit = useCallback(async()=>{
        dispatch(setProgressLoading(true))
        try {
           for(const a of reviewData){
            await tryOnService.setTryOnReviewFinetune([a.name],a.feedback)
           }
        } catch (error) {
            dispatch(addToast({type:TOAST_TYPES.ERROR,messsage: handleErrorMessage(error)}))
        }finally{
            dispatch(setProgressLoading(false))
        }
    },[reviewData])

    useEffect(() => {
        setTechpackData(deepCopy(productSizingDetails?.techpackMeasurements));
        handleInitialRowsOptions();
        setSizingOnBoarded(productSizingDetails?.sizingOnboarded);
        setTryonOnBoarded(productSizingDetails?.tryonOnboarded)
        setReviewData(createReviewData(productSizingDetails?.tryonImages,productSizingDetails?.product?.productHandle))
    }, [productSizingDetails]);

    return (
        <div className={`${styles.details}`}>
            <span className={`${styles.details__close}`} role="button" onClick={onClose}>
                <Close />
            </span>
            {listLoading ? (
                <div className="flex align-center justify-center h-full">
                    <LoadingScreen height={false} />
                </div>
            ) : error ? (
                <div className="flex align-center justify-center h-full">
                    <NoDataPlaceHolder message="No data to show" showOnlyMessage />
                </div>
            ) : (
                <div className={`${styles.details__wrapper}`}>
                    <div className={`${styles.details__header}`}>
                        <h2>{productSizingDetails?.product?.productHandle}</h2>
                  
                    </div>

                    <div className={`${styles.details__info}`}>
                        <ul>
                            <li>
                            <h3>
                            Live on your store:
                                    </h3>
                                    <div className={`flex ${styles.details__button}`}>
                                    <FormControlLabel control={<Switch disabled={!productSizingDetails?.techpackId} checked={sizingOnBoarded} onChange={(e)=>handleProductSizingOnBoard(e.target.checked)
                            } />} label="Sizing" />
                            <FormControlLabel control={<Switch disabled={productSizingDetails?.tryonLaunchStatus!=="onboarded" && productSizingDetails?.tryonLaunchStatus!=="approved" } checked={tryonOnBoarded} onChange={(e)=>handleProducttryonOnBoard(e.target.checked)
                            } />} label="Try-On" />
                                        </div>   
                            </li>
                            {productSizingDetails?.product?.link &&
                                <li>
                                    <h3>
                                        <img src={link} alt="link" />
                                        Link
                                    </h3>
                                    <p>
                                        <a href={productSizingDetails?.product?.link} target="_blanck">
                                            {productSizingDetails?.product?.link}
                                        </a>
                                    </p>
                                </li>
                            }
                            {productSizingDetails?.product?.gender && (
                                <li>
                                    <h3>
                                        <img src={user} alt="user" />
                                        Gender
                                    </h3>
                                    <p>{productSizingDetails?.product?.gender}</p>
                                </li>
                            )}
                            {productSizingDetails?.product?.category && productSizingDetails?.product?.category?.length > 0 &&
                                <li>
                                    <h3>
                                        <img src={list} alt="list" />
                                        Category
                                    </h3>
                                    <p>{productSizingDetails?.product?.category}</p>
                                </li>
                            }
                            {productSizingDetails?.product?.style && productSizingDetails?.product?.style?.length > 0 &&
                                <li>
                                    <h3>
                                        <img src={scissors} alt="scissors" />
                                        Styles
                                    </h3>
                                    <p>{productSizingDetails?.product?.style?.join(", ")}</p>
                                </li>
                            }
                            {productSizingDetails?.product?.size && productSizingDetails?.product?.size?.length > 0 &&
                                <li>
                                    <h3>
                                        <img src={layers} alt="layers" /> Size range
                                    </h3>
                                    <p>{productSizingDetails?.product?.size?.join(", ")}</p>
                                </li>
                            }
                            {productSizingDetails?.product?.color && productSizingDetails?.product?.color?.length > 0 &&
                                <li>
                                    <h3>
                                        <img src={colors} alt="colors" />
                                        Colours

                                    </h3>
                                    <p>
                                        <span className="flex align-center gap-10">
                                            {productSizingDetails?.product?.color?.map((el: string) => (
                                                <span key={el} className={`${styles.details__color}`} style={{ backgroundColor: el ?? "" }}></span>
                                            ))}
                                        </span>
                                    </p>
                                </li>
                            }

                        </ul>
                    </div>
                    <div className={`${styles.details__Other}`}>
                        {productSizingDetails?.product?.images &&  productSizingDetails?.product?.images?.length> 0 && <>
                        <h3>
                            <img src={image} alt="image" />
                            Images
                        </h3>
                        <div className={`${styles.details__Images}`}>
                            <ul className="flex-wrap">
                                {productSizingDetails?.product?.images?.map((el: string) => (
                                    <li key={el}>
                                        <figure>
                                            <img src={el ?? ""} alt="image" />
                                        </figure>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        </>}
                       {productSizingDetails?.product?.description && <div className={`${styles.details__ImagesDesc}`}>
                            <h4>With image description</h4>
                            <p>{productSizingDetails?.product?.description}</p>
                        </div>}
                        {reviewData.length > 0 && <div>
                            <div className={`${styles.details__Shadow} ${styles.details__review}`}><ReviewImages data={reviewData} handleFeedaback={handleFeedaback} handleFinetune={handleFinetune} showButton handleSubmit={handleSubmit} loading={progressLoading} disabled={progressLoading} title="Test Try-Ons" /></div>
                        </div>}
                        <div className={`${styles.details__Shadow}`}>
                            <div className={`${styles.details__ImagesDesc}`}>
                                <p>
                                    <strong>Short</strong> -{productSizingDetails?.techpackMeasurements?.name}
                                </p>
                               {productSizingDetails?.techpackMeasurements?.description && <div className={`${styles.details__ImagesDescSpace}`}>
                                    <h4>With product description</h4>
                                    <p>{productSizingDetails?.techpackMeasurements?.description}</p>
                                </div>}
                               { techpackData?.sizes && Object.keys(techpackData?.sizes).length>0 &&  <div className={`${styles.details__ImagesDescSpace}`}>
                                    <h4>With parsed measurements</h4>
                                    <div className={`${styles.details__ImagesTable}`}>
                                        <CustomTableMui
                                            type={MUI_TABLE_TYPE.TECHPACK}
                                            rows={rowOptions}
                                            data={techpackData?.sizes || {}}
                                            columns={getColumnsDataTechpack(techpackData?.sizes || {}, techpackData?.ease)}
                                            handleChange={handleChange}
                                            handleSelectChange={handleSelectChange}
                                            rowArray={rowsArray}
                                            firstColSticky
                                            easeData={techpackData?.ease}
                                            baseSizeFortechpack={techpackData?.base_size||""}
                                        />
                                    </div>
                                </div>}
                               { fitData &&  <div className={`${styles.details__ImagesDescSpace}`}>
                                    <h4>With associated fit model</h4>
                                        <div className={`${styles.details__ImagesTable}`}>
                                            <FitModelTable data={fitData} showDelete={false} />
                                        </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.details__Other}`}>
                        <h3>
                            <img src={file} alt="file" />
                            File
                        </h3>
                        <p>{productSizingDetails?.fileName}</p>

                        <div className={`flex align-center justify-center ${styles.details__ImagesDescSpace}`}>
                            {downloadUrl ? (
                                uploadedFileType === TECHPACK_FILE_TYPE.pdf ? (
                                    <PdfViewer fileUrl={downloadUrl} />
                                ) : (
                                    <XlsxViewer fileUrl={downloadUrl} />
                                )
                            ) : (
                                <NoDataPlaceHolder message="No file to show." showOnlyMessage />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductDetails;
