import { call, delay, put, select, take, takeLatest, all } from "redux-saga/effects";
import {
	setBatchFileUploaded,
	setBatchLoading,
	setDownloadUrl,
	setFileName,
	setIsFeedbackSubmited,
	setLatestTechpackFieldId,
	setLoading,
	setSubmitLoading,
	setTechpackData,
	setTechpackFileBatchId,
	setTechPackParsedData,
	setTechpackParseDone,
	setUploadedFiles,
	setUploadedFileType,
	setUploadLoading,
} from "./reducer";
import {
	HANDLE_BATCH_FILE_UPLOAD,
	HANDLE_DEBOUCE_UPDATE_TECHPACK_PARSE,
	HANDLE_FAILED_TECHPACK_PARSE,
	HANDLE_GET_TECHPACK_LIST,
	HANDLE_GET_UPLOADED_FILE_URL,
	HANDLE_SUBMIT_FEEDBACK,
	HANDLE_SUCCESS_TECHPACK_PARSE,
	HANDLE_TECHPACK_PARSE,
	HANDLE_UPDATE_TECHPACK_PARSE,
	HANDLE_UPLOAD_TECHPACK_FILE,
} from "./action.types";
import techpackService from "services/techpackservice";
import { addToast } from "store/toaster/reducer";
import {  generateUuid, handleErrorMessage } from "lib/utils";
import { TOAST_TYPES } from "enum/enums";
import { SubmitFeedbackPayload, TechpackparsePayload, TechPackUpdatePayload } from "interface/interface";
import WebSocketService from "services/webSocketService";
import { REACT_APP_WEBSOCKET_URL } from "lib/Constants";
import { RootState } from "store/store";
import { eventChannel } from "redux-saga";

function* getTechpackList(): Generator {
	try {
		const res: any = yield call(techpackService.getTechpackList);
		yield put(setTechpackData(res?.data));
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
	}
}

function createWebSocketChannel(websocketService: any) {
	return eventChannel((emitter) => {
		websocketService.handleWebSocket({
			onSuccess: (data: any) => {
				emitter({ type: HANDLE_SUCCESS_TECHPACK_PARSE, payload: data });
			},
			onError: (error: any) => {
				emitter({ type: HANDLE_FAILED_TECHPACK_PARSE, payload: error });
			},
			// onClose: () => {
			//     // emitter({ type: HANDLE_WEBSOCKET_CLOSE });
			//     emitter(END);  // Close the channel when WebSocket closes
			// }
		});

		return () => {
			websocketService.disconnect(); // Clean up WebSocket when the channel is closed
		};
	});
}

function* uploadTechpackFile(action: { type: string; payload: File }): Generator {
	yield put(setUploadLoading(true));
	try {
		yield put(setFileName(action.payload.name));
		const res: any = yield call(techpackService.uploadTechpackFile, action.payload.name);
		const state: any = yield select((state: RootState) => state.auth);
		const url = `${REACT_APP_WEBSOCKET_URL}?storeUrl=${state.userDetails?.store_url}&techpackFileId=${res?.data?.techpackFileId}`;
		const websocketService = new WebSocketService(url);
		const channel: any = yield call(createWebSocketChannel, websocketService);
		yield call(techpackService.uploadFileAWSBucket, action.payload, res?.data?.uploadUrl);
		const resVal: any = yield call(techpackService.getUploadedFileUrl, res?.data?.techpackFileId, action.payload.name);
		yield put(setDownloadUrl(resVal?.data?.downloadUrl));
		yield put(setLatestTechpackFieldId(res?.data?.techpackFileId));
		yield put(setUploadedFileType(res?.data?.fileType));
		yield put(setUploadLoading(false));
		while (true) {
			const event: any = yield take(channel);
			yield put(event);
		}
	} catch (error) {
		yield all([
			put(setLatestTechpackFieldId("")),
			put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR })),
			put(setUploadLoading(false)),
			put(setLoading(false)),
			put(setFileName("")),
		]);
	}
}
function* uploadBatchFile(action: { type: string; payload: { files: File[]; isToParse: boolean } }): Generator {
	const { payload } = action;
	yield put(setBatchLoading(true));
	yield put(setTechpackParseDone(false))
	try {
		const state: any = yield select((state: RootState) => state.techpack);
		for (let index = 0; index < payload.files.length; index++) {
			const res: any = yield call(techpackService.uploadTechpackFile, payload.files[index].name, state.techpackFileBatchId);
			yield call(techpackService.uploadFileAWSBucket, payload.files[index], res?.data?.uploadUrl);
		}
        yield put(setUploadedFiles([...state.uploadedFiles,...payload.files]))
		if (payload.isToParse) {
			yield call(techpackService.techpackParse, state.techpackFileBatchId);
			yield put(setTechpackFileBatchId(generateUuid()));
		} 
		yield put(setBatchFileUploaded(true));
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
		yield put(setBatchFileUploaded(false));
	} finally {
		yield put(setBatchLoading(false));
	}
}
function* getUploadedFileUrl(action: { type: string; payload: { techpackFileId: string; fileName: string } }): Generator {
	// yield put(setLoading(true));
	try {
		const res: any = yield call(techpackService.getUploadedFileUrl, action.payload.techpackFileId, action.payload.fileName);
		yield put(setDownloadUrl(res?.data?.downloadUrl));
		yield put(setUploadedFileType(res?.data?.fileType));
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
	} finally {
		yield put(setLoading(false));
	}
}
function* techpackParse(action: { type: string; payload: TechpackparsePayload | { techpackFileBatchId: string } }): Generator {
	yield put(setLoading(true));
	try {
		yield call(techpackService.techpackParse, action.payload)
		yield all([
			put(setUploadedFiles([])),
			put(setTechpackFileBatchId(generateUuid())),
			put(setBatchFileUploaded(false)),
		])
		if ('techpackFileBatchId' in action.payload) {
			put(setTechpackParseDone(true))
		}
		
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
		yield put(setLoading(false));
	} finally {
		if ('techpackFileBatchId' in action.payload) {
			yield put(setLoading(false));
		}
	}
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* onSuccess(action: { type: string; payload: any[] }): Generator {
	try {
		const state: any = yield select((state: RootState) => state.techpack);
		const res: any = yield call(techpackService.getTechpackParseInfo, state.latestTechpackFieldId);
		yield put(setTechPackParsedData(res?.data));
	} catch (error) {
		yield put({ type: HANDLE_FAILED_TECHPACK_PARSE, payload: error });
	} finally {
		yield put(setLoading(false));
	}
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* onError(action: { type: string; payload: any }) {
	yield all([
		put(addToast({ message: handleErrorMessage("Failed to parse, Try again"), type: TOAST_TYPES.ERROR })),
		put(setDownloadUrl("")),
		put(setLoading(false)),
		put(setUploadedFileType("")),
	]);
}
function* updateTechpackParse(action: { type: string; payload: TechPackUpdatePayload }): Generator {
	// yield put(setLoading(true));
	try {
		if (action.payload.type === "onlyMeasurement") {
			yield call(techpackService.updateTechpackParse, action.payload.data);
		} else {
			yield call(techpackService.updateTechpack, action.payload.data);
		}
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
		// yield put(setLoading(false));
	} finally {
		// yield put(setUploadLoading(false));
	}
}

function* debouceUpdateTechpackParse(action: { type: string; payload: TechPackUpdatePayload }): Generator {
	yield delay(2000);
	yield call(updateTechpackParse, { type: HANDLE_UPDATE_TECHPACK_PARSE, payload: action.payload });
}

function* submitFeedback(action: { type: string; payload: SubmitFeedbackPayload }): Generator {
	try {
		yield put(setSubmitLoading(true));
		const result: any = yield call(techpackService.submitFeedbackTechpack, action.payload);
		yield put(addToast({ message: result.data.message, type: TOAST_TYPES.SUCCESS }));
		yield put(setIsFeedbackSubmited(true));
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
	} finally {
		yield put(setSubmitLoading(false));
	}
}

function* techpackSaga() {
	yield takeLatest(HANDLE_GET_TECHPACK_LIST, getTechpackList),
		yield takeLatest(HANDLE_UPLOAD_TECHPACK_FILE, uploadTechpackFile),
		yield takeLatest(HANDLE_GET_UPLOADED_FILE_URL, getUploadedFileUrl),
		yield takeLatest(HANDLE_TECHPACK_PARSE, techpackParse),
		yield takeLatest(HANDLE_UPDATE_TECHPACK_PARSE, updateTechpackParse),
		yield takeLatest(HANDLE_FAILED_TECHPACK_PARSE, onError),
		yield takeLatest(HANDLE_SUCCESS_TECHPACK_PARSE, onSuccess),
		yield takeLatest(HANDLE_DEBOUCE_UPDATE_TECHPACK_PARSE, debouceUpdateTechpackParse),
		yield takeLatest(HANDLE_SUBMIT_FEEDBACK, submitFeedback),
		yield takeLatest(HANDLE_BATCH_FILE_UPLOAD, uploadBatchFile);
}

export default techpackSaga;
