export enum TOAST_TYPES {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WARNING = "WARNING",
}

export enum TOKEN_DATA_TYPE {
  ACCESS = "ACCESS",
  INTERNAL = "INTERNAL",
}

export enum AUTH_TYPE {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
}
export enum ANALYTICS_CONTAINER_TITLE{
  CORE_METRICS = "Core metrics",
  SIZING ="Sizing",
  TRY_ON="Try-On"
}
export enum DATE_PICKER_VALUE {
  // TODAY = "dStart",
  // TWENTY_FOUR_HOURS = "-24hr",
  SEVEN_DAYS = "-7d",
  FOURTEEN_DAYS = "-14d",
  THIRTY_DAYS = "-30d",
  THIS_MONTH = "mStart",
  CUSTOM = "custom",
  THIS_QUATER = "-120d"
}

export enum FETCH_LIST_TYPE {
  MEASUREMENT_SUCCESS_FIT_VIEW = "/measurement_success/fit-view",
  MEASUREMENT_SUCCESS_SHOPIFY = "/measurement_success/shopify",
  MEASUREMENT_FAILED_FIT_VIEW = "/measurement_failed/fit-view",
  PRODUCT_REQUEST = "/product_request",
  SCAN_SUCCESS = "/scan_success",
  USER_LIST = "",
  SIZING_SUGGESTIONS = "Order Processed",
  ORDERS = "/orders",
  SWAN_POWERED_SALES = "Scan Outcome Requested",
  SCAN_STARTED = "/scan_started",
  RESCAN = "/rescan",
  TOKEN_VERIFY = "/token_verify",
  PHONE_DETECTION = "/phone_detection",
  TRY_ON="/try-on",
  TRY_ON_SHARE="/try-on_share",
  FIT_VIEW_RESULTED_GENERATED="Fit View Result Generated",
  SCAN_COMPLETION_TIME="/scan_completion_time",
  TRY_ON_COMPLETION_TIME="/try-on_completion_time",
  CTA_CLICK="/cta_click",
  CTA_METRICS="cta_metrics",
  GROUP_TWO_METRICS="group2_metrics",
  GROUP_THREE_METRICS="group3_metrics",
  GROUP_FOUR_METRICS="group4_metrics",
  SALES_METRICS="sales_details",

}

export enum Metrics {
  SCAN_COMPLETE_RATE = "Scan completion rate",
  SCAN_ACCURACY = "Scan Accuracy",
  CAMERA_ACTIVATION_RATE = "Camera Activation Rate",
  COMPLETION_AND_CONFIRMATION_RATE = "Completion And Confirmation Rate",
  TOTAL_SCANS = "Total Scans",
  SIZING_SUGGESTIONS = "Sizing Suggestions",
  SWAN_POWERED_SALES = "Swan Powered Sales",
  EMAIL_VERIFICATION_RATE = "Email Verification Rate",
  CONVERSION_RATE = "Conversion Rate",
  AOV = "AOV (Average Order Value)",
  FAILED_SCAN_PERCENTAGE = "Failed Scan Percentage",
  AUTOMATIC_PHONE_DETECTION = "Automatic phone detection",
  SCANS = "Scans",
  SUCCESS_PERCENTAGE="Success Percentage",
  SCAN_INITIATION_RATE = "Scan Initiation Rate",
  SWAN_INFLUENCE_TO_SALES="Swan Use to Sales",
  SHARING_RATE_OF_TRY_ON="Sharing Rate of Try-On",
  TRY_ON_INTERACTION_TIME="Try-On Interaction Time",
  AVERAGE_TIME_SPENT_PER_SCAN="Average Time Spent Per Scan",
  FAILED_SCAN="Failed Scan",
  SUCCESS_SCAN="Scan Success",
  TRY_ONS="Try-Ons",
  SCAN_JOURNEY_COMPLETION_RATE="Scan Journey Completion Rate",
  TRY_ON_COMPLETION_RATE="Try-Ons Completion Rate",
  AVERAGE_TIME_SPENT_PER_TRY_ON="Average Time Spent Per Try-On",
  SIZING_SUGGESTIONS_PER_USER="Sizing Suggestions Per User",
  SCAN_SUCCESS_COMPLETION_RATE="Scan Success Completion Rate",
  TRY_ON_SUCCESS="Try-Ons Success",
  TRY_ON_JOURNEY_COMPLETION_RATE="Try-Ons Journey Completion Rate",
  TRY_ON_SUCCESS_COMPLETION_RATE="Try-Ons Success Completion Rate"
}

export enum METRICS_KEY{
  CTA_CLICKS="cta_clicks",
  MEASUREMENT_SUCCESS="measurement_success",
  PHONE_DETECTIONS_AUTO="phone_detections_auto",
  PHONE_DETECTIONS_MANUAL="phone_detections_manual",
  FITVIEW_SIZING="fitview_sizing",
  SCAN_STARTED="scan_started",
  DISTINCT_PRODUCT_REQUEST="distinct_count_product_request",
  TRY_ON_SUCCESS="try_on_success",
  TRY_ON_FAILED="try_on_failed",
  TRY_ON_SHARE="try_on_share",
  SCAN_COMPLETION_TIME="scan_completion_time",
  TRY_ON_COMPLETION_TIME="tryon_completion_time",
  MEASUREMENT_FAILED="measurement_failed",
  TRY_ON_STARTED="try_on_started",
 CAMERA_ACTIVATION_FAILED="camera_activation_failed"
}

export enum COMBINE_EVENTS_EQUATION{
  ADD="ADD",
  DIVIDE="DIVIDE"
}

export enum SIGN_UP_TAB_VALUE{
  ONE=1,
  TWO=2,
  THREE=3,
  FOUR=4
}

export enum MEASUREMENT_CONVERSION_TYPE{
  CM="cm",
  INCH="inch"
}
export enum IMAGE_SHOW_TYPE{
  SQUARE="SQUARE",
  RECTANGLE="RECTANGLE"
}

export enum TRY_ON_FILE_UPLOAD_SHOW_TYPE{
  NORMAL="NORMAL",
  MODAL="MODAL"
}
