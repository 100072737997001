import React from 'react';
import styles from "./ReviewImages.module.scss";
import { ReviewData } from 'interface/interface';
import { Switch } from '@mui/material';
import Info from "assets/images/info-icon.svg";
import CustomButton from 'atoms/customButton/CustomButton';

interface ReviewImagesProps{
    data:ReviewData[];
    handleFinetune:(a:boolean,index:number)=>void;
    handleFeedaback:(a:string,index:number)=>void;
    showButton?:boolean;
    handleSubmit?:()=>void;
    disabled?:boolean;
    loading?:boolean;
    title?:string
}

const ReviewImages = ({data,handleFinetune,handleFeedaback,showButton=false,handleSubmit,disabled,loading,title=""}:ReviewImagesProps) => {
  return (
    <div className={`${styles.tryOn}`}>
    {data?.map((el: ReviewData, index: number) => (
        <React.Fragment key={el?.name}>
            <div className={`${styles.tryOn__loopBoxHeader} flex-wrap align-center justify-between`}>
                {title?<p>{title}</p> :<h2>{el?.name}</h2>}
                <span className={`${styles.tryOn__switch} flex align-center`}>
                    Fine tune <Switch checked={el?.isFinetune} onChange={(e) => handleFinetune(e.target.checked, index)} />
                    <span className={`${styles.tryOn__toolTip}`}>
                        <img src={Info} alt="info" />
                        <span className={`${styles.tryOn__toolTipInfo} toolTipInfo`}>This will temporarily pause Try-On for this product & fine tune it for better results.</span>
                    </span>
                </span>
            </div>
            <div className={`${styles.tryOn__imagesBox}`}>
                <ul className="flex-wrap">
                    {el?.imageUrls?.map((imageUrl: string) => (
                    <li key={imageUrl}>
                        <figure className={`${el.status==="in-progress" ? styles.tryOn__imagesBlur : '' }`}>
                            <img src={imageUrl} alt="images" />
                        </figure>
                    </li>
                ))}
                </ul>
            </div>
            
            {el?.isFinetune && (
                <div className={`${styles.tryOn__textarea}`}>
                    <textarea name={el?.name} placeholder="Add details on why this product should be fine-tuned" onChange={(e) => handleFeedaback(e.target.value, index)}></textarea>
                </div>
            )}
            {showButton && el?.isFinetune && <div className={`${styles.tryOn__btn} flex justify-center`}><CustomButton buttonText="submit" handleFunc={handleSubmit && handleSubmit} disabled={disabled} loading={loading} /></div>}
        </React.Fragment>
    ))}
   </div>
  )
}

export default ReviewImages