
class WebSocketService {
    #socketRef: WebSocket
    constructor(url:string){
        this.#socketRef =  new WebSocket(url)
    }
    

    disconnect(){
        this.#socketRef?.close();
    }

    handleWebSocket = ({onSuccess,onError,onClose}:any): void => {
        this.#socketRef.onopen = async () => {
            console.log("websocket connection open ");
            
         };
        this.#socketRef.onmessage = (event) => {
        const data = JSON.parse(event.data);
         onSuccess?.(data)
         };
         this.#socketRef.onclose = () => {
           onClose?.();
         };
         this.#socketRef.onerror = (event) => {
            console.log(event,"error while wesocket connection");
           onError?.(event)
         };

    }
    
}

export default WebSocketService