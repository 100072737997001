import { ROUTES } from "lib/Constants";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./TryOnReviewImages.module.scss";
import BackButton from "component/backButton/BackButton";
import { useDispatch } from "react-redux";
import { HANDLE_GET_TRY_ON_APPOVE_AND_FINE_TUNE, HANDLE_GET_TRY_ON_REVIEW_IMAGES } from "store/tryOn/action.types";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import CustomButton from "atoms/customButton/CustomButton";
// import { Switch } from "@mui/material";
// import Info from "../../assets/images/info-icon.svg";
import LoadingScreen from "component/LoadingScreen";
import NoDataPlaceHolder from "component/NoDataPlaceHolder";
import { ReviewData } from "interface/interface";
import { useNavigate } from "react-router-dom";
import { setIsApproved, setIsFineTuned } from "store/tryOn/reducer";
import ReviewImages from "component/reviewImages/ReviewImages";



const TryOnReviewImages = () => {
	const { reviewImages, detailsLoading, detailsError,progressLoading,isApproved, isFineTuned } = useSelector((state: RootState) => state.tryOn);
	const [reviewData, setReviewData] = useState<ReviewData[]>([]);
	const dispatch = useDispatch();
    const navigate = useNavigate()

	const handleFinetune = useCallback(
		(value: boolean, index: number) => {
			reviewData[index].isFinetune = value;
			setReviewData([...reviewData]);
		},
		[reviewData],
	);

	const handleFeedaback = useCallback(
		(value: string, index: number) => {
			reviewData[index].feedback = value;
			setReviewData([...reviewData]);
		},
		[reviewData],
	);

	const handleApprove = useCallback( () => {
		dispatch({type:HANDLE_GET_TRY_ON_APPOVE_AND_FINE_TUNE,payload:reviewData})
	}, [reviewData]);

	const handleReviewData = useCallback(() => {
		const data = Object.entries(reviewImages)?.reduce((acc: ReviewData[], [key, value]: any) => {
			const urls = value?.reduce((ac: string[], el: any) => {
				if (el?.status == "available") {
					const url = el?.tryonResultImages?.[el?.userImages?.[0]];
					if (url) {
						ac.push(url);
					}
				}
				return ac;
			}, []);
			if (urls.length) {
				const obj = { name: key, isFinetune: false, feedback: "", imageUrls: urls };
				acc.push(obj);
			}
			return acc;
		}, []);
		setReviewData(data || []);
	}, [reviewImages]);

	useEffect(() => {
		handleReviewData();
	}, [reviewImages]);

	useEffect(()=>{		
     if (isApproved && isFineTuned) {
		dispatch(setIsApproved(false))
		dispatch(setIsFineTuned(false))
		navigate(ROUTES.TRY_ON_ONBOARDING)
	}
	},[isApproved,isFineTuned])

	useEffect(() => {
		dispatch({ type: HANDLE_GET_TRY_ON_REVIEW_IMAGES });
	}, []);

	return (
		<div className={`${styles.tryOn} flex-column`}>
			<div className={styles.tryOn__back}>
				<BackButton route={ROUTES.TRY_ON_ONBOARDING} />
			</div>
			<div className={`${styles.tryOn__scroll}`}>
				<div className={`${styles.tryOn__main} flex-column`}>
					<div className={`${styles.tryOn__images}`}>
						{detailsLoading ? (
							<div className="flex align-center justify-center h-full">
								<LoadingScreen height={false} />
							</div>
						) : (
							<div className={`${styles.tryOn__loopBox}`}>
								{detailsError || reviewData?.length===0 ? (
									<div className="flex align-center justify-center h-full">
										<NoDataPlaceHolder message="No data to show" showOnlyMessage />{" "}
									</div>
								) : (
									<ReviewImages data={reviewData} handleFeedaback={handleFeedaback} handleFinetune={handleFinetune}/>
								)}
							</div>
						)}
					</div>
				</div>
			</div>

			{!detailsLoading && !detailsError && reviewData?.length > 0 &&  (
				<div className={`${styles.tryOn__button} flex justify-end`}>
					<CustomButton buttonText="Approve" disabled={progressLoading} loading={progressLoading} className="rounded sm" handleFunc={handleApprove} />
				</div>
			)}
		</div>
	);
};

export default TryOnReviewImages;
