import { TODAY_DATE } from "lib/Constants";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface DateRangePickerProps {
  setStartDate: (a: Date) => void;
  startDate: Date;
  setEndDate: (a: Date) => void;
  endDate: Date;
  setError: (a: boolean) => void;
}

const DateRangePicker = ({ setStartDate, startDate, setEndDate, endDate, setError }: DateRangePickerProps) => {
  return (
    <>
      <DatePicker
        dateFormat="yyyy/MM/dd"
        selectsRange
        startDate={startDate}
        endDate={endDate}
        maxDate={TODAY_DATE}
        onChange={(update: any) => {
          setError(false);
          setStartDate(update[0]);
          setEndDate(update[1]);
        }}
      />
    </>
  );
};

export default DateRangePicker;
