/* eslint-disable @typescript-eslint/no-unused-vars */
import CustomResizeDrawer from "component/customResizeDrawer/CustomResizeDrawer";
import React, { useEffect, useRef, useState } from "react";
import styles from "./SizingOnboarding.module.scss";
import file from "assets/images/paperclip.svg";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useDispatch } from "react-redux";
import { HANDLE_UPLOAD_TECHPACK_FILE } from "store/techpack/action.types";
import PdfViewer from "component/pdfViewer/PdfViewer";
import XlsxViewer from "component/xlsxViewer/XlsxViewer";
import { ROUTES, TECHPACK_FILE_TYPE } from "lib/Constants";
import Upload from "atoms/upload/Upload";
import LinearBuffer from "component/analyticTryOnCards/ProgressLoading";
import SizingOnboardingLeftFirstChild from "component/sizingOnboardingChild/SizingOnboardingLeftFirstChild";
import SizingOnboardingSecondLeftChild from "component/sizingOnboardingChild/SizingOnboardingSecondLeftChild";
import { setDownloadUrl } from "store/techpack/reducer";
import BackButton from "component/backButton/BackButton";

const SizingOnboarding = () => {
	const { fileName, uploadingLoading, downloadUrl, uploadedFileType, techPackParsedData } = useSelector((state: RootState) => state.techpack);
	const dispatch = useDispatch();
	const [totalContent, setTotalContent] = useState<any>(0);
	const ref = useRef<any>();

	const handleTotalContent = (val: any) => {
		setTotalContent(val);
	};

	const handleFileOnChange = (files: File[]) => {
		const file = files[0];
		if (file.type === TECHPACK_FILE_TYPE.PDF || file.type === TECHPACK_FILE_TYPE.XLSX) {		
			dispatch({ type: HANDLE_UPLOAD_TECHPACK_FILE, payload: file });
		} else {
			ref.current.clearFileInput();
		}
	};
    useEffect(()=>{
    dispatch(setDownloadUrl(""))
	},[])
	return (
		<div className={`${styles.sizeOnboard}`}>
		
			<>
				<div className={`${styles.sizeOnboard__left} flex-column `}>
                <div className={styles.sizeOnboard__back}>
            <BackButton route={ROUTES.SIZING_START}/>
            </div>
					{techPackParsedData?.length > 0 ? <SizingOnboardingSecondLeftChild /> : <SizingOnboardingLeftFirstChild totalContent={totalContent} />}
				</div>
				<CustomResizeDrawer>
					<div className={`${styles.sizeOnboard__header} `}>
						<h2>
							<img src={file} alt="file" /> Files
						</h2>
						<p>{fileName ?? ""}</p>
					</div>
					<div className={`${styles.sizeOnboard__upload} flex align-center justify-center `}>
						{uploadingLoading && (
							<div className="progress-bar">
								<p>{fileName ?? ""}</p> <LinearBuffer value={0} />
							</div>
						)}
						{!uploadingLoading && <> 
						{downloadUrl ? (
							uploadedFileType === TECHPACK_FILE_TYPE.pdf ? (
								<PdfViewer fileUrl={downloadUrl} handleTotalContent={handleTotalContent} />
							) : (
								<XlsxViewer fileUrl={downloadUrl} handleTotalContent={handleTotalContent} />
							)
						) : (
							<Upload accept=".pdf, .xls, .xlsx" handleFileOnChange={handleFileOnChange} subHeading="PDF or .xlsx" ref={ref}/>
						)}</>
						}
					</div>
				</CustomResizeDrawer>
			</>
		</div>
	);
};

export default SizingOnboarding;
