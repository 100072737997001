import LoadingScreen from "component/LoadingScreen";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { HANDLE_GET_MEASUREMENT_GROUP_LIST } from "store/measurement/action.types";
import { RootState } from "store/store";
import styles from "./MeasurementGroups.module.scss";
import noImage from "assets/images/measurements-2.jpg";
import ArrowRight from "assets/images/arrow-blue-right.svg";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "lib/Constants";
import { setIndividualMeasurementGroup } from "store/measurement/reducer";
import BackButton from "component/backButton/BackButton";
import Capsule from "atoms/capsule/Capsule";

const MeasurementGroups = () => {
	const { measurementGroupList, measurementGroupListLoading } = useSelector((state: RootState) => state.measurement);
	const navigate = useNavigate();
    const dispatch = useDispatch();

	const handleNavigate = (id: string) => { 
		navigate(`${ROUTES.MEASUREMENT_DEFINITION}/${id}`);
	};

	useEffect(() => {
		dispatch({ type: HANDLE_GET_MEASUREMENT_GROUP_LIST });
		dispatch(setIndividualMeasurementGroup([]))
	}, []);

	return measurementGroupListLoading ? (
		<div className={`flex align-center justify-center h-full`}>
			{" "}
			<LoadingScreen height={false} />
		</div>
	) : (
		<div className={`${styles.measurement}`}>
            <div className={`${styles.measurement__back}`}>
            <BackButton route={ROUTES.SIZING_START} />
            </div>
			<div className={`${styles.measurement__inner} flex-wrap`}>
				{measurementGroupList?.map((el) => {
					return (
						<React.Fragment key={el.displayGroupOrder}>
							<div className={`${styles.measurement__box}`} key={el.displayGroupOrder}>
								{el?.measurements.length>0 ? 
                                <div className={`${styles.measurement__capsule} flex-column align-start`}>{
                                el?.measurements?.map((measurement:Record<string,any>,index:number)=>
										<Capsule bgColor="#f2f4f7" name={measurement?.measurementName ?? ""}  key={index}/>
									)}</div> :<figure>
									<img src={el.displayGroupImageUrl ? el.displayGroupImageUrl : noImage} alt="image" />
								</figure> }
								<div className={`${styles.measurement__heading} flex justify-between align-center`}>
									<h2>{el.displayGroup}</h2>
									<span className={`${styles.measurement__link}`} onClick={() => handleNavigate(el?.displayGroupOrder)} role="button">
										{" "}
										<span>
											{el?.measurements.length>0 ? "Open" :"Start"} <img src={ArrowRight} alt="icon" />
										</span>
									</span>
								</div>
							</div>
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
};

export default MeasurementGroups;
