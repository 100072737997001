import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import customerSaga from './customer/saga';
import fitModelSaga from './fitModel/saga';
import techpackSaga from './techpack/saga';
import productSaga from './products/saga';
import groupSaga from './group/saga';
import measurementSaga from './measurement/saga';
import tryOnSaga from './tryOn/saga';
import customSaga from './custom/saga';

export default function* rootSaga() {
  yield all([
    authSaga(), // Add your authSaga
    customerSaga(),
    fitModelSaga(),
    techpackSaga(),
    productSaga(),
    groupSaga(),
    measurementSaga(),
    tryOnSaga(),
    customSaga()
  ]);
}