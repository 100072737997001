import CustomButton from "atoms/customButton/CustomButton";
import React, { useCallback, useMemo, useState } from "react";
import tryOnService from "services/tryOnService";

interface CheckEligibiltyButtonProps {
	productName: string;
}

const CheckEligibiltyButton = ({ productName }: CheckEligibiltyButtonProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [success, setSucess] = useState<boolean>(false);

	const handleTryonCheckEligibilty = useCallback(async () => {
		setLoading(true);
		setError(false);
		setSucess(false);
		try {
			await tryOnService.setTryOnOnBoardEligibilty([productName]);
			setSucess(true);
		} catch (error) {
			setError(true);
		} finally {
			setLoading(false);
		}
	}, [productName, loading, error]);

	const handelButtonText = useMemo(() => {
		if (loading) {
			return <span className="eligible-text">Checking Eligibility</span>;
		} else if (error) {
			return <span className="eligible-text">Failed to Eligible</span>;
		} else {
			return <span className="eligible-text">Sent for Eligibility</span>;
		}
	}, [success, loading, error, productName]);

	return (
		<>
			{loading && handelButtonText}
			{!loading && error && handelButtonText}
			{!loading && !error && success && handelButtonText}
			{!loading && !error && !success && <CustomButton className="xs rounded" loading={loading} disabled={loading} buttonText="Check Eligible" handleFunc={handleTryonCheckEligibilty} />}
		</>
	);
};

export default React.memo(CheckEligibiltyButton);
