import styles from "./AnalyticsCard.module.scss";
import upArrow from "assets/images/arrow-up.svg";
import downArrow from "assets/images/arrow-down-red.svg";
import infoIcon from "assets/images/info-icon.svg";
import React from "react";
import { DATE_PICKER_VALUE, Metrics } from "enum/enums";
import NoDataPlaceHolder from "component/NoDataPlaceHolder";
import AreaApexChart from "component/AreaApexChart";
import { Tooltip } from "@mui/material";

interface AnalyticsCardProps {
  title: string;
  value?: number | string;
  showGain?: boolean;
  showLoss?: boolean;
  showGraph?: boolean;
  InfoIcon?:boolean;
  data?: any[];
  dataTwo?:any[]
  datePickerValue?: DATE_PICKER_VALUE;
  type?:Metrics;
  showErrorMessage?:boolean
  InfoIconTitle?:string;
  locked?:boolean;
  customUi?:boolean;
 
}

const AnalyticsCard = ({ title, InfoIcon,InfoIconTitle, value,locked, customUi=false, showGain = false, showLoss = false, showGraph = false, data = [], datePickerValue,type,showErrorMessage,dataTwo}: AnalyticsCardProps) => {
  return (
    <div className={` ${styles.analyticsCard} ${customUi ? styles.analyticsCardCustom : ``}`}>
      <div>
        <h4>
          {title}
          {InfoIcon && 
          <Tooltip title={InfoIconTitle} enterTouchDelay={0} leaveTouchDelay={3000}>
        <span role='cursor' className={` ${styles.infoIcon}`} ><img src={infoIcon} alt="info" /></span>
        </Tooltip>
         
          }
          {showGain && (
            <span className={` ${styles.percentValue}`}>
              <img src={upArrow} alt="menu" />
              15%
            </span>
          )}
          {showLoss && (
            <span className={` ${styles.percentValue} ${styles.redColor}`}>
              <img src={downArrow} alt="menu" />
              10%
            </span>
          )}
        </h4>
       {value !==undefined &&  <h3>{value}</h3>}
       { showErrorMessage && value===0 &&  <NoDataPlaceHolder message="No data to show in selected time period" showOnlyMessage />  }
      </div>
      {showGraph ? <AreaApexChart locked ={locked}  dataOne={data ?? []} dataTwo={dataTwo} height={200} datePickerValue={datePickerValue} showYaxis={false} type={type} />  : <></>}
    </div>
  );
};

export default AnalyticsCard;
