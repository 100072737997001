import { API_END_POINTS } from "lib/Constants";
import UseApiService from "./apiService";
import { TOKEN_DATA_TYPE } from "enum/enums";


class TryOnService{
    getTryOnList(): Promise<any> {
		const body = {
			url: API_END_POINTS.TRY_ON.getList,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
		};
		return UseApiService().get(body);
	}	
	setStatusApprove(selectedProductHandles:string[]): Promise<any> {
		const body = {
			url: API_END_POINTS.TRY_ON.toApprove,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
			data:{selectedProductHandles}
		};
		return UseApiService().post(body);
	}

	getSampleImages(gender:string): Promise<any> {
		const body = {
			url: API_END_POINTS.TRY_ON.getSampleImages,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
			data:{gender}
		};
		return UseApiService().get(body);
	}
	getTryOnTrials(selectedUserImages:string[],selectedProductHandles:string[]): Promise<any> {
		const body = {
			url: API_END_POINTS.TRY_ON.tryOnTrials,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
			params:{mode:"user"},
			data:{selectedUserImages,selectedProductHandles}
		};
		return UseApiService().post(body);
	}
	getTryOnProductDetails(productName:string): Promise<any> {
		const body = {
			url: `${API_END_POINTS.TRY_ON.tryonProductDetails}/${productName}`,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
		};
		return UseApiService().get(body);
	}
	getTryOnReviewImages(): Promise<any> {
		const body = {
			url: API_END_POINTS.TRY_ON.reviewImages,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
		};
		return UseApiService().get(body);
	}
	setTryOnReviewFinetune(selectedProductHandles:string[],feedback:string): Promise<any> {
		const body = {
			url: API_END_POINTS.TRY_ON.tryonFinetune,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
			data:{
				selectedProductHandles,
				feedback :feedback||"Something's odd!"
			}
		};
		return UseApiService().post(body);
	}
	updateTryOnOnBoard(selectedProductHandles:string[],isOnboarded:boolean): Promise<any> {
		const body = {
			url: `${API_END_POINTS.TRY_ON.tryonOnboard}?${isOnboarded?"on":"off"}`,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
			data:{
				selectedProductHandles,
			}
		};
		return UseApiService().post(body);
	}
	setTryOnOnBoardEligibilty(selectedProducts:string[]): Promise<any> {
		const body = {
			url: API_END_POINTS.TRY_ON.tryonOnboardCheck,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
			data:{
				selectedProducts,
			}
		};
		return UseApiService().post(body);
	}
	tryonUploadFile(fileName: string,tryonProductHandle:string) {
		const body = {
			url: API_END_POINTS.TRY_ON.uploadTryonFile,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
			data: {
				uploadType: "tryonEligibility",
				file: fileName,
				tryonProductHandle
			},
		};
		return UseApiService().get(body);
	}
	tryonDeleteUploadedFile(fileName: string,tryonProductHandle:string) {
		const body = {
			url: API_END_POINTS.TRY_ON.tryonDeleteFile,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
			data: {
				uploadType: "tryonEligibility",
				file: fileName,
				tryonProductHandle
			},
		};
		return UseApiService().delete(body);
	}
	tryonSubmitUploadedFile(tryonProductHandle:string) {
		const body = {
			url: `${API_END_POINTS.TRY_ON.tryonOnboardCheck}/${tryonProductHandle}`,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
		};
		return UseApiService().post(body);
	}
}

  const tryOnService = new TryOnService();
  export default tryOnService