import { createSlice } from "@reduxjs/toolkit";

interface InitialStateInterface{
    customFitData:Record<string, any>;
    selectedValue:Record<string, any>;
    loading:boolean,
    exportButtonDisabled:boolean;
    measurementsSubmitLoading:boolean;
    notesSubmitLoading:boolean;
    noteEditable:boolean;
    measurementEditable:boolean
}
const initialState:InitialStateInterface={
    customFitData:{},
    selectedValue:{},
    loading:false,
    exportButtonDisabled:false,
    measurementsSubmitLoading:false,
    notesSubmitLoading:false,
    noteEditable:false,
    measurementEditable:false
}

const customSlice = createSlice({
    name:"customReducer",
    initialState,
    reducers:{
        setCustomFitData:(state,{payload})=>{
          state.customFitData= payload
        },
        setSelectedValue:(state,{payload})=>{
            state.selectedValue= payload
          },
          setLoading:(state,{payload})=>{
            state.loading= payload
          },
          setExportButtonDisabled:(state,{payload})=>{
            state.exportButtonDisabled= payload
          },
          setMeasurementsSubmitLoading:(state,{payload})=>{
            state.measurementsSubmitLoading= payload
          },
          setNotesSubmitLoading:(state,{payload})=>{
            state.notesSubmitLoading= payload
          },
          setNoteEditable:(state,{payload})=>{
            state.noteEditable= payload
          },
          setMeasurementEditable:(state,{payload})=>{
            state.measurementEditable= payload
          }
    }
})

export const {setCustomFitData,setSelectedValue,setLoading,setExportButtonDisabled,setMeasurementsSubmitLoading,setNotesSubmitLoading,setNoteEditable,setMeasurementEditable} = customSlice.actions;
export default customSlice.reducer