import { ROUTES } from "lib/Constants";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "store/store";
interface CustomCheckWrapperProps{
    children:React.ReactNode,
    fallBackRoute?:string,
    allowCustomRoutes:boolean
}
const CustomCheckWrapper = ({children,fallBackRoute =ROUTES.HOME,allowCustomRoutes=false}:CustomCheckWrapperProps) => {
    const { hasCustomAddon } = useSelector((state: RootState) => state.auth);
    
    if (allowCustomRoutes && hasCustomAddon) {
        return <>{children}</>;
    }
    else if (!allowCustomRoutes && !hasCustomAddon) {
        return <>{children}</>;
    }
    else{
       return <Navigate to={fallBackRoute} replace/>
    }

};

export default CustomCheckWrapper;
