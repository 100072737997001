
const PackageIcon = () => {
    return (
        <>
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
<path d="M15.375 5.45972L8.99998 9.00138M8.99998 9.00138L2.62498 5.45972M8.99998 9.00138L9 16.1264M15.75 12.0453V5.95749C15.75 5.70051 15.75 5.57202 15.7121 5.45742C15.6786 5.35604 15.6239 5.26297 15.5515 5.18446C15.4697 5.0957 15.3574 5.0333 15.1328 4.9085L9.58277 1.82517C9.37007 1.707 9.26372 1.64792 9.15109 1.62475C9.05141 1.60425 8.9486 1.60425 8.84891 1.62475C8.73628 1.64792 8.62993 1.707 8.41723 1.82517L2.86723 4.9085C2.64259 5.0333 2.53026 5.09571 2.44847 5.18446C2.37612 5.26298 2.32136 5.35604 2.28786 5.45742C2.25 5.57202 2.25 5.70051 2.25 5.95749V12.0453C2.25 12.3023 2.25 12.4308 2.28786 12.5454C2.32136 12.6468 2.37612 12.7398 2.44847 12.8184C2.53026 12.9071 2.64259 12.9695 2.86723 13.0943L8.41723 16.1776C8.62993 16.2958 8.73628 16.3549 8.84891 16.3781C8.9486 16.3986 9.05141 16.3986 9.15109 16.3781C9.26372 16.3549 9.37007 16.2958 9.58277 16.1776L15.1328 13.0943C15.3574 12.9695 15.4697 12.9071 15.5515 12.8184C15.6239 12.7398 15.6786 12.6468 15.7121 12.5454C15.75 12.4308 15.75 12.3023 15.75 12.0453Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.375 7.125L5.625 3.375" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        </>
    );
};

export default PackageIcon;
