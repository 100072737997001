import { createSlice } from "@reduxjs/toolkit";

interface InitialStateInterface{
    listLoading:boolean;
    customerList:Record<string, string>[];
    customerSearchList:Record<string, string>[]
}
const InitialState:InitialStateInterface={
    listLoading:true,
    customerList:[],
    customerSearchList:[]

}
const customerSlice= createSlice({
    name:"customer",
    initialState:InitialState,
    reducers:{
        setCustomerList:(state,{payload})=>{
         state.customerList= payload
        },
        setListLoading:(state,{payload})=>{
            state.listLoading= payload
        },
        setCustomerSearchList:(state,{payload})=>{
            state.customerSearchList= payload
           },
    }
})

export const {setCustomerList,setListLoading,setCustomerSearchList} = customerSlice.actions;
export default customerSlice.reducer