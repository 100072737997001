import { TOAST_TYPES } from "enum/enums";
import { fileToBase64, handleErrorMessage } from "lib/utils";
import { apply, call, put, select, takeLatest,all } from "redux-saga/effects";
import { addToast } from "store/toaster/reducer";
import { HANDLE_DELETE_TRY_ON_UPLOADED_FILE, HANDLE_GET_SAMPLE_IMAGES, HANDLE_GET_TRY_ON_APPOVE_AND_FINE_TUNE, HANDLE_GET_TRY_ON_FINE_TUNE, HANDLE_GET_TRY_ON_IMAGES_APPROVE, HANDLE_GET_TRY_ON_PRODUCT_DETAILS, HANDLE_GET_TRY_ON_REVIEW_IMAGES, HANDLE_GET_TRY_ON_TRIALS, HANDLE_GET_TRYON_LIST, HANDLE_TRY_ON_FILE_UPLOAD, HANDLE_TRY_ON_ONBAORD, HANDLE_TRY_ON_ONBAORD_ELIGIBILITY, HANDLE_TRY_ON_SUBMIT, HANDLE_TRY_ON_UPLOADED_FILE } from "./action.types";
import tryOnService from "services/tryOnService";
import { setApproveLoading, setCheckEligibilityLoading, setData, setDetailsData, setDetailsError, setDetailsLoading, setError, setFileUplading, setIsApproved, setIsFineTuned, setLoading, setProgressLoading, setReviewImages, setSampleImages, setSelectedData, setShowModal, setSubmitLoading, setTryonTrialRequestSent, setTryOnUploadImage } from "./reducer";
import swan from "services/swanService";
import { v4 as uuidv4 } from "uuid"; 
import { ReviewData, TryOnImageUploadInterface } from "interface/interface";
import { RootState } from "store/store";
import techpackService from "services/techpackservice";



function* fetchTryOnData():Generator{
  yield put(setLoading(true))
  yield put(setError(false))
  yield put(setDetailsError(false))
  try {
    const res:any = yield call(tryOnService.getTryOnList);
    yield put(setData(res?.data))
  } catch (error) {
    yield put(setError(true))
    yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
  }finally{
    yield put(setLoading(false))
  }
}

function* tryonFileUpload(action:{type:string,payload:{files:File[],userEmail:string}}):Generator{
  yield put(setFileUplading(true))
  const {files,userEmail} = action.payload
  const state :any = yield select((state:RootState) => state.tryOn)  ; 
   try {
    yield apply(swan.tryOn, swan.tryOn.uploadFile, [{ files, userEmail }]);
    const imageArray:TryOnImageUploadInterface[]=[]
    yield Promise.all(
      files.map(async (file) => {
        const base64File = await fileToBase64(file);
        const fileObj = { name: file.name,binaryFile: base64File as string,id:uuidv4() };
        imageArray.push(fileObj)
      })
    );
    const newImageArray = [...state.tryOnUploadImage, ...imageArray];
    yield put(setTryOnUploadImage(newImageArray))
   } catch (error) {    
    yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
   }finally{
    yield put(setFileUplading(false))
   }
}

function* setStatusApprove(action:{type:string,payload:{data:string[],info:boolean}}){
  yield put(setApproveLoading(true));
  try {
    yield call(tryOnService.setStatusApprove,action.payload.data);
    yield put(setSelectedData([]))
    if (action.payload.info) {
      yield put(setIsApproved(true))
    }
  } catch (error) {
    yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
  }finally{
    yield put(setApproveLoading(false))
  }
}
function* getSampleImages(action:{type:string,payload:string}):Generator{
  yield put(setLoading(true))
  try {
   const res:any = yield call(tryOnService.getSampleImages,action.payload);
   const arr = res?.data?.sampleImages?.reduce((acc:Record<string,string|boolean>[],el:Record<string,string|boolean>)=>{
      el ={...el,selected:false}
      acc.push(el);
      return acc
   },[])
   yield put(setSampleImages([...arr]))
  } catch (error) {
    yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
  }finally{
    yield put(setLoading(false))
  }
}

function* getTryOnTrials(action:{type:string,payload:{selectedUserImages:string[],selectedProductHandles:string[]}}):Generator{
  yield put(setApproveLoading(true))
  try {
    yield call(tryOnService.getTryOnTrials,action.payload.selectedUserImages,action.payload.selectedProductHandles);
    yield put(setTryonTrialRequestSent(true))
    yield put(setTryOnUploadImage([]))
    } catch (error) {
      yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
  }finally{
    yield put(setApproveLoading(false))
  }
}

function* getTryOnProductDetails(action:{type:string,payload:string}):Generator{
  yield put(setDetailsLoading(true))
  yield put(setDetailsError(false))
  try {
    const res:any = yield call(tryOnService.getTryOnProductDetails,action.payload);
    yield put(setDetailsData(res?.data));
    } catch (error) {
    yield put(setDetailsError(true))
    yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
  }finally{
    yield put(setDetailsLoading(false))
  }
}
function* getTryOnReviewImages():Generator{
  yield put(setDetailsLoading(true))
  yield put(setDetailsError(false))
  try {
    const res:any = yield call(tryOnService.getTryOnReviewImages);
    yield put(setReviewImages(res?.data?.tryonImages));
    } catch (error) {
    yield put(setDetailsError(true))
    yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
  }finally{
    yield put(setDetailsLoading(false))
  }
}
function* setTryOnFinetune(action:{type:string,payload:{selectedProductHandles:string[],feedback:string,info:boolean}}):Generator{
  yield put(setProgressLoading(true));
 
  // yield put(setIsFineTuned(false))
  try {
    yield call(tryOnService.setTryOnReviewFinetune,action.payload.selectedProductHandles,action.payload.feedback);
    if (action.payload.info) {
      yield put(setIsFineTuned(true));
    }
    } catch (error) {
      yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
  }finally{
    yield put(setProgressLoading(false))
  }
}
function* handleApproveAndFineTune(action:{type:string,payload:ReviewData[]}):Generator{
  yield all([
    put(setProgressLoading(true)),
    put(setIsApproved(false)),
    put(setIsFineTuned(false))
  ])
 try {
  const fineTuneData: ReviewData[] = [];
		const approveData: string[] = [];
		action.payload.forEach((el) => {
			if (el?.isFinetune) {
				fineTuneData.push(el);
			} else {
				approveData.push(el.name);
			}
		});
    if (fineTuneData?.length) {
      for (const el of fineTuneData) {
        yield call(setTryOnFinetune,{
          type: HANDLE_GET_TRY_ON_FINE_TUNE,
          payload: { selectedProductHandles: [el.name], feedback: el.feedback ,info:false},
        });
      }
    } 
		if (approveData.length) {
			yield call(setStatusApprove,{ type: HANDLE_GET_TRY_ON_IMAGES_APPROVE, payload:{data:approveData,info:false }});
		}   
    yield all([
      put(setIsApproved(true)),
      put(setIsFineTuned(true))
    ])
 } catch (error) {
  console.log(error);
 }finally{
  yield put(setProgressLoading(false))
 }
}

function* checkTryonOnboardEligibilty(action:{type:string,payload:{products:string[],type:string}}):Generator {
  yield put(setCheckEligibilityLoading(true))
  try {
    yield call(tryOnService.setTryOnOnBoardEligibilty,action.payload.products)
    if(action.payload.type==="all"){
      const state :any = yield select((state:RootState) => state.tryOn)  ; 
      const arr = state.data?.map((el: Record<string, any>)=>{
        if(!el?.tryonLaunchStatus){
          el = {...el,tryonLaunchStatus:"sent_for_elibility"}
        }
        return el;
      });
      yield put(setData([...arr]))
    }
  } catch (error) {
    yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
  }finally{
    yield put(setCheckEligibilityLoading(false))
  }
}

function* deleteTryonUploadedFile(action:{type:string,payload:{fileName:string,productHandle:string}}):Generator{
  try {
    yield call(tryOnService.tryonDeleteUploadedFile,action.payload.fileName,action.payload.productHandle)
    const state :any = yield select((state:RootState) => state.tryOn); 
    const arr = state.tryOnUploadImage.filter((el:TryOnImageUploadInterface)=>el.name!== action.payload.fileName)
    yield put(setTryOnUploadImage([...arr]));
  } catch (error) {
    yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
  }
}  

function* getTryonOnBoard(action:{type:string;payload:{selectedProductHandles:string[],isOnboarded:boolean}}):Generator{
  try {
   yield call(tryOnService.updateTryOnOnBoard,action.payload.selectedProductHandles,action.payload.isOnboarded)
  } catch (error) {
    yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
  }
}
function* uploadTryonFile(action: { type: string; payload: { data: TryOnImageUploadInterface[];productHandle : string } }): Generator {
	const { payload } = action;
	yield put(setFileUplading(true));
	try {
		const state: any = yield select((state: RootState) => state.tryOn);
		for (let index = 0; index < payload.data.length; index++) {
      const file = payload.data[index]?.file;
      if (file) {
        const res: any = yield call(tryOnService.tryonUploadFile,file.name,payload.productHandle);
        yield call(techpackService.uploadFileAWSBucket, file, res?.data?.uploadUrl);
      }
		}
    yield put(setTryOnUploadImage([...state.tryOnUploadImage,...payload.data]))
		
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
	} finally {
    yield put(setFileUplading(false));
	}
}

function* tryonOnboardCheck(action:{type:string,payload:string}):Generator {
  yield put(setSubmitLoading(true))
  try {
    yield call(tryOnService.tryonSubmitUploadedFile,action.payload)
    yield put(setShowModal(false))
    yield call(fetchTryOnData);
    yield put(setTryOnUploadImage([]))
  } catch (error) {
    yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
  }finally{
    yield put(setSubmitLoading(false))

  }
}

function* tryOnSaga(){
    yield takeLatest(HANDLE_GET_TRYON_LIST,fetchTryOnData),
    yield takeLatest(HANDLE_TRY_ON_FILE_UPLOAD,tryonFileUpload),
    yield takeLatest(HANDLE_GET_TRY_ON_IMAGES_APPROVE,setStatusApprove),
    yield takeLatest(HANDLE_GET_SAMPLE_IMAGES,getSampleImages),
    yield takeLatest(HANDLE_GET_TRY_ON_TRIALS,getTryOnTrials),
    yield takeLatest(HANDLE_GET_TRY_ON_PRODUCT_DETAILS,getTryOnProductDetails);
    yield takeLatest(HANDLE_GET_TRY_ON_REVIEW_IMAGES,getTryOnReviewImages);
    yield takeLatest(HANDLE_GET_TRY_ON_FINE_TUNE,setTryOnFinetune);
    yield takeLatest(HANDLE_GET_TRY_ON_APPOVE_AND_FINE_TUNE,handleApproveAndFineTune);
    yield takeLatest(HANDLE_TRY_ON_ONBAORD,getTryonOnBoard);
    yield takeLatest(HANDLE_TRY_ON_ONBAORD_ELIGIBILITY,checkTryonOnboardEligibilty);
    yield takeLatest(HANDLE_DELETE_TRY_ON_UPLOADED_FILE,deleteTryonUploadedFile);
    yield takeLatest(HANDLE_TRY_ON_UPLOADED_FILE,uploadTryonFile);
    yield takeLatest(HANDLE_TRY_ON_SUBMIT,tryonOnboardCheck)



}

export default tryOnSaga