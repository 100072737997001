import CustomTableMui from "component/customTableMui/CustomTableMui";
import { MeasurementRowOptions, MUI_TABLE_TYPE } from "lib/Constants";
import { deepCopy, generateUuid, getColumnsDataTechpack, makeMeasurementRowSelected, modifyMeasurementRow } from "lib/utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./SizingOnboardingChild.module.scss";
import { useDispatch } from "react-redux";
import { HANDLE_DEBOUCE_UPDATE_TECHPACK_PARSE } from "store/techpack/action.types";

const SizingOnboardingTable = ({ data }: any) => {
	const [measurementData, setMeasurementData] = useState<any>(deepCopy(data));
	const measurements = useMemo(() => data?.techpackfileMeasurements, [data]);
	const [rowOptions, setRowOptions] = useState<any[]>(deepCopy(MeasurementRowOptions));
	const [rowsArray, setRowsArray] = useState<{ name: string; id: string }[]>([]);
	const dispatch = useDispatch();

	const handleInitialRowsOptions = useCallback(() => {
		const keys: string[] = data?.techpackfileMeasurements ? Object.keys(data?.techpackfileMeasurements) ?? [] : [];
		if (keys.length) {			
			setRowsArray(modifyMeasurementRow({ key:"techpack", measurementData: data?.techpackfileMeasurements,keys,rowOptions }));
			setRowOptions([...makeMeasurementRowSelected({ options: "techpack", rowOptions, beRowOptions: keys, beValues: data?.techpackfileMeasurements })]);
		} 
	}, [data, rowOptions, rowsArray]);

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, size: string | undefined, index: number | undefined) => {
			const { name, value } = e.target;
			console.log(index);
			if (size && name) {
				const newMeasurements = { ...measurementData.techpackfileMeasurements };
				if (newMeasurements[size][name]) {
					newMeasurements[size][name] = +value;
				} else {
					newMeasurements[size] = {
						...newMeasurements[size],
						[name]: +value,
					};
				}
				setMeasurementData({
					...measurementData,
					techpackfileMeasurements: newMeasurements,
				});
				const payload = {
					data: { parsedTableKey: measurementData?.parsedTableKey, techpackfileMeasurements: newMeasurements },
					type: "onlyMeasurement",
				};
				dispatch({ type: HANDLE_DEBOUCE_UPDATE_TECHPACK_PARSE, payload });
			}
		},
		[measurementData],
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleSelectChange = (name: string, index = 0, skip = false) => {
		rowsArray.push({ name, id: generateUuid() });
		setRowsArray([...rowsArray]);
		setRowOptions([...makeMeasurementRowSelected({ options: "selection", rowOptions, key: name })]);
	};

	useEffect(() => {
		handleInitialRowsOptions();
	}, []);

	return (
		<div className={`${styles.sizeOnboardTable}`}>
			<h2>{measurementData?.identifier || "-"}</h2>
			<CustomTableMui
				type={MUI_TABLE_TYPE.TECHPACK}
				rows={rowOptions}
				data={measurementData?.techpackfileMeasurements || {}}
				columns={getColumnsDataTechpack(measurements || {})}
				handleChange={handleChange}
				handleSelectChange={handleSelectChange}
				rowArray={rowsArray}
			/>
		</div>
	);
};

export default SizingOnboardingTable;
