import React, { useEffect, useState } from 'react';
import styles from './CustomDetailsChild.module.scss';
// import edit from 'assets/images/edit.svg';
import axios from 'axios';
import LoadingScreen from 'component/LoadingScreen';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { MUI_TABLE_TYPE, REACT_APP_PUBLIC_MODEL_VIEW_URL, regex } from 'lib/Constants';
import Model from 'component/Model';
// import CustomButton from 'atoms/customButton/CustomButton';
import { customMeasurementValue, deepCopy } from 'lib/utils';
import CustomTableMui from 'component/customTableMui/CustomTableMui';
import { ModalTableData, reviewHeadCells } from 'component/customTableMui/tableData';
import CustomDialog from 'component/CustomDialog';
import MeasurementDescriptionModal from 'component/measurementDescriptionModal/MeasurementDescriptionModal';
import { useDispatch } from 'react-redux';
import { setMeasurementEditable, setNoteEditable } from 'store/custom/reducer';
import {  HANDLE_CUSTOM_SCAN_EXPORT, HANDLE_DEBOUNCE_CUSTOM_MEASUREMENT_UPDATE, HANDLE_DEBOUNCE_CUSTOM_NOTES_UPDATE } from 'store/custom/action.types';
import downloadIcon from "assets/images/download-02.svg";
import ExcelIcon from "assets/images/excel-sheet.svg";
import GoogleSheetIcon from "assets/images/google-sheet.svg";
import ExportToCSV from 'atoms/exportToCSV/ExportToCSV';
import { columns } from './Constants';


const CustomNameDetailRight = () => {
    const [measurementsData, setMeasurementsData] = useState<{ id: string; name: string; value: any }[]>([]);
    const [selectedValueCopy, setSelectedValueCopy] = useState<Record<string, any>>({})
    const {userDetails} = useSelector((state:RootState)=>state.auth)
    const [modelUrl, setModelUrl] = useState('');
    const { selectedValue } = useSelector((state: RootState) => state.custom)
    // const [showMore, setShowMore] = useState<boolean>(false);
    const [showModalTable, setShowModalTable] = useState<boolean>(false);
    const [id, setId] = useState<string>();
    const dispatch = useDispatch();

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, row?: string) => {
        const value = e.target.value;
        if (+value < 0 || !regex.test(value)) {
            return;
        }
        const a ={...selectedValueCopy}
        if (row) {
            a.measurements[row] = value;
        }
        setSelectedValueCopy({ ...a });
        const index = measurementsData?.findIndex((el: { id: string; name: string; value: any }) => el?.name === row);
        measurementsData[index].value = value;
        setMeasurementsData([...measurementsData]);
        dispatch({ type: HANDLE_DEBOUNCE_CUSTOM_MEASUREMENT_UPDATE, payload: { data:a?.measurements , id: selectedValue?.id } })
    };

    const getModelForScanId = async () => {
        try {
            const res = await axios.get(`${REACT_APP_PUBLIC_MODEL_VIEW_URL}/model/${selectedValue?.id}`);
            setModelUrl(res?.data?.modelUrl.url);
        } catch (error) {
            console.log(error);
        }
    };
    const handleModal = (id: string) => {
        setId(id)
        setShowModalTable(true)
    }

    const handleChange = () => {
      return measurementsData.map((item: any) => ({
        measurement: `"${item?.name}"`,
        value: `"${item?.value}"`,
      }));
    };

    // const handleMeasurementSubmit = () => {
    //     dispatch({ type: HANDLE_CUSTOM_MEASUREMENT_UPDATE, payload: { data: selectedValueCopy?.measurements, id: selectedValue?.id } })
    // }
    // const handleNotesSubmit = () => {
    //     dispatch({ type: HANDLE_CUSTOM_NOTES_UPDATE, payload: { notes: selectedValueCopy?.notes?.notes, id: selectedValue?.id } })
    // }
    const handelNoteSubmit=(e:any)=>{
        setSelectedValueCopy((prev: any) => {
            return { ...prev, notes: { notes: e.target.value } };
        })
        dispatch({type:HANDLE_DEBOUNCE_CUSTOM_NOTES_UPDATE,payload:{id:selectedValue?.id,notes:e.target.value}})
    }
    useEffect(() => {
        const arr = customMeasurementValue(selectedValue?.measurements);
        setMeasurementsData(arr);
        getModelForScanId();
        setSelectedValueCopy(deepCopy(selectedValue))
        return () => {
            dispatch(setMeasurementEditable(false))
            dispatch(setNoteEditable(false))
        }
    }, []);
    return (
      <div className={` ${styles.detailRight}`}>
        <div className={` ${styles.detailRight__header}`}>
          {/* <h2>AI Mesh </h2>
          <p>Learn more about your customer’s shape & stance. Use your cursor or keys to move it around. </p> */}
          {/* <ul className='flex'>
                        <li>
                            <span className={` ${styles.detailRight__action}  ${styles.active}`}>Pose</span>
                        </li>
                        <li>
                            <span className={` ${styles.detailRight__action} `}>Overlay</span>
                        </li>
                    </ul> */}
          {/* <ul className='flex'>
                        <li>
                            <span className={` ${styles.detailRight__latter}`}>T</span>
                        </li>
                        <li>
                            <span className={` ${styles.detailRight__latter}`}>A</span>
                        </li>
                    </ul> */}
        </div>
        <div className={` ${styles.detailRight__wrapper}`}>
          {modelUrl ? <Model src={modelUrl} /> : <LoadingScreen size={20} />}
        </div>
        <div className={` ${styles.detailRight__textBox}`}>
          <textarea
            value={selectedValueCopy?.notes?.notes}
            placeholder="Add your notes"
            onChange={handelNoteSubmit}
          ></textarea>
          {/* <p>
            Add your figuration analysis here. Over time, Swan will learn and
            auto generate these.
          </p> */}
        </div>
        <div className={` ${styles.detailRight__boxBorder}`}>
          <div
            className={` ${styles.detailRight__header} flex-wrap justify-between align-center`}
          >
            <p>With associated measurements</p>
            <span className={` ${styles.detailRight__hoverBox}`} role="button">
              <img src={downloadIcon} alt="download" />
              <span className={` ${styles.detailRight__hoverBoxList}`}>
                <ul>
                  <li
                    onClick={() =>
                      dispatch({
                        type: HANDLE_CUSTOM_SCAN_EXPORT,
                        payload: {
                          id: selectedValue?.id,
                          email: userDetails?.email,
                        },
                      })
                    }
                  >
                    <img src={GoogleSheetIcon} alt="google sheet" /> Export to
                    Google Sheets
                  </li>
                  <li>
                    <ExportToCSV
                      headers={columns.map((column) => {
                        return { id: column.key, displayName: column.label };
                      })}
                      filename={`Measurement-File.csv`}
                      loadData={() => handleChange()}
                      label="Export to CSV"
                      icon={ExcelIcon}
                    ></ExportToCSV>
                    {/* <img src={ExcelIcon} alt="google sheet" /> Export to Excel */}
                  </li>
                </ul>
              </span>
            </span>
          </div>
          <div className={` ${styles.detailRight__wrapper}`}>
            <div
              className={` ${styles.detailRight__tableEdit} flex justify-end align-center`}
            >
              {/* {measurementEditable ? (
                            <CustomButton
                                disabled={measurementsSubmitLoading}
                                loading={measurementsSubmitLoading}
                                className="button primary"
                                buttonText="Save"
                                handleFunc={handleMeasurementSubmit}
                            />
                        ) : (
                            <span role="cursor" onClick={() => dispatch(setMeasurementEditable(true))}>
                                <img src={edit} />
                            </span>
                        )} */}
            </div>
            <div
              className={` ${styles.detailRight__table} table-group tableCustom`}
            >
              <div className={` ${styles.detailRight__inner}`}>
                {measurementsData?.length > 0 && (
                  <CustomTableMui
                    measurementEditable={true}
                    rows={measurementsData}
                    columns={reviewHeadCells}
                    handleChange={handleOnChange}
                    type={MUI_TABLE_TYPE.CUSTOM_MEASUREMENT_LIST}
                    handleModal={handleModal}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className={` ${styles.detailRight__noteText}`}>
              
                    <div className={`flex justify-between align-center ${styles.detailRight__header}`}>
                        <div>
                            <h2>Notes</h2>
                            <p>Add notes about your customer and their preference.</p>
                        </div>
                        <div>
                            {noteEditable ? (
                                <CustomButton buttonText="save" className="button sm" disabled={notesSubmitLoading}
                                    loading={notesSubmitLoading} handleFunc={handleNotesSubmit} />
                            ) : (
                                <CustomButton buttonText={(selectedValueCopy?.notes?.notes || '')?.length > 0 ? 'Edit' : 'Add'} className="button sm" handleFunc={() => dispatch(setNoteEditable(true))} />
                            )}
                        </div>
                    </div>
                    <div className={` ${styles.detailRight__noteEdit}`}>
                        <h5>Notes</h5>
                        {noteEditable ? (
                            <textarea
                                autoFocus
                                value={selectedValueCopy?.notes?.notes ?? ''}
                                onChange={(e) =>
                                    setSelectedValueCopy((prev: any) => {
                                        return { ...prev, notes: { notes: e.target.value } };
                                    })
                                }
                            />
                        ) : showMore ? (
                            <p>{selectedValueCopy?.notes?.notes ?? ''}</p>
                        ) : (
                            <p>{(selectedValueCopy?.notes?.notes ?? '').substr(0, 445)}</p>
                        )}
                        {(selectedValueCopy?.notes?.notes ?? '').length > 445 && (
                            <div className={` ${styles.detailRight__readMore}`}>
                                <a onClick={() => setShowMore(!showMore)}>{showMore ? 'Show Less' : 'Read More'}</a>
                            </div>
                        )}
                    </div>
                </div> */}

        {measurementsData?.map((el: Record<string, any>, index) => {
          return (
            <React.Fragment key={index}>
              {el?.id === id && (
                <CustomDialog
                  className="desc-modal"
                  open={showModalTable}
                  handleShowModal={() => setShowModalTable(false)}
                >
                  <MeasurementDescriptionModal
                    open={showModalTable}
                    setShowModalTable={setShowModalTable}
                    image={
                      selectedValueCopy?.profile?.gender
                        ? ModalTableData[selectedValueCopy?.profile?.gender][
                            el?.name
                          ]?.path
                        : ""
                    }
                    title={
                      selectedValueCopy?.profile?.gender
                        ? ModalTableData[selectedValueCopy?.profile?.gender][
                            el?.name
                          ]?.title
                        : ""
                    }
                    desc={
                      selectedValueCopy?.profile?.gender
                        ? ModalTableData[selectedValueCopy?.profile?.gender][
                            el?.name
                          ]?.desc
                        : ""
                    }
                  />
                </CustomDialog>
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
};

export default CustomNameDetailRight;
