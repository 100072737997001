import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";

interface XlsxViewerProps {
	fileUrl: string;
	handleTotalContent?: (val: string[]) => void;
}

const XlsxViewer = ({ fileUrl, handleTotalContent }: XlsxViewerProps) => {
	const [sheetNames, setSheetNames] = useState<string[]>([]);
	const [currentSheetIndex, setCurrentSheetIndex] = useState(0);
	const [sheetData, setSheetData] = useState<any[][]>([]);
	const [workbook, setWorkbook] = useState<XLSX.WorkBook | null>(null); // Store the workbook

	useEffect(() => {
		const fetchExcelFile = async () => {
			try {
				const response = await fetch(fileUrl);
				const arrayBuffer = await response.arrayBuffer();
				const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: "array" });
				setWorkbook(workbook); // Store the workbook for later use
				setSheetNames(workbook.SheetNames);
				handleTotalContent?.(workbook.SheetNames);
				// Load the data from the first sheet by default
				const firstSheetName = workbook.SheetNames[0];
				const firstSheet = workbook.Sheets[firstSheetName];
				const data = XLSX.utils.sheet_to_json(firstSheet, { header: 1 }) as any;
				setSheetData(data);
			} catch (error) {
				console.error("Error fetching or processing Excel file:", error);
			}
		};

		if (fileUrl) {
			fetchExcelFile();
		}
	}, [fileUrl]);

	const handleSheetChange = (index: number) => {
		if (workbook) {
			const sheetName = sheetNames[index];
			const sheet = workbook.Sheets[sheetName];
			const data = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as any;
			setCurrentSheetIndex(index);
			setSheetData(data);
		}
	};

	return (
		<div className="drawer-content">
			{sheetNames.length > 0 ? (
				<div>
					<div className="drawer-content__header">
						<p>
							Total Sheets: <strong>({sheetNames.length})</strong>, Current Sheet: <strong>({sheetNames[currentSheetIndex]})</strong>
						</p>
					</div>
					<div className="drawer-content__scroll">
						<table border={1}>
							<tbody>
								{sheetData.map((row, rowIndex) => (
									<tr key={rowIndex}>
										{row.map((cell, cellIndex) => (
											<td key={cellIndex}>{cell}</td>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<div className="drawer-content__nav">
						<button onClick={() => handleSheetChange(currentSheetIndex - 1)} disabled={currentSheetIndex === 0}>
							Previous Sheet
						</button>
						<button onClick={() => handleSheetChange(currentSheetIndex + 1)} disabled={currentSheetIndex === sheetNames.length - 1}>
							Next Sheet
						</button>
					</div>
				</div>
			) : (
				<p>Loading...</p>
			)}
		</div>
	);
};

export default XlsxViewer;
