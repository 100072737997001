import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { setCreateLoading, setDeleteLoading, setFitModelsData, setLoading } from "./reducer";
import { addToast } from "store/toaster/reducer";
import { handleErrorMessage, scrollToBottom } from "lib/utils";
import { TOAST_TYPES } from "enum/enums";
import fitModelService from "services/fitModelService";
import {
	HANDLE_CREATE_FIT_MODEL,
	HANDLE_DEBOUNCE_UPDATE_FIT_MODEL_MEASUREMENT,
	HANDLE_DELETE_FIT_MODEL,
	HANDLE_GET_FIT_MODEL_LIST,
	HANDLE_UPDATE_FIT_MODEL_MEASUREMENT,
	HANDLE_UPDATE_FIT_MODEL_NAME,
} from "./action.types";
import { RootState } from "store/store";
import { setMeasurementList } from "store/measurement/reducer";
import measurementService from "services/measurementService";

function* getFitModelList(): Generator {
	yield put(setLoading(true));
	try {
		const res: any = yield call(fitModelService.getListFitModels);
		const resMes: any = yield call(measurementService.getMeasurementList);
		yield put(setMeasurementList(resMes?.data));
		yield put(setFitModelsData(res?.data ?? []));
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
	} finally {
		yield put(setLoading(false));
	}
}

function* deleteFitModel(action: { type: string; payload: string }): Generator {
	yield put(setDeleteLoading(true));
	try {
		const res: any = yield call(fitModelService.deleteFitModel, action.payload);
		if (res?.data?.deleted) {
			const state: any = yield select((state: RootState) => state.fitModel);
			const data = state.data.filter((el: any) => el.id !== res?.data?.fitmodelId);
			yield put(setFitModelsData(data));
		} else {
			yield put(addToast({ message: "Failed to delete", type: TOAST_TYPES.ERROR }));
		}
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
	} finally {
		yield put(setDeleteLoading(false));
	}
}
function* createFitModel(action: { type: string; payload: string }): Generator {
	yield put(setCreateLoading(true));
	try {
		const res: any = yield call(fitModelService.createFitModel, action.payload);
		const modRes = { ...res.data, measurements: null };
		const state: any = yield select((state: RootState) => state.fitModel);
		yield put(setFitModelsData([...state.data, modRes]));
		setTimeout(() => {
			scrollToBottom(res?.data?.id);
		});
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
	} finally {
		yield put(setCreateLoading(false));
	}
}

function* updateFitModelMeasurement(action: { type: string; payload: Record<string, any> }): Generator {
	try {
		yield call(fitModelService.updateFitModelMeasurement, action.payload);
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
	} finally {
		yield put(setLoading(false));
	}
}
function* updateFitModelName(action: { type: string; payload: Record<string, any> }): Generator {
	try {
		yield call(fitModelService.updateFitModelName, action.payload);
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
	} finally {
		yield put(setLoading(false));
	}
}
function* debounceFitModelUpdateMeasurement(action: { type: string; payload: Record<string, any> }) {
	yield delay(2000);
	yield call(updateFitModelMeasurement, { type: HANDLE_UPDATE_FIT_MODEL_MEASUREMENT, payload: action.payload });
}

function* fitModelSaga() {
	yield takeLatest(HANDLE_GET_FIT_MODEL_LIST, getFitModelList);
	yield takeLatest(HANDLE_DELETE_FIT_MODEL, deleteFitModel);
	yield takeLatest(HANDLE_CREATE_FIT_MODEL, createFitModel);
	yield takeLatest(HANDLE_UPDATE_FIT_MODEL_MEASUREMENT, updateFitModelMeasurement);
	yield takeLatest(HANDLE_DEBOUNCE_UPDATE_FIT_MODEL_MEASUREMENT, debounceFitModelUpdateMeasurement);
	yield takeLatest(HANDLE_UPDATE_FIT_MODEL_NAME, updateFitModelName);
}

export default fitModelSaga;
