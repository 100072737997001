import { TOKEN_DATA_TYPE } from "enum/enums";
import UseApiService from "./apiService";
import { API_END_POINTS } from "lib/Constants";


class GroupService {
	getGroupList(): Promise<any> {
		const body = {
			url: API_END_POINTS.GROUPS.getList,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
		};
		return UseApiService().get(body);
	}	
}

const groupService = new GroupService();
export default groupService;
