/* eslint-disable prefer-const */
import {  call, put, takeLatest } from "redux-saga/effects";
import { addToast } from "store/toaster/reducer";
import { TOAST_TYPES } from "enum/enums";
import { handleErrorMessage } from "lib/utils";
import measurementService from "services/measurementService";
import { HANDLE_CUSTOMISE_MESUREMENT_UPDATE, HANDLE_GET_MEASUREMENT_DEF, HANDLE_GET_MEASUREMENT_GROUP_INDIVIDUAL, HANDLE_GET_MEASUREMENT_GROUP_LIST, HANDLE_GET_MEASUREMENT_LIST, HANDLE_UPDATE_MEASUREMENT_DEF } from "./action.types";
import { setIndividualMeasurementGroup, setIndividualMeasurementLoading, setCustomiseMeasurementLoading, setMeasurementGroupList, setMeasurementGroupListLoading, setMeasurementList, setCustomiseMeasurementUpload, setIndividualMeasurementDef, setIndividualMeasurementDefLoading, setError } from "./reducer";
import techpackService from "services/techpackservice";

function* fetchMeasurementGroupList(): Generator {
  yield put(setMeasurementGroupListLoading(true));
  try {
    const res: any = yield call(measurementService.getMeasurementGroupList);
    const list: any = yield call(fetchMeasurementList,{ type:HANDLE_GET_MEASUREMENT_LIST, payload: true});
    const listValues:Record<string,any>[] = Object.values(list);
    const modRes = res?.data?.reduce((acc:Record<string,any>[],el:Record<string,any>)=>{
      const groupDone = listValues?.filter((element:Record<string,any>)=> element?.action!=="skip" && element?.displayGroup===el?.displayGroup )
      el = {...el,measurements:groupDone}
      acc.push(el)
      return acc;
    },[])    
    yield put(setMeasurementGroupList(modRes));
  } catch (error) {
    yield put(
      addToast({ type: TOAST_TYPES.ERROR, messsage: handleErrorMessage(error) })
    );
  } finally {
    yield put(setMeasurementGroupListLoading(false));
  }
}

function* fetchMeasurementGroupIndividual(action: { type: string; payload: any }): Generator {
  yield put(setIndividualMeasurementLoading(true));
  try {
    const res: any = yield call(measurementService.getIndividualMeasurementGroup,action.payload);
    yield put(setIndividualMeasurementGroup(res?.data));
  } catch (error) {
    yield put(
      addToast({ type: TOAST_TYPES.ERROR, messsage: handleErrorMessage(error) })
    );
  } finally {
    yield put(setIndividualMeasurementLoading(false));
  }
}
function* fetchMeasurementList(action: { type: string; payload?: boolean}): Generator {
  try {
    const res: any = yield call(measurementService.getMeasurementList);
    if (action.payload) {
      return res?.data;
    }
    yield put(setMeasurementList(res?.data));
 
  } catch (error) {
    if (action.payload) {
      return [];
    }
    yield put(
      addToast({ type: TOAST_TYPES.ERROR, messsage: handleErrorMessage(error) })
    );
  } 
}

function* fetchUpdateMeasurementdef(action: { type: string; payload: any}): Generator {
  yield put(setIndividualMeasurementLoading(true));
  try {
    yield call(measurementService.updateMeasurement,action?.payload?.payload,action?.payload?.name);
  } catch (error) {
    yield put(
      addToast({ type: TOAST_TYPES.ERROR, messsage: handleErrorMessage(error) })
    );
  }
}
function* customiseMeasurementSubmit(action: { type: string; payload:any }): Generator {
  yield put(setCustomiseMeasurementLoading(true));
  yield put(setCustomiseMeasurementUpload(false))
  try {
    let {data,measureDefKey} = action.payload
    if (data?.file) {
      const res: any = yield call(measurementService.uploadFileUrl,data?.file.name,measureDefKey);
      yield call(techpackService.uploadFileAWSBucket,data.file, res?.data?.uploadUrl);
       delete data?.["file"];
    }
    yield put({type:HANDLE_UPDATE_MEASUREMENT_DEF,payload:{payload:data,name:measureDefKey}})
    yield put(setCustomiseMeasurementUpload(true))
  } catch (error) {
    yield put(
      addToast({ type: TOAST_TYPES.ERROR, messsage: handleErrorMessage(error) })
    );
  }finally{
    yield put(setCustomiseMeasurementLoading(false));
  }
}

function* fetchIndividualMeasurementdef(action: { type: string; payload: any}): Generator {
  yield put(setError(false))
  yield put(setIndividualMeasurementDefLoading(true));
  try {
    const res: any = yield call(measurementService.getIndividualMeasurement,action?.payload);
    yield put(setIndividualMeasurementDef(res?.data))
  } catch (error) {
    yield put(setError(true))
    yield put(
      addToast({ type: TOAST_TYPES.ERROR, messsage: handleErrorMessage(error) })
    );
  }finally{
    yield put(setIndividualMeasurementDefLoading(false));
  }
}


function* measurementSaga() {
  yield takeLatest(HANDLE_GET_MEASUREMENT_GROUP_LIST, fetchMeasurementGroupList);
  yield takeLatest(HANDLE_GET_MEASUREMENT_GROUP_INDIVIDUAL, fetchMeasurementGroupIndividual);
  yield takeLatest(HANDLE_GET_MEASUREMENT_LIST,fetchMeasurementList );
  yield takeLatest(HANDLE_UPDATE_MEASUREMENT_DEF,fetchUpdateMeasurementdef);
  yield takeLatest(HANDLE_CUSTOMISE_MESUREMENT_UPDATE,customiseMeasurementSubmit)
  yield takeLatest(HANDLE_GET_MEASUREMENT_DEF,fetchIndividualMeasurementdef)
}

export default measurementSaga;
