import axios from "axios";
import { ApiDataObject } from "interface/interface";
import { REACT_APP_BASE_URL } from "lib/Constants";

const baseUrl = REACT_APP_BASE_URL ?? "";

const UseApiService = () => {
  return {
    async get(obj: ApiDataObject) {
      return await axios.get(`${baseUrl}${obj?.url}`, {
        params: { ...obj?.data },
        headers: {
          headerToken: obj?.headerToken,
        },
      });
    },
    async post(obj: ApiDataObject) {
      return await axios.post(`${baseUrl}${obj?.url}`, obj?.data, {
        params: { ...obj?.params },
        headers: {
          headerToken: obj?.headerToken,
        },
      });
    },
    async put(obj: ApiDataObject) {
      return await axios.put(`${baseUrl}${obj?.url}`, obj?.data, {
        headers: {
          headerToken: obj?.headerToken,
        },
      });
    },
    async delete(obj: ApiDataObject) {
      return await axios.delete(`${baseUrl}${obj?.url}`, {
        params: { ...obj?.data },
        headers: {
          headerToken: obj?.headerToken,
        },
      });
    },
    async patch(obj: ApiDataObject) {
      return await axios.patch(`${baseUrl}${obj?.url}`, obj?.data, {
        headers: {
          headerToken: obj?.headerToken,
        },
      });
    },
  };
};

export default UseApiService;
