import styles from "./BackButton.module.scss";
import backIcon from "../../assets/images/arrow-left-gray.svg";
import { useNavigate } from "react-router-dom";


const BackButton = ({route}:{route?:string}) => {
    const navigate = useNavigate();
    return (
        <div className={` flex-wrap`}>
           <span onClick={() =>route ? navigate(route) : navigate(-1)} role="button" className={`${styles.back} flex align-center gap-5`}><img src={backIcon} alt="icon"/> Back</span>
            </div>
);
};

export default BackButton;