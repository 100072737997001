import {createSlice} from "@reduxjs/toolkit"

interface InitialStateInterface{
    measurementGroupListLoading:boolean;
    measurementGroupList:Record<string, any>[];
    individualMeasurement:any[],
    individualMeasurementLoading:boolean;
    measurementList:any;
    customiseMeasurementLoading:boolean;
    customiseMeasurementUpload:boolean;
    individualMeasurementdef:{definition:string,measurementName:string,measurementImageUrl:string}
    individualMeasurementDefLoading:boolean,
    error:boolean
}
const InitialState:InitialStateInterface={
    measurementGroupList:[] ,
    individualMeasurement:[],
    measurementGroupListLoading:false ,
    individualMeasurementLoading:false,
    measurementList:{},
    customiseMeasurementLoading:false,
    customiseMeasurementUpload:false,
    individualMeasurementdef:{definition:"",measurementName:"",measurementImageUrl:""},
    individualMeasurementDefLoading:false,
    error:false
}

const measurementSlice= createSlice({
    name:"measurement",
    initialState:InitialState,
    reducers:{
        setMeasurementGroupList:(state,{payload})=>{
            state.measurementGroupList= payload
        },
        setMeasurementGroupListLoading:(state,{payload})=>{
            state.measurementGroupListLoading= payload
        },
        setIndividualMeasurementGroup:(state,{payload})=>{
            state.individualMeasurement= payload
        },
        setIndividualMeasurementLoading:(state,{payload})=>{
        state.individualMeasurementLoading=payload
        },
        setMeasurementList:(state,{payload})=>{
            state.measurementList=payload
        },
        setCustomiseMeasurementLoading:(state,{payload})=>{
            state.customiseMeasurementLoading=payload
        },
        setCustomiseMeasurementUpload:(state,{payload})=>{
            state.customiseMeasurementUpload=payload
        },
        setIndividualMeasurementDef:(state,{payload})=>{
            state.individualMeasurementdef=payload
        },
        setIndividualMeasurementDefLoading:(state,{payload})=>{
            state.individualMeasurementDefLoading=payload
        },
        setError:(state,{payload})=>{
            state.error = payload
        }
     
    }

})

export const {setMeasurementGroupList,setMeasurementGroupListLoading,setIndividualMeasurementGroup,setIndividualMeasurementLoading,setMeasurementList,setCustomiseMeasurementLoading,setCustomiseMeasurementUpload,setIndividualMeasurementDef,setIndividualMeasurementDefLoading,setError}= measurementSlice.actions
export default measurementSlice.reducer