import UseApiService from "./apiService";
import {  TOKEN_DATA_TYPE } from "enum/enums";
import { API_END_POINTS } from "lib/Constants";




export const getProductsSupported = async (date: string | number) => {
  const body = {
    url: API_END_POINTS.DASHBOARD_METRICS,
    data: { from_date: date },
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };

  return UseApiService().get(body);
};
export const getOrdersDetails = async (date: string | number) => {
  const body = {
    url: API_END_POINTS.DASHBOARD_ORDERS,
    data: { after: date },
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };

  return UseApiService().get(body);
};
interface fetchEventsDataProps {
  event: string;
  after: string;
  properties?: string;
  extraParams?: string;
  afterDate:string;
  beforeDate:string
}
export const fetchEventsData = async ({ event, after, beforeDate, extraParams ,afterDate}: fetchEventsDataProps) => {
  let url = `${API_END_POINTS.DASHBOARD_ANALYTICS}?event=${event}&beforeDate=${beforeDate}`;

  if(afterDate){
    url += `&afterDate=${afterDate}`
  }
  else{
     url += `&after=${after}`
  }

  if (extraParams) {
    url += `&${extraParams}`;
  } 
  const body = {
    url,
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().get(body);
};
