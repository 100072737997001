import React from "react";
import { Link } from "react-router-dom";
import styles from "./QuickStartCard.module.scss";
import arrow from "assets/images/blue-arrow.svg";
import { COLOURS, BGCOLOURS, ROUTES, SELECT_TYPE } from "lib/Constants";
import Capsule from "atoms/capsule/Capsule";
import { sumOfNumbersFromId } from "lib/utils";

interface QuickStartCardProps {
	imageUrl: string;
	route: string;
	heading?: string;
	para?: string;
	time?: string;
	data?: any[];
	type: string;
	altRoute: string;
}

const QuickStartCard = ({ imageUrl, route, heading, para, time, data = [], type, altRoute }: QuickStartCardProps) => {
		
	return (
		<div className={`${styles.quickCard} flex-column`}>
			{data?.length === 0 && (
				<figure>
					<img src={imageUrl} alt="quickCard" />
				</figure>
			)}

			<figcaption className="flex-column justify-between">
				<div className={`${styles.quickCard__info}`}>
					<h2>{heading}</h2>
					{data?.length === 0 && <p>{para}</p>}
					<div className={`${styles.quickCard__links} flex-wrap`}>
						{data?.map((el, index) => (
							<React.Fragment key={index}>
								
									{type === SELECT_TYPE.FITMODEL && (
										<div className={`${styles.quickCard__linksCol}`}  key={el?.id}>
											<Link to={`${ROUTES.FIT_MODEL_ONBOARDING}/?selectedId=${el?.id}`}>
												<Capsule bgColor={BGCOLOURS[(sumOfNumbersFromId(el?.id) % 5)]} colour={COLOURS[(sumOfNumbersFromId(el?.id) % 5)]} name={el?.name ?? ""} />
											</Link>
										</div>
									)}
									{type === SELECT_TYPE.TECHPACK && (
										<div className={`${styles.quickCard__linksCol}`}  key={el?.techpackFileId}>
											<Capsule bgColor="#f2f4f7" name={el?.fileName ?? ""} />
										</div>
									)}
									{type === SELECT_TYPE.MESUREMENT && (
										el?.name && <div className={`${styles.quickCard__linksCol}`}  key={el?.id}>
											{/* <Link to={`${ROUTES.FIT_MODEL_ONBOARDING}/?selectedId=${el?.id}`}> */}
												<Capsule bgColor="#f2f4f7" name={el?.name ?? ""} />
											{/* </Link> */}
										</div>
									)}
								
							</React.Fragment>
						))}
					</div>
				</div>
				<div className={` ${data.length === 0 ? `` : "flex justify-end"}`}>
					<Link to={data?.length > 0 ? altRoute : route} className={`${styles.quickCard__button} ${data.length === 0 ? `` : "justify-center"} `}>
						{data.length === 0 ? (
							<>
								{time}
								<span>
									Start <img src={arrow} alt="arrow" />
								</span>
							</>
						) : (
							<>
								<span>Open</span>
							</>
						)}
					</Link>
				</div>
			</figcaption>
		</div>
	);
};

export default QuickStartCard;
