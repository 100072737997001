import React, { useState } from 'react'
import styles from './CustomResizeDrawer.module.scss';
import expandIcon from 'assets/images/expand-icon.svg';
import shrinkIcon from 'assets/images/shrink.svg';

interface CustomResizeDrawerProps{
    children:React.ReactNode,
    mobileFull?:boolean,
    noImage?:boolean
}

const CustomResizeDrawer = ({children,mobileFull,noImage}:CustomResizeDrawerProps) => {
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };
    return (
        <div className={` ${styles.drawer}  ${mobileFull ? styles.fullSizeMobile: null} ${isActive ? styles.fullSize: null} ${isActive ? 'fullSize': 'toggledSize'} flex-column`}>
            <div className={`${styles.drawer__resize} flex justify-end`}>
                {!noImage &&<span role='button' onClick={toggleClass} >
                    <img src={isActive ? shrinkIcon : expandIcon} alt='icon' />
                </span>}
            </div>
            {children}
        </div>
    )
}

export default CustomResizeDrawer