import React from "react";
import styles from "./MeasurementDefinationCard.module.scss";
import checkCircle from "assets/images/check-circle-blue.svg";

interface MesurementDefinationCardProps {
	data: Record<string, any>;
	setSelectedGroupdata: (a:Record<string,any>) => void;
	selectedGroupdata: Record<string,any>|undefined;
    handleAdjustDefinition:(a:Record<string, string | number>)=>void;
    index:number
}

const MesurementDefinationCard = ({ data, setSelectedGroupdata, selectedGroupdata,index,handleAdjustDefinition }: MesurementDefinationCardProps) => {
  
	return (
		<div className={`${styles.measurementCard}`}>
			<label>
				<input
					type="radio"
					name="measurement"
					checked={selectedGroupdata?.measureDefKey === data?.measureDefKey}
					onChange={() => setSelectedGroupdata(data)}
				/>
				<div role="button" className={`${styles.measurementCard__box} flex-column`}>
					<span className={`${styles.checkCircle}`}>
						<img src={checkCircle} alt="icon" />
					</span>
					<div className={`${styles.measurementCard__boxInfo}`}>
						<span className={`${styles.chip}`}>{String.fromCharCode(65+index) }</span>
						<h5> {data?.measurementName}</h5>
						<p>{data?.definition}</p>
					</div>
					<span role="button" className={`${styles.measurementCard__boxLink}`} onClick={(e:any) => {
                         e.stopPropagation()
						handleAdjustDefinition(data)}}>
                    Customize measurement
					</span>
				</div>
			</label>
		</div>
	);
};

export default MesurementDefinationCard;
