import LoadingScreen from "component/LoadingScreen";
import styles from "./TryOnSelection.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useDispatch } from "react-redux";
import { setSampleImages, setTryOnUploadImage } from "store/tryOn/reducer";
import { deepCopy } from "lib/utils";
import { useCallback, useEffect } from "react";
import { HANDLE_GET_SAMPLE_IMAGES } from "store/tryOn/action.types";

const TryOnSampleImages = ({selectedGender}:{selectedGender:string}) => {
    const {sampleImages,loading} = useSelector((state:RootState)=>state.tryOn)
    const dispatch = useDispatch()
    const handleSelectedImage =useCallback((val:number)=>{
        const arr = deepCopy(sampleImages);
        arr[val].selected = !arr[val].selected;
        dispatch(setSampleImages(arr));
      },[sampleImages]) 
    useEffect(()=>{
        dispatch({type:HANDLE_GET_SAMPLE_IMAGES,payload:selectedGender==="Male"?"male":"female"})
    },[selectedGender])
    useEffect(()=>{
        dispatch(setTryOnUploadImage([]))
      },[])
      
    return loading ? <div className="flex align-center justify-center h-full">
    <LoadingScreen height={false} />
  </div> : (
        <ul className='flex-wrap'>
            {sampleImages?.map((el,index:number)=>(
                <li key={index} onClick={()=>handleSelectedImage(index)}>
            <figure role='button' className={el?.selected ? styles.active:""}>
                <img src={el?.url} alt='images' />
            </figure>
        </li>
            ))}
    </ul>
    )
}

export default TryOnSampleImages