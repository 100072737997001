import React from "react";
import styles from "./Analytics.module.scss";
import { ANALYTICS_CONTAINER_TITLE } from "enum/enums";

import AnalyticCardContainer from "atoms/analyticCardContainer/AnalyticCardContainer";
import AnalyticCoreCards from "component/analyticCoreCards/AnalyticCoreCards";
import AnalyticSizingCards from "component/analyticSizingCards/AnalyticSizingCards";
import AnalyticTryOnCards from "component/analyticTryOnCards/AnalyticTryOnCards";


const AnalyticsChild = () => {

  return (
    <>
      <div className={`${styles.cardList} flex-wrap`}>
        <AnalyticCardContainer title={ANALYTICS_CONTAINER_TITLE.CORE_METRICS}>
          <AnalyticCoreCards/>
        </AnalyticCardContainer>

        <AnalyticCardContainer title={ANALYTICS_CONTAINER_TITLE.SIZING}>
          <AnalyticSizingCards/>
        </AnalyticCardContainer>

        <AnalyticCardContainer title={ANALYTICS_CONTAINER_TITLE.TRY_ON}>
          <AnalyticTryOnCards/>
        </AnalyticCardContainer>
        
      </div>

    </>
  );
};
export default AnalyticsChild;
