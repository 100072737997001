
import { ChipProps } from '../../interface/interface';
import styles from './Chip.module.scss';


const Chip = ({ title, blue, blueLight, success, error, prefix }: ChipProps) => {
    return (<>
        <span className={`chipUi ${styles.chip}  ${blue ? styles.blue : ``} ${blueLight ? styles.blueLight : ``}
        ${success ? styles.success : ``}
         ${error ? styles.error : ``}
        `}>
         {prefix} {title}
        </span>

    </>)
}
export default Chip;