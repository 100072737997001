

const HelpIcon = () => {
    return (
        <>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<g clipPath="url(#clip0_6451_27665)">
<path d="M6.0626 6.0026C6.21934 5.55705 6.52871 5.18134 6.93591 4.94203C7.34311 4.70271 7.82187 4.61523 8.28739 4.69508C8.75291 4.77493 9.17515 5.01695 9.47932 5.37829C9.7835 5.73963 9.94997 6.19695 9.94927 6.66927C9.94927 8.0026 7.94927 8.66927 7.94927 8.66927M8.0026 11.3359H8.00927M14.6693 8.0026C14.6693 11.6845 11.6845 14.6693 8.0026 14.6693C4.32071 14.6693 1.33594 11.6845 1.33594 8.0026C1.33594 4.32071 4.32071 1.33594 8.0026 1.33594C11.6845 1.33594 14.6693 4.32071 14.6693 8.0026Z"
 stroke="currentColor" 
 strokeWidth="1.33333" 
 strokeLinecap="round" 
 strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_6451_27665">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
        </>
    );
};

export default HelpIcon;
