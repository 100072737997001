import React, {  useCallback, useEffect, useState } from "react";
import styles from "./MeasurementDefinition.module.scss";
import { HANDLE_GET_MEASUREMENT_GROUP_INDIVIDUAL, HANDLE_UPDATE_MEASUREMENT_DEF } from "store/measurement/action.types";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import CustomDialog from "component/CustomDialog";
import CustomiseMeasurement from "pages/measurementGroups/CustomiseMeasurement";
import LoadingScreen from "component/LoadingScreen";
import arrowLeft from "assets/images/arrow-left.svg";
import arrowRight from "assets/images/arrow-right.svg";
import MesurementDefinationCard from "component/measurementDefinationCard/MesurementDefinationCard";
import { ROUTES } from "lib/Constants";
import BackButton from "component/backButton/BackButton";
import { setCustomiseMeasurementUpload } from "store/measurement/reducer";

const MeasurementDefinition = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { individualMeasurement,customiseMeasurementUpload } = useSelector((state: RootState) => state.measurement);
	const [groupedMeasurements, setGroupedMeasurements] = useState<Record<string, any>[][]>([]);
	const [currentGroupIndex, setCurrentGroupIndex] = useState<number>(0);
	const [selectedGroupdata, setSelectedGroupdata] = useState<Record<string, any>>();
	const [open, setOpen] = useState(false);
  const [selectedIndex,setSelectedIndex] =useState<Record<string,any>>({})

	const updatedData = () => {
		const groups = individualMeasurement?.reduce((acc: Record<string, Record<string, any>[]>, measurement: Record<string, any>) => {
			if (measurement?.groupOrder in acc) {
				acc[measurement?.groupOrder].push(measurement);
			} else {
				acc[measurement?.groupOrder] = [measurement];
			}
			return acc;
		}, {});
		setGroupedMeasurements(Object.values(groups));
	};

	const handleGroup = useCallback(
		(action: string) => {
			const payload = { action };
      let name =selectedGroupdata?.measureDefKey
      setCurrentGroupIndex(currentGroupIndex + 1);
      if (action ==="skip") {
        name =groupedMeasurements?.[currentGroupIndex]?.[0]?.optionGroupName??groupedMeasurements?.[currentGroupIndex]?.[0]?.measureDefKey
      }else{
        setSelectedIndex({...selectedIndex,[currentGroupIndex]:selectedGroupdata}) 
      }
				dispatch({ type: HANDLE_UPDATE_MEASUREMENT_DEF, payload: { payload, name } });
        setSelectedGroupdata(undefined)
			if (currentGroupIndex >= groupedMeasurements?.length - 1) {
				navigate(ROUTES.MEASUREMENT_GROUPS);
			} 
		},
		[currentGroupIndex, groupedMeasurements,selectedGroupdata],
	);

	const handlePreviousGroup = () => {
		if (currentGroupIndex > 0) {
			setCurrentGroupIndex(currentGroupIndex - 1);
		}
	};

	const handleAdjustDefinition = (data: any) => {
		setSelectedGroupdata(data);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		
	};
  useEffect(()=>{
    if(customiseMeasurementUpload){
      handleClose()
      if (currentGroupIndex < groupedMeasurements.length - 1) {
        setCurrentGroupIndex(currentGroupIndex + 1);
      }
       dispatch(setCustomiseMeasurementUpload(false))
    }
   },[customiseMeasurementUpload])
	useEffect(() => {
		if (individualMeasurement?.length > 0) {
			updatedData();
		}
	}, [individualMeasurement]);

	useEffect(() => {
		dispatch({ type: HANDLE_GET_MEASUREMENT_GROUP_INDIVIDUAL, payload: id });
	}, [id]);
  
  useEffect(()=>{
    if (selectedIndex[currentGroupIndex]) {
      setSelectedGroupdata(selectedIndex[currentGroupIndex])
    }
  },[currentGroupIndex])

  return (
    <>
      {groupedMeasurements.length === 0 ? (
        <div className={`flex align-center justify-center h-full`}>
          {" "}
          <LoadingScreen height={false} />
        </div>
      ) : (
        <div className={`${styles.measurement} flex-wrap`}>
              <div className={`${styles.measurement__back}`}>
            <BackButton/>
            </div>
          <div className={`${styles.measurement__left} flex-column`}>
        
            <div className={`${styles.measurement__inner} flex align-center`}>
              <div className={`${styles.measurement__innerMain}`}>
                  <h2>Which measurement do you use?</h2>
                <div
                  className={`${styles.measurement__innerMainBox} flex-wrap`}
                >
                  {groupedMeasurements?.[currentGroupIndex]?.map((card: Record<string,number|string>,index:number) => (
                    <React.Fragment key={index}>
                   <MesurementDefinationCard data={card} index={index} selectedGroupdata={selectedGroupdata} setSelectedGroupdata={setSelectedGroupdata} handleAdjustDefinition={handleAdjustDefinition} />
                    </React.Fragment>
                  ))}
                   <div
                    className={`${styles.measurement__btn} flex justify-center w-full gap-10 align-center`}
                  >
                    <span
                      role={ currentGroupIndex === 0 ?
                        "none":"button"}
                      className={
                        currentGroupIndex === 0 ?
                        "disabled":""
                      }
                      onClick={handlePreviousGroup}
                    >
                      <img src={arrowLeft} alt="icon" />
                    </span>

                    <span
                         role={ (selectedGroupdata || selectedIndex?.[currentGroupIndex]!==undefined) ?
                            "button" :"none" }
                      className={
                        (selectedGroupdata || selectedIndex?.[currentGroupIndex]!==undefined) ?
                        "":"disabled"
                      }
                      onClick={()=>handleGroup("confirm")}
                    >
                      <img src={arrowRight} alt="icon" />
                    </span>
                    {id!=="1" && <span role="button"  onClick={()=>handleGroup("skip")}>Skip</span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.measurement__right}`}>
            <img
              src={
                groupedMeasurements[currentGroupIndex][0].measurementImageUrl
              }
              alt="measurements"
            />
          </div>
        </div>
      )}

			<CustomDialog className="measurement-modal disabled-overlay" open={open} handleShowModal={() => setOpen(false)}>
				<CustomiseMeasurement data={selectedGroupdata} handleClose={handleClose} />
			</CustomDialog>
		</>
	);
};

export default MeasurementDefinition;
