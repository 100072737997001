import { createSlice } from "@reduxjs/toolkit";

interface InitialStateInterface{
    listLoading:boolean;
    productList:Record<string, string>[];
    techpackProductSizing:Record<string, string>[];
    associatedStatus:string;
    productSizingDetails:Record<string,any>;
    error:boolean;
    loading:boolean;
    onboardingSummaryData:Record<string,number>
}
const InitialState:InitialStateInterface={
    listLoading:true,
    productList:[],
    techpackProductSizing:[],
    associatedStatus:"Pending",
    productSizingDetails:{},
    error:false,
    loading:true,
    onboardingSummaryData:{}
}
const productSlice= createSlice({
    name:"product",
    initialState:InitialState,
    reducers:{
        setProductList:(state,{payload})=>{
         state.productList= payload
        },
        setListLoading:(state,{payload})=>{
            state.listLoading= payload
        },
        setLoading:(state,{payload})=>{
            state.loading= payload
        },
        setTechpackProductSizing:(state,{payload})=>{
            state.techpackProductSizing= payload
        },
        setAssociatedStatus:(state,{payload})=>{
            state.associatedStatus=payload
        },
        setProductSizingDetails:(state,{payload})=>{
            state.productSizingDetails=payload
        },
        setError:(state,{payload})=>{
            state.error=payload
        },
        setOnboardingSummaryData:(state,{payload})=>{
            state.onboardingSummaryData=payload
        }
    }
})

export const {setProductList,setListLoading,setTechpackProductSizing,setAssociatedStatus,setProductSizingDetails,setError,setLoading,setOnboardingSummaryData} = productSlice.actions;
export default productSlice.reducer