import React, { useEffect } from "react";
import styles from "./TryOnSelection.module.scss";
import Upload from "atoms/upload/Upload";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useDispatch } from "react-redux";
import { setSampleImages } from "store/tryOn/reducer";
import LinearBuffer from "component/analyticTryOnCards/ProgressLoading";
import CustomButton from "atoms/customButton/CustomButton";
import closeIcon from "../../assets/images/x-close.svg";
import rocket from "../../assets/images/rocket-02.svg";
import { TRY_ON_FILE_UPLOAD_SHOW_TYPE } from "enum/enums";
import FileCard from "component/fileCard/FileCard";

interface TryOnImageUploadProps {
	handleFileOnChange: (files: File[]) => void;
	handleDeleteImage: (id: string) => void;
	showType: string;
	handleClose?: () => void;
	handleSubmit?:()=>void
}

const TryOnImageUpload = ({ handleFileOnChange, handleDeleteImage, showType, handleClose,handleSubmit }: TryOnImageUploadProps) => {
	const { tryOnUploadImage, fileUploading,submitLoading } = useSelector((state: RootState) => state.tryOn);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setSampleImages([]));
	}, []);
	return (
		<>
			{showType === TRY_ON_FILE_UPLOAD_SHOW_TYPE.NORMAL && (
				<>
					<div className="flex align-center justify-center ">
						{fileUploading ? (
							<div className={`${styles.loader} progress-bar large`}>
								<LinearBuffer value={0} />
							</div>
						) : (
							<Upload isDragAndDrop multiple handleFileOnChange={handleFileOnChange} subHeading={""} />
						)}
					</div>
					{
						<ul className={`${styles.selection__imagesUploaded} flex-wrap`}>
							{tryOnUploadImage?.map((el) => (
								<li key={el.id}>
									<figure role="button">
										<img src={el?.binaryFile} alt="images" />
										<span className={`${styles.close} flex-wrap`} role="button" onClick={() => handleDeleteImage(el?.id)}>
											<Close />
										</span>
									</figure>
								</li>
							))}
						</ul>
					}
				</>
			)}
			{showType === TRY_ON_FILE_UPLOAD_SHOW_TYPE.MODAL && (
				<div className={`${styles.batchUpload}`}>
					<span className={`${styles.batchUpload__close}`} role="button" onClick={handleClose}>
						<img src={closeIcon} alt="close" />
					</span>
					<div className={`${styles.batchUpload__header}`}>
						<img src={rocket} alt="icon" />
						<h3>Formatting Done</h3>
						<p>Now, add all your other tech packs. Swan will process them & email you when ready for review.</p>
					</div>
					{fileUploading ? (
						<div className={`${styles.batchUpload__UploadedBar} progress-bar large`}>
							<LinearBuffer value={0} />
						</div>
					) : (
						<div className={styles.batchUpload__Upload}>
							<Upload id="fileMultiInput" handleFileOnChange={handleFileOnChange} subHeading="PDF or .xlsx" multiple isDragAndDrop />
						</div>
					)}
					{
						tryOnUploadImage?.map((el)=><FileCard file={el} key={el.id} handleDelete={handleDeleteImage} />)
					}
					{tryOnUploadImage?.length > 0 && <div className={`${styles.batchUpload__Btn} flex justify-end`}>
						<CustomButton className="button rounded sm" buttonText="Done" handleFunc={handleSubmit && handleSubmit}  disabled={submitLoading} loading={submitLoading}/>
					</div>}
				</div>
			)}
		</>
	);
};

export default TryOnImageUpload;
