/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo } from "react";
import styles from "./FitModelOnboarding.module.scss";
import plus from "assets/images/plus.svg";
// import CustomButton from "atoms/customButton/CustomButton";
import { useDispatch } from "react-redux";
import { HANDLE_CREATE_FIT_MODEL, HANDLE_GET_FIT_MODEL_LIST } from "store/fitModel/action.types";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import FitModelTable from "component/fitModelTable/FitModelTable";
import LoadingScreen from "component/LoadingScreen";
import { useSearchParams } from "react-router-dom";
import { scrollToBottom } from "lib/utils";
import NoDataPlaceHolder from "component/NoDataPlaceHolder";
import { setEditToFitmodel } from "store/fitModel/reducer";
import BackButton from "component/backButton/BackButton";
import { ROUTES } from "lib/Constants";

const FitModelOnboarding = () => {
	const dispatch = useDispatch();
	const { data, loading, createLoading } = useSelector((state: RootState) => state.fitModel);
	const [searchParams, setSearchParams] = useSearchParams();
	const selectedId = useMemo(() => searchParams.get("selectedId"), [searchParams]);
	const appendTable = () => {
		const length = data.length;
		dispatch({ type: HANDLE_CREATE_FIT_MODEL, payload: `Fit Model ${length + 1}` });
		dispatch(setEditToFitmodel(""));
	};

	useEffect(() => {
		if (!data?.length) {
			dispatch({ type: HANDLE_GET_FIT_MODEL_LIST });
		}
		return ()=>{
			dispatch(setEditToFitmodel(""));
		}
	}, []);
	
	useEffect(()=>{
    if (selectedId) {
		setTimeout(()=>{
			scrollToBottom(selectedId)
			setSearchParams(params => {
				params.set("selectedId", "");
				return params;
			  })
		},1500)
	}
	},[selectedId])
        return(<>
			<div className={`${styles.fitModal} flex-column`}>
			<div className={styles.tryOn__back}>
            <BackButton route={ROUTES.SIZING_START}/>
            </div>
				{(loading || createLoading) && <div className={` ${loading && 'flex h-full align-center justify-center'}`}><LoadingScreen height={loading && false} customClass={createLoading ? "fixedLoader" : ``} /></div>}
                {!loading && <> 
				<div className={` flex justify-end`}>
            
            <span  onClick={appendTable}  className={`${styles.fitModal__add} flex align-center`} role="button">
                <img src={plus} alt="icon" /> Add another
            </span>
        </div>
				<div id="bottom-div" className={`${styles.fitModal__info}`}>
					{data?.length>0 ? data?.map((element) => {
						return (
							<div className={`${styles.fitModal__infoTable}`} id={element.id} key={element.id}>
								<FitModelTable  data={element} />
							</div>
						);
					}):<div className="h-full flex justify-center align-center"><NoDataPlaceHolder message='No fit model found. Click on "Add another" to add. ' showOnlyMessage /></div>}
				</div>

				{/* <div className={`${styles.fitModal__btn} flex justify-end`}>
					<CustomButton buttonText="Complete" className="rounded semibold" />
				</div> */}
				</> }
			</div>
			
		</>
	);
};

export default FitModelOnboarding;
