import HomeMenu from "component/homeMenu/HomeMenu";
import HomeOnboarding from "component/homeOnboarding/HomeOnboarding";
// import HomeResource from "component/homeResource/HomeResource";
import React, { useEffect } from "react";
import styles from "./Home.module.scss";
import { useDispatch } from "react-redux";
import { HANDLE_ONBOARDING_SUMMARY } from "store/products/action.types";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import LoadingScreen from "component/LoadingScreen";

const Home = () => {
	const dispatch = useDispatch();
	const { listLoading } = useSelector((state: RootState) => state.product);
	useEffect(() => {
		dispatch({ type: HANDLE_ONBOARDING_SUMMARY });
	}, []);
	return (
		<>
			<div className={`${styles.home}`}>
				{listLoading ? (
					<div className="flex align-center justify-center h-full">
						<LoadingScreen height={false} />
					</div>
				) : (
					<>
						<div className={`${styles.home__wrap}`}>
							<h2>Onboarding</h2>
							<div className={`${styles.home__inner}`}>
								<HomeOnboarding />
							</div>
							<h2>Menu</h2>
							<div className={`${styles.home__inner}`}>
								<HomeMenu />
							</div>
							{/* <h2>Resources</h2>
							<div className={`${styles.home__inner}`}>
								<HomeResource />
							</div> */}
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default Home;
