import CustomButton from "atoms/customButton/CustomButton";
import CustomRadio from "atoms/customRadio/CustomRadio";
import Input from "atoms/input/Input";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styles from "./SizingOnboardingChild.module.scss";
import parsingIcon from "assets/images/parsing.svg";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import SizingOnboardingTable from "./SizingOnboardingTable";
import { useDispatch } from "react-redux";
import { HANDLE_SUBMIT_FEEDBACK } from "store/techpack/action.types";
import SizingBatchUpload from "./SizingBatchUpload";
import { setUploadedFiles } from "store/techpack/reducer";

const SizingOnboardingSecondLeftChild = () => {
	const {
		register,
		formState: { errors },
		setError,
		handleSubmit,
	} = useForm<{ type: string }>({
		defaultValues: {
			type: "",
		},
	});
	const dispatch = useDispatch();
	const { techPackParsedData, latestTechpackFieldId, submitLoading, isFeedbackSubmited } = useSelector((state: RootState) => state.techpack);
	const [open, setOpen] = useState<boolean>(false);
	const [values, setValues] = useState("correct");
	const onChange = (e: any) => {
		const { name } = e.target;
		setValues(name);
	};
	const onSubmit: SubmitHandler<{ type: string }> = (data) => {
		const { type } = data;
		if (!type.trim()) {
			setError("type", { type: "custom", message: "Please enter a valid feedback." });
		} else {
			const payload = {
				techpackFileId: latestTechpackFieldId,
				feedback: type,
				isCorrect: values === "correct"
			};
			dispatch({ type: HANDLE_SUBMIT_FEEDBACK, payload });
		}
	};
useEffect(()=>{
	return ()=>{
		dispatch(setUploadedFiles([]))
	}
},[])
	return (
		<div className={`${styles.sizeOnboard} flex-column`}>
			<div className={`${styles.sizeOnboard__scroll}`}>
				{!isFeedbackSubmited && <div className={`${styles.sizeOnboard__parsing}`}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className={`${styles.sizeOnboard__parsingWrapper}`}>
							<div className={`${styles.sizeOnboard__parsingHeader}`}>
								<img src={parsingIcon} alt="parsing" />
								<h2>Parsing details</h2>
								<p>Please review and re-run the parsing if necessary. You need only do this once, all other files will be auto parsed.</p>
							</div>
							<div>
								<div className={`${styles.sizeOnboard__parsingRadio} flex-wrap justify-around`}>
									<CustomRadio simple id="1" title="This is correct" name="correct" value={values==="correct"} onChange={onChange}/>
									<CustomRadio simple id="2" title="This is wrong" name="wrong" value={values==="wrong"} onChange={onChange} />
								</div>
							</div>
							<Input
								type="textarea"
								placeholder="Describe why this parsing is not what you expected"
								register={register}
								inputName="type"
								errorMessage={errors.type ? (errors.type.message ? errors.type.message : "This Field is required") : ""}
							/>
							<div className="flex justify-end">
								<CustomButton type="submit" className="rounded sm" buttonText="Submit feedback" disabled={submitLoading} loading={submitLoading} />
							</div>
						</div>
					</form>
				</div>}
				<div className={`${styles.sizeOnboard__tables}`}>
					{techPackParsedData?.map((el) => {
						return (
							<React.Fragment key={el?.parsedTableKey}>
								<SizingOnboardingTable data={el} />
							</React.Fragment>
						);
					})}
				</div>
			</div>
			<div className={`${styles.sizeOnboard__leftBtn} flex justify-end`}>
				<CustomButton type="button" className="rounded sm" buttonText="Approve" handleFunc={() => setOpen(true)} />
			</div>

			<SizingBatchUpload open={open} handleClose={() => setOpen(false)} />
		</div>
	);
};

export default SizingOnboardingSecondLeftChild;
