import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import DateRangePicker from "atoms/dateRangePicker/DateRangePicker";
import { DATE_PICKER_VALUE } from "enum/enums";
import { TODAY_DATE } from "lib/Constants";
import React, { useState } from "react";

interface CalenderModalProps {
  handleShowModal: () => void;
  setDatePickerValue: (a: DATE_PICKER_VALUE) => void;
  fetchAllList: (a: boolean) => Promise<void>;
  endDate: Date;
  setEndDate: (a: Date) => void;
  startDate: Date;
  setStartDate: (a: Date) => void;
}
const sevenDaysAgo = new Date(TODAY_DATE);
sevenDaysAgo.setDate(TODAY_DATE.getDate() - 7);
const CalenderModal = ({ handleShowModal, setDatePickerValue, fetchAllList, endDate, setEndDate, startDate, setStartDate }: CalenderModalProps) => {
  const [error, setError] = useState<boolean>(false);

  const handleApply = () => {
    if (startDate > sevenDaysAgo) {
      setError(true);
      return;
    }

    handleShowModal();
    setDatePickerValue(DATE_PICKER_VALUE.CUSTOM);
    fetchAllList(true);
  };

  return (
    <>
      <DialogTitle id="responsive-dialog-title">Select date range </DialogTitle>

      <DialogContent>
        <DialogContentText className="flex-wrap">
          <DateRangePicker setStartDate={setStartDate} setEndDate={setEndDate} endDate={endDate} startDate={startDate} setError={setError} />
          {error && <div className="calender-error">Date range should be minimum of 1 week.</div>}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            handleShowModal();
            setStartDate(TODAY_DATE);
            setEndDate(TODAY_DATE);
          }}
        >
          Cancel
        </Button>
        <Button onClick={handleApply} autoFocus className="button-style" disabled={error}>
          Apply
        </Button>
      </DialogActions>
    </>
  );
};

export default CalenderModal;
