import React, { useMemo, useState } from 'react'
import styles from "./SizingOnboardingChild.module.scss"
import Note from 'component/note/Note'
import { SubmitHandler, useForm } from 'react-hook-form';
import { TECHPACK_FILE_TYPE } from 'lib/Constants';
import { useDispatch } from 'react-redux';
import { HANDLE_TECHPACK_PARSE } from 'store/techpack/action.types';
import CustomMultiSelect from 'atoms/customMultiSelect/CustomMultiSelect';
// import CustomRadio from 'atoms/customRadio/CustomRadio';
import Input from 'atoms/input/Input';
import CustomButton from 'atoms/customButton/CustomButton';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

interface FormData {
    style: number;
    products: number;
}
const getMultiSelectData = (val: number) => {
	if (!val) {
		return [""];
	}
	return new Array(val).fill(0).map((el, index) => index + 1);
};
const SizingOnboardingLeftFirstChild = ({totalContent}:{totalContent:any}) => {
    const [value, setValue] = useState<any[]>();
    const [selectError,setSelectError] = useState<string>("");
	const { latestTechpackFieldId, downloadUrl,uploadedFileType,loading } = useSelector((state: RootState) => state.techpack);
	const dispatch = useDispatch();


    const multiSelectData = useMemo(() => {
		let data:string[] | number[]=[]
		if (uploadedFileType === TECHPACK_FILE_TYPE.pdf) {
			data= getMultiSelectData(totalContent);
		} else {
			data = totalContent;
		}
		setValue(data?.[0]?[data?.[0]]:[])
		return data
	}, [uploadedFileType, totalContent]);
    const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm<FormData>({
		defaultValues: {
			style: 0,
			products: 0,
		},
	});

    const handleValue = (val: any[]) => {
		setValue(val);
		setSelectError("")
	};
    const onSubmit: SubmitHandler<FormData> = (data) => {
		const { products, style } = data;
		if (products <= 0 || (products - Math.floor(products) !== 0)) {
			setError("products", { type: "custom", message: "Please enter a valid product count" });
		} else if (style <= 0 || (style - Math.floor(style) !== 0)) {
			setError("style", { type: "custom", message: "Please enter a valid style/fit variation" });
		} else if (value?.length === 0 || !value?.[0]) {
			setSelectError(`Please select valid ${uploadedFileType === TECHPACK_FILE_TYPE.pdf ? "page" : "sheet"}`)
		} else {
			const payload = {
				techpackFileId: latestTechpackFieldId,
				parserConfig: {
					fileType: uploadedFileType,
					sheetNames: uploadedFileType === TECHPACK_FILE_TYPE.pdf ? null : value,
					measurementPages: uploadedFileType === TECHPACK_FILE_TYPE.pdf ? value : null,
					numberOfProducts: products,
				},
			};
			dispatch({type:HANDLE_TECHPACK_PARSE,payload})
		}
	};

  return (
    <div className={`${styles.sizeOnboard} flex-column`}>
         <form className="flex-column" onSubmit={handleSubmit(onSubmit)}>
         <div className={`${styles.sizeOnboard__scroll}`}>
    <div className={`${styles.sizeOnboard__note}`}>
        <Note title="Share the layout of one tech pack and we’ll do the rest. " />
    </div>
   
        <>
            <div className={`${styles.sizeOnboard__form}`}>
                {/* <div className={`${styles.sizeOnboard__formGroup}`}>
                    <h5>Garment measurements are taken:</h5>
                    <div className={`${styles.sizeOnboard__formGroupList}`}>
                        <CustomRadio value={true} id="2" name="2" simple title="Laid flat" />
                    </div>
                    <div className={`${styles.sizeOnboard__formGroupList}`}>
                        <CustomRadio id="3" value={false} name="3" simple title="Circumference" />
                    </div>
                </div> */}
                <div className={`${styles.sizeOnboard__formGroup}`}>
                    <h5>Garment measurements are found on page/sheet named:  </h5>
                    <div className={`${styles.sizeOnboard__formGroupList}`}>
                        <CustomMultiSelect
                            value={value || []}
                            handleValue={handleValue}
                            data={multiSelectData || []}
                            selectError={selectError}
                        />
                    </div>
                </div>
                <div className={`${styles.sizeOnboard__formGroup}`}>
                    <h5>Per tech pack there are:</h5>
                    <div className={`${styles.sizeOnboard__formGroupList}`}>
                        <Input
                            type="number"
                            label="Product"
                            register={register}
                            inputName="products"
                            errorMessage={errors.products ? (errors.products.message ? errors.products.message : "This Field is required") : ""}
                        />
                    </div>
                    <div className={`${styles.sizeOnboard__formGroupList}`}>
                        <Input
                            placeholder="Enter style/fit variation"
                            type="number"
                            label="Style/fit variation"
                            register={register}
                            inputName="style"
                            errorMessage={errors.style ? (errors.style.message ? errors.style.message : "This Field is required") : ""}
                        />
                    </div>
                </div>
            </div>
            
         
        </>
  
    </div>
    <div className={`${styles.sizeOnboard__leftBtn} flex justify-end`}>
                <CustomButton type="submit" className="rounded sm" buttonText="Approve" disabled={!downloadUrl || loading} loading={loading} />
            </div>
            </form>
</div>
  )
}

export default SizingOnboardingLeftFirstChild