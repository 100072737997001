import {Checkbox, TableCell, TableHead, TableRow } from "@mui/material";
import CustomSelect from "atoms/customSelect/CustomSelect";
import { MEASUREMENT_CONVERSION_TYPE } from "enum/enums";
import { SELECT_TYPE } from "lib/Constants";

import React from "react";

interface EnhancedHeader {
	columns: any[];
	requiredCheckBox?: boolean;
	selectData?:Record<string,string|number>[];
	value?:string;
    icon?:string;
	setMeasurementUnit?:(a:MEASUREMENT_CONVERSION_TYPE|string)=>void
	handleSelectAllClick:(event: React.ChangeEvent<HTMLInputElement>) => void;
	numSelected: number;
	rowCount:number;
	baseSizeFortechpack?:string
}

const EnhancedHeader = ({ columns,selectData, value,setMeasurementUnit,requiredCheckBox=false,rowCount,numSelected,handleSelectAllClick,baseSizeFortechpack="" }: EnhancedHeader) => {
	
	return (
		<TableHead>
			<TableRow>
				{requiredCheckBox && <TableCell className="checkBoxHeader">
                    <span className=" customCheckBox flex">
					<Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={handleSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
             </span>
				</TableCell>}
				{columns.map((el) => {
					return el?.showSelect ? (
						<TableCell align={el?.center ? "center" : "left"} key={el.id}>
							<CustomSelect value={value} type={SELECT_TYPE.NORMAL} data={selectData} handleChange={(a)=>setMeasurementUnit?.(a)}/>
						</TableCell>
					) : (
						<TableCell className={el?.customClass? 'checkboxTd' : ''} align={el?.center ? "center" : "left"} key={el.id}>
                           {el?.customClass? <>
                            <span className=" customCheckBox flex">
                                                    <label>
                                                        <input type="checkbox" disabled />
                                                        <span></span>
                                                    </label>
                                                </span>
                           </>: <>
                            {el?.icon ? <>
                                <span className="head-with-icon flex"><img src={el?.icon} />{el.name}</span>
                            </> : <>
                            {el.name==="ease"? `${el.name}(${baseSizeFortechpack})`:el.name}
                            </>}
                           </>}
                            
                             
							
						</TableCell>
					);
				})}
			</TableRow>
		</TableHead>
	);
};

export default EnhancedHeader;
