import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import styles from "./DateFilter.module.scss";
// import calender from "../../assets/images/custom-date.svg";
import { DATE_PICKER_VALUE } from "enum/enums";

interface DateFilterProps {
  setDatePickerValue: (a: DATE_PICKER_VALUE) => void;
  datePickerValue: DATE_PICKER_VALUE;
  handleShowModal?: () => void;
  startDate: string;
  endDate: string;
  disabled?:boolean
}
const DateFilter = ({ setDatePickerValue, datePickerValue, startDate, endDate,disabled }: DateFilterProps) => {
  return (
    <div className={`flex align-center ${styles.dataFilter}`}>
      {
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            disabled={disabled}
            onChange={(e: any) => {
              setDatePickerValue(e.target.value);
            }}
            value={datePickerValue !== DATE_PICKER_VALUE.CUSTOM ? datePickerValue : `${startDate}-${endDate}`}
          >
            <MenuItem className="hidden" value={`${startDate}-${endDate}`}>
              {startDate} - {endDate}
            </MenuItem>
            {/* <MenuItem value={DATE_PICKER_VALUE.TODAY}>Today</MenuItem> */}
            {/* <MenuItem value={DATE_PICKER_VALUE.TWENTY_FOUR_HOURS}>Last 24 hours</MenuItem> */}
            <MenuItem value={DATE_PICKER_VALUE.SEVEN_DAYS}>Last 7 days</MenuItem>
            <MenuItem value={DATE_PICKER_VALUE.FOURTEEN_DAYS}>Last 14 days</MenuItem>
            <MenuItem value={DATE_PICKER_VALUE.THIRTY_DAYS}>Last 30 Days</MenuItem>
            <MenuItem value={DATE_PICKER_VALUE.THIS_MONTH}>This Month</MenuItem>
            <MenuItem value={DATE_PICKER_VALUE.THIS_QUATER}>This Quater</MenuItem>
          </Select>
        </FormControl>
      }{" "}
      {/* condition for datePickerValue === DATE_PICKER_VALUE.CUSTOM */}
      {/* <CustomButton
        buttonImg={calender}
        className={`button ${datePickerValue === DATE_PICKER_VALUE.CUSTOM && `active`}`}
        buttonText="Custom"
        handleFunc={handleShowModal}
      ></CustomButton> */}
    </div>
  );
};

export default DateFilter;
