import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import EnhancedHeader from "./EnhancedHeader";
import CustomSelect from "atoms/customSelect/CustomSelect";
import { camelCaseToWords } from "lib/utils";
import { MUI_TABLE_TYPE, SELECT_TYPE} from "lib/Constants";
import { selectData } from "./tableData";
import { MEASUREMENT_CONVERSION_TYPE } from "enum/enums";
import openIcon from "assets/images/open-icon.svg";
import helpIcon from "../../assets/images/info-icon.svg";
import TryOnTableRows from "component/tryonTableRows/TryOnTableRows";
import CustomListTableRows from "component/customListTableRows/CustomListTableRows";
interface CustomTableMui {
	columns: { name: string; id: string; center: boolean }[];
	rows: any[];
	data?: any;

	borderTopHide?: boolean;
	rowArray?: {
		definition?: string;
		name: string;
		id: string;
	}[];
	handleChange?: (e: React.ChangeEvent<HTMLInputElement>, size?: string, index?: number, type?: string) => void;
	handleSelectChange?: (e: string, b: number, c: boolean) => void;
	type: string;
	handleMeasurementUnit?: (a: MEASUREMENT_CONVERSION_TYPE | string) => void;
	measurementUnit?: string | MEASUREMENT_CONVERSION_TYPE;
	firstColSticky?: boolean;
	secondColSticky?: boolean;
	handleMeasurementDetails?: (value: string, type?: string, id?: string) => void;
	requiredCheckBox?: boolean;
	setSelected?: (a: string[]) => void;
	selected?: string[];
	customRowCount?: number;
	easeData?: Record<number | string, { [key: string]: string | number }>;
	baseSizeFortechpack?: string;
	handleSelectedCustom?: (a: any) => void;
	measurementEditable?: boolean;
	handleModal?: (id: string) => void;
}

function CustomTableMui({
	columns,
	rows,
	data,
	handleChange,
	handleSelectChange,
	rowArray,
	type,
	handleMeasurementUnit,
	measurementUnit,
	firstColSticky,
	secondColSticky,
	handleMeasurementDetails,
	requiredCheckBox,
	borderTopHide,
	setSelected,
	selected = [],
	customRowCount = 0,
	easeData = {},
	baseSizeFortechpack = "",
	handleSelectedCustom,
	measurementEditable = false,
	handleModal,
}: CustomTableMui) {
	const [showBlank, setShowBlank] = React.useState<boolean>(false);
	const selectedOptions = React.useMemo(() => rows.filter((el) => el?.selected), [rows]);
	const [lastEditedField, setLastEditedField] = React.useState<{ row: number; column: number } | null>(null);
	const inputRefs = React.useRef<HTMLInputElement[][]>([]);

	const handleFieldChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, columnName: string, rowIndex: number, type: string) => {
			handleChange?.(e, columnName, rowIndex, type);
			const columnIndex = columns.findIndex((col) => col.name === columnName);
			setLastEditedField({ row: rowIndex, column: columnIndex });
		},
		[lastEditedField, handleChange, columns, rows],
	);

	const handleSelectAllClick = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (event.target.checked) {
				const newSelecteds = rows?.reduce((acc: string[], el) => {
					acc.push(el?.handle);
					return acc;
				}, []);
				setSelected?.(newSelecteds);
				return;
			}
			setSelected?.([]);
		},
		[rows, selectData],
	);

	const handleClick = React.useCallback(
		(event: React.MouseEvent<unknown>, id: string) => {
			const selectedIndex = selected?.indexOf(id);
			let newSelected: string[] = [];
			if (selectedIndex === -1) {
				newSelected = newSelected.concat(selected, id);
			} else if (selectedIndex === 0) {
				newSelected = newSelected.concat(selected.slice(1));
			} else if (selectedIndex === selected.length - 1) {
				newSelected = newSelected.concat(selected.slice(0, -1));
			} else if (selectedIndex > 0) {
				newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
			}
			setSelected?.([...newSelected]);
		},
		[selected],
	);

	React.useEffect(() => {
		if (lastEditedField && type === MUI_TABLE_TYPE.TECHPACK) {
			const { row, column } = lastEditedField;
			const inputElement = inputRefs.current[row]?.[column];
			if (inputElement) {
				inputElement.focus();
			}
		}
	}, [lastEditedField, data]);

	return (
		<div className={`custom-table ${borderTopHide ? `borderTopHide` : ``} ${firstColSticky ? `firstColSticky` : ``} ${secondColSticky ? `secondColSticky` : ``}`}>
			<div className={`custom-table__inner`}>
				<Table>
					<EnhancedHeader
						columns={columns}
						requiredCheckBox={requiredCheckBox}
						selectData={selectData}
						value={measurementUnit}
						setMeasurementUnit={handleMeasurementUnit}
						handleSelectAllClick={handleSelectAllClick}
						numSelected={selected.length}
						rowCount={customRowCount || rows?.length}
						baseSizeFortechpack={baseSizeFortechpack}
					/>
					{type === MUI_TABLE_TYPE.FITMODEL && (
						<TableBody>
							{rowArray?.map((row, index: number) => (
								<TableRow key={row.id}>
									<TableCell component="th" scope="row">
										<CustomSelect
											bgGray={true}
											disabled={selectedOptions.length === 25}
											data={rows}
											value={row.name}
											handleChange={(e: string) => handleSelectChange?.(e, index, false)}
											type={SELECT_TYPE.FITMODEL}
										/>
									</TableCell>
									<TableCell>
										<div className="table-input">
											<input min={0} type="number" name={row.name ?? ""} value={data?.[row.name] ?? ""} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange?.(e)} />
										</div>
									</TableCell>
								</TableRow>
							))}
							{showBlank && (
								<TableRow>
									<TableCell>
										<CustomSelect
											bgGray={true}
											disabled={selectedOptions.length === 25}
											data={[
												...rows,
												{
													name: "Select",
													id: "cdd9d799-1e2d-4683-b4f1-75acf619d210",
													selected: true,
												},
											]}
											value={"Select"}
											handleChange={(e: string) => handleSelectChange?.(e, 0, true)}
											setShowBlank={setShowBlank}
											type={SELECT_TYPE.FITMODEL}
										/>
									</TableCell>
									<TableCell></TableCell>
								</TableRow>
							)}
							{selectedOptions.length < 25 && !showBlank && (
								<TableRow>
									<TableCell colSpan={2}>
										<span className="table-link" role="button" onClick={() => setShowBlank(true)}>
											+ New
										</span>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					)}
					{type === MUI_TABLE_TYPE.TECHPACK && (
						<TableBody>
							{rowArray?.map((row: { name: string; id: string }, ind: number) => {
								const easeKey: string | number = Object.keys(easeData).length > 0 ? Object.keys(easeData)?.[0] : "";
								return (
									<TableRow key={row?.id}>
										<TableCell>{camelCaseToWords(row?.name)}</TableCell>
										{columns?.map((column: { name: string; id: string }, index: number) => {
											if (index === 0) {
												return null;
											}
											return column.name !== "ease" ? (
												<TableCell key={column.id}>
													<div className="table-input">
														<input
															ref={(el: any) => {
																if (!inputRefs.current[ind]) {
																	inputRefs.current[ind] = [];
																}
																inputRefs.current[ind][index] = el;
															}}
															min={0}
															type="number"
															name={row.name ?? ""}
															value={data?.[column.name]?.[row.name] || ""}
															onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(e, column.name, ind, "sizes")}
														/>
													</div>
												</TableCell>
											) : (
												<TableCell>
													<div className="table-input">
														<input
															ref={(el: any) => {
																if (!inputRefs.current[ind]) {
																	inputRefs.current[ind] = [];
																}
																inputRefs.current[ind][1] = el;
															}}
															min={0}
															type="number"
															name={row.name ?? ""}
															value={easeData?.[easeKey]?.[row?.name] || ""}
															onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(e, "ease", ind, "ease")}
														/>
													</div>
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
							{showBlank && (
								<TableRow>
									<TableCell>
										<CustomSelect
											bgGray={true}
											disabled={selectedOptions.length === 25}
											data={[
												...rows,
												{
													name: "Select",
													id: "cdd9d799-1e2d-4683-b4f1-75acf619d210",
													selected: true,
												},
											]}
											value={"Select"}
											handleChange={(e: string) => handleSelectChange?.(e, 0, true)}
											setShowBlank={setShowBlank}
											type={SELECT_TYPE.FITMODEL}
										/>
									</TableCell>
									{columns?.map((el, index: number) => index !== 0 && <TableCell key={el.id}></TableCell>)}
								</TableRow>
							)}
							{selectedOptions.length < 25 && !showBlank && (
								<TableRow>
									<TableCell colSpan={columns.length}>
										<span className="table-link" role="button" onClick={() => setShowBlank(true)}>
											+ New
										</span>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					)}
					{type === MUI_TABLE_TYPE.MEASUREMENT && (
						<>
							<TableBody>
								{rows?.map(
									(row) =>
										row?.name && (
											<TableRow key={row?.id}>
												<TableCell>
													<div className={`flex justify-between align-start productCell`}>
														<span> {row?.name}</span>
														<span role="button" className={`openButton`} onClick={() => handleMeasurementDetails?.(row.value)}>
															<img src={openIcon} alt="icon" /> OPEN
														</span>
													</div>
												</TableCell>
												<TableCell>{row?.definition ?? ""}</TableCell>
											</TableRow>
										),
								)}
							</TableBody>
						</>
					)}
					{type === MUI_TABLE_TYPE.TRY_ON && (
						<TryOnTableRows rows={rows} selected={selected} requiredCheckBox={requiredCheckBox} handleMeasurementDetails={handleMeasurementDetails} handleClick={handleClick} />
					)}
					{type === MUI_TABLE_TYPE.CUSTOM_LIST && (
						<CustomListTableRows rows={rows} handleSelectedCustom={handleSelectedCustom} />
					)}
					{type === MUI_TABLE_TYPE.CUSTOM_MEASUREMENT_LIST && (
						<TableBody>
							{rows?.map((row) => {
								const labelId = `enhanced-table-checkbox-${row?.id}`;
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={row.id} sx={{ cursor: "pointer" }}>
										<TableCell component="th" id={labelId} scope="row" padding="none">
											<div className="flex align-center ">
												<h5 className="flex align-center gap-10 custom-name">
													{camelCaseToWords(row?.name ?? "")}
													<span role="button" className="helpIcon" onClick={() => handleModal?.(row?.id)}>
														<img src={helpIcon} alt="icon" />
													</span>
												</h5>
											</div>
										</TableCell>
										<TableCell className="inputTd">
											<input
												className={`${measurementEditable ? `show-border` : ``}`}
												type="number"
												value={row?.value ?? 0}
												readOnly={!measurementEditable}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
													handleChange?.(e, row?.name);
												}}
											/>
											cm
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					)}
				</Table>
			</div>
		</div>
	);
}
export default React.memo(CustomTableMui);
