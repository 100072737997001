import { createSlice } from "@reduxjs/toolkit";

interface InitialStateInterface{
    groupListLoading:boolean;
    groupList:Record<string, string>[];
}
const InitialState:InitialStateInterface={
    groupListLoading:false,
    groupList:[],
}
const groupSlice= createSlice({
    name:"group",
    initialState:InitialState,
    reducers:{
        setGroupList:(state,{payload})=>{
         state.groupList= payload
        },
        setGroupListLoading:(state,{payload})=>{
            state.groupListLoading= payload
        },
        
    }
})

export const {setGroupList,setGroupListLoading} = groupSlice.actions;
export default groupSlice.reducer