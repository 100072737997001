import { COMBINE_EVENTS_EQUATION, Metrics } from "enum/enums";
import React, { useContext, useMemo } from "react";
import { CombineEvents, formatTime, getEventCountSum, kpiCalculation } from "lib/utils";
import styles from "./AnalyticTryOnCards.module.scss";
import { AnalyticsPropsContextInterface } from "interface/interface";
import AnalyticsCard from "atoms/analyticsCard/AnalyticsCard";
import AnalyticsPropsContext from "context/AnalyticsPropsContext";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const AnalyticTryOnCards = () => {
	const { datePickerValue, tryOnCompletionTimeData, tryOnSuccessData, tryOnShareData, tryOnFailedData, ctaClickData, tryOnStartedData } = useContext(
		AnalyticsPropsContext,
	) as AnalyticsPropsContextInterface;
	const { isInternalUser } = useSelector((state: RootState) => state.auth);
	const TRY_ON_INTERACTION_TIME_DATA = useMemo(() => {
		let val: any = kpiCalculation(getEventCountSum(tryOnCompletionTimeData), getEventCountSum(tryOnSuccessData));
		val = val ? formatTime(val) : val;
		return val;
	}, [tryOnCompletionTimeData, tryOnSuccessData]);

	const TRY_ON_SUCCESS_PERCENTAGE = useMemo(() => {
		{
			return kpiCalculation(getEventCountSum(tryOnSuccessData), getEventCountSum(tryOnSuccessData) + getEventCountSum(tryOnFailedData), true) || 0;
		}
	}, [tryOnFailedData, tryOnSuccessData]);
  
   
	return (
		<div className={`${styles.cardList} flex-wrap`}>
			<div className={`cardBox`}>
				<AnalyticsCard
					title={Metrics.TRY_ONS}
					data={CombineEvents(tryOnSuccessData, tryOnFailedData, COMBINE_EVENTS_EQUATION.ADD)}
					datePickerValue={datePickerValue}
					showGraph
					value={getEventCountSum(tryOnSuccessData) + getEventCountSum(tryOnFailedData) || 0}
					InfoIcon
					InfoIconTitle=" Total number of Try-Ons generated."
				/>
			</div>
			<div className={`cardBox`}>
				<AnalyticsCard
					title={Metrics.TRY_ON_INTERACTION_TIME}
					value={TRY_ON_INTERACTION_TIME_DATA || 0}
					data={tryOnCompletionTimeData}
					datePickerValue={datePickerValue}
					type={Metrics.TRY_ON_INTERACTION_TIME}
					showGraph
					InfoIcon
					InfoIconTitle="Average time users spend interacting with Try-On."
				/>
			</div>
			<div className={`cardBox`}>
				<AnalyticsCard
					title={Metrics.TRY_ON_SUCCESS}
					data={tryOnSuccessData}
					datePickerValue={datePickerValue}
					showGraph
					dataTwo={tryOnFailedData}
					value={TRY_ON_SUCCESS_PERCENTAGE}
					InfoIcon
					InfoIconTitle="Percentage of successful Try-On generations."
				/>
			</div>
			<div className={`cardBox`}>
				<AnalyticsCard
					title={Metrics.SHARING_RATE_OF_TRY_ON}
					data={CombineEvents(tryOnShareData, tryOnSuccessData, COMBINE_EVENTS_EQUATION.DIVIDE)}
					datePickerValue={datePickerValue}
					showGraph
					value={kpiCalculation(getEventCountSum(tryOnShareData), getEventCountSum(tryOnSuccessData), true)}
					InfoIcon
					InfoIconTitle="Percentage of users who share their Try-Ons."
				/>
			</div>
			<div className={`cardBox`}>
				<AnalyticsCard
					title={Metrics.TRY_ON_COMPLETION_RATE}
					data={CombineEvents(tryOnSuccessData, ctaClickData, COMBINE_EVENTS_EQUATION.DIVIDE)}
					datePickerValue={datePickerValue}
					showGraph
					value={kpiCalculation(getEventCountSum(tryOnSuccessData), getEventCountSum(ctaClickData), true)}
					InfoIcon
					InfoIconTitle="The percentage of try-on sessions successfully completed."
				/>
			</div>
			{isInternalUser && (
				<>
					<div className={`cardBox`}>
						<AnalyticsCard
							title={Metrics.AVERAGE_TIME_SPENT_PER_TRY_ON +" (SWAN only)"}
							data={CombineEvents(
								tryOnCompletionTimeData,
								CombineEvents(tryOnFailedData, tryOnSuccessData, COMBINE_EVENTS_EQUATION.ADD),
								COMBINE_EVENTS_EQUATION.DIVIDE,
							)}
							datePickerValue={datePickerValue}
							showGraph
							type={Metrics.AVERAGE_TIME_SPENT_PER_TRY_ON}
							value={kpiCalculation(
								getEventCountSum(tryOnCompletionTimeData),
								getEventCountSum(tryOnFailedData) + getEventCountSum(tryOnSuccessData)
							)?formatTime(+kpiCalculation(
								getEventCountSum(tryOnCompletionTimeData),
								getEventCountSum(tryOnFailedData) + getEventCountSum(tryOnSuccessData)
							)):0}
						/>
					</div>

					<div className={`cardBox`}>
						<AnalyticsCard
							title={Metrics.TRY_ON_SUCCESS_COMPLETION_RATE +" (SWAN only)"}
							data={CombineEvents(tryOnSuccessData, tryOnStartedData, COMBINE_EVENTS_EQUATION.DIVIDE)}
							datePickerValue={datePickerValue}
							value={kpiCalculation(getEventCountSum(tryOnSuccessData), getEventCountSum(tryOnStartedData), true) || 0}
							showGraph
						/>
					</div>
					<div className={`cardBox`}>
						<AnalyticsCard
							title={Metrics.TRY_ON_JOURNEY_COMPLETION_RATE +" (SWAN only)"}
							data={CombineEvents(
								CombineEvents(tryOnSuccessData, tryOnFailedData, COMBINE_EVENTS_EQUATION.ADD),
								tryOnStartedData,
								COMBINE_EVENTS_EQUATION.DIVIDE,
							)}
							datePickerValue={datePickerValue}
							value={
								kpiCalculation(getEventCountSum(tryOnSuccessData) + getEventCountSum(tryOnFailedData), getEventCountSum(tryOnStartedData), true) || 0
							}
							showGraph
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default AnalyticTryOnCards;
