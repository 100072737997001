import { TOKEN_DATA_TYPE } from "enum/enums";
import UseApiService from "./apiService";
import { API_END_POINTS } from "lib/Constants";


class MeasurementService {
	getMeasurementGroupList(): Promise<any> {
		const body = {
			url: API_END_POINTS.MEASUREMENT.getgroupList,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
		};
		return UseApiService().get(body);
	}	

	getIndividualMeasurementGroup(id: number): Promise<any> {
		const body = {
			url: `${API_END_POINTS.MEASUREMENT.getIndividualMeasurementGroup}/${id}`,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
		};
		return UseApiService().get(body);
	}

	updateMeasurement(data:Record<string,string>,id:string){
        const body ={
            url:`${API_END_POINTS.MEASUREMENT.measurement}/${id}`,
            headerToken: TOKEN_DATA_TYPE.ACCESS,
            data
        }
        return UseApiService().patch(body)
    }
	uploadFileUrl(fileName:string,measureDefType:string){
        const body ={
            url:`${API_END_POINTS.TECHPACK.uploadTechpackFile}`,
            headerToken: TOKEN_DATA_TYPE.ACCESS,
            data:{
				uploadType:"measureDef",
				file:fileName,
				measureDef:measureDefType
			}
        }
        return UseApiService().get(body)
    }	
	getUploadedFileUrl(measureDef: string, fileName: string): Promise<any> {
		const body = {
			url: API_END_POINTS.TECHPACK.uploadedFileUrl,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
			data: {
				uploadType: "measureDef",
				file: fileName,
				measureDef,
			},
		};
		return UseApiService().get(body);
	}
	getMeasurementList(): Promise<any> {
		const body = {
			url: API_END_POINTS.MEASUREMENT.measurement,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
		};
		return UseApiService().get(body);
	}
	getIndividualMeasurement(id:string){
        const body ={
            url:`${API_END_POINTS.MEASUREMENT.measurement}/${id}`,
            headerToken: TOKEN_DATA_TYPE.ACCESS,
        }
        return UseApiService().get(body)
    }	

}

const measurementService = new MeasurementService();
export default measurementService;
