const Chart = () => {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
<path d="M14.875 14.875H3.25833C2.86163 14.875 2.66328 14.875 2.51176 14.7978C2.37848 14.7299 2.27011 14.6215 2.2022 14.4882C2.125 14.3367 2.125 14.1384 2.125 13.7417V2.125M14.1667 5.66667L11.3908 8.62938C11.2856 8.74166 11.233 8.7978 11.1695 8.82682C11.1135 8.85243 11.0518 8.863 10.9905 8.85747C10.921 8.85121 10.8527 8.81576 10.7161 8.74485L8.40885 7.54681C8.2723 7.47591 8.20402 7.44046 8.13452 7.4342C8.07319 7.42867 8.01147 7.43923 7.95546 7.46484C7.892 7.49386 7.8394 7.55 7.7342 7.66228L4.95833 10.625" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        </>
    );
};

export default Chart;
