import { TOKEN_DATA_TYPE } from "enum/enums";
import UseApiService from "./apiService";
import { API_END_POINTS } from "lib/Constants";

 class FitModelService {

    getListFitModels():Promise<any>{
        const body ={
            url:API_END_POINTS.FIT_MODELS,
            headerToken: TOKEN_DATA_TYPE.ACCESS,
        }
        return UseApiService().get(body)
    }

    deleteFitModel(fitModelId:string){
        const body ={
            url:`${API_END_POINTS.FIT_MODELS}/${fitModelId}`,
            headerToken: TOKEN_DATA_TYPE.ACCESS,
        }
        return UseApiService().delete(body)
    }
    createFitModel(name:string){
        const body ={
            url:API_END_POINTS.FIT_MODELS,
            headerToken: TOKEN_DATA_TYPE.ACCESS,
            data:{
                name
            }
        }
        return UseApiService().post(body)
    }
    updateFitModelMeasurement(data:Record<string,string>){
        const body ={
            url:API_END_POINTS.FIT_MODEL_MEASUREMENTS,
            headerToken: TOKEN_DATA_TYPE.ACCESS,
            data
        }
        return UseApiService().post(body)
    }
    updateFitModelName(data:Record<string,string>){
        const body ={
            url:API_END_POINTS.FIT_MODELS,
            headerToken: TOKEN_DATA_TYPE.ACCESS,
            data
        }
        return UseApiService().patch(body)
    }
}

 const fitModelService = new FitModelService() 
 export default fitModelService