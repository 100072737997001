import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from "./TryOnSelection.module.scss";
import CustomButton from 'atoms/customButton/CustomButton';
import { EXAMPLE_IMAGES, ROUTES, SELECT_TYPE } from "lib/Constants";
import { useNavigate } from "react-router-dom";
import CustomSelect from 'atoms/customSelect/CustomSelect';
import TryOnSampleImages from './TryOnSampleImages';
import TryOnImageUpload from './TryOnImageUpload';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useDispatch } from 'react-redux';
import {  HANDLE_GET_TRY_ON_TRIALS, HANDLE_TRY_ON_FILE_UPLOAD } from 'store/tryOn/action.types';
import { setTryonTrialRequestSent, setTryOnUploadImage } from 'store/tryOn/reducer';
import { TRY_ON_FILE_UPLOAD_SHOW_TYPE } from 'enum/enums';

const TryOnSelection = () => {
    const [imageSelectValue,setImageSelectValue] = useState<string>(EXAMPLE_IMAGES);
    const {tryOnUploadImage,approveLoading,selectedData,sampleImages,tryonTrialRequestSent} = useSelector((state:RootState)=>state.tryOn)
    const [selectedGender,setSelectedGender] = useState<string>("Male");
    const { userDetails } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isButtonDisabled = useMemo(()=>{
      if (imageSelectValue===EXAMPLE_IMAGES) {
        return sampleImages?.filter((el)=>el?.selected)?.length===0
      }else{
       return  tryOnUploadImage?.length === 0
      }
    },[imageSelectValue,tryOnUploadImage,sampleImages])
    
    const handleFileOnChange = useCallback(
		(files: File[]) => {
			const imageFiles = files.filter((file) => file.type.startsWith("image/"));
			if (imageFiles.length > 0) {
				dispatch({ type: HANDLE_TRY_ON_FILE_UPLOAD, payload: { userEmail: `dashboard@${userDetails?.store_url}`, files } });
			} else {
				console.error("No valid image files selected.");
			}
		},
		[userDetails],
	);
	const handleDeleteImage = useCallback(
		(id: string) => {
			const filteredData = tryOnUploadImage?.filter((el) => el.id !== id);
			dispatch(setTryOnUploadImage(filteredData));
		},
		[tryOnUploadImage],
	);
    const handleApprove=useCallback(()=>{
        let selectedNames:string[]=[];
        if (imageSelectValue===EXAMPLE_IMAGES) {
            selectedNames =  sampleImages?.reduce((acc:string[],el)=>{
                if(el?.selected){
                    acc.push(el.filename)
                }
                return acc;
            },[])  
        }else{
            selectedNames = tryOnUploadImage?.map((el)=>el.name);
        }
        dispatch({type:HANDLE_GET_TRY_ON_TRIALS,payload:{selectedUserImages:selectedNames,selectedProductHandles:selectedData}})
   },[tryOnUploadImage,imageSelectValue,selectedData,sampleImages])


useEffect(()=>{
  if (tryonTrialRequestSent) {
    dispatch(setTryonTrialRequestSent(false))
    navigate(ROUTES.TRY_ON_ONBOARDING)
  }
},[tryonTrialRequestSent])
useEffect(()=>{
  if (!selectedData.length) {
    navigate(ROUTES.TRY_ON_ONBOARDING)
  }
},[selectedData])

    return (
        <div className={`${styles.selection} flex-column`}>
            <div className={`${styles.selection__select} flex-wrap`}>
                <div className={`${styles.selection__col}`}>
                    <p>For</p>
                    <CustomSelect
                        data={[{ id: 1, name: "Male" }, { id: 2, name: "Female" }]}
                        type={SELECT_TYPE.NORMAL}
                        value={"Male"}
                        outlineShadow
                        full
                        handleChange={(value:string)=>setSelectedGender(value)}
                    />
                </div>
                <div className={`${styles.selection__col}`}>
                    <p>With</p>
                    <CustomSelect
                        data={[{ id: 1, name: "Example images" }, { id: 2, name: "Images I will uplaod" }]}
                        type={SELECT_TYPE.NORMAL}
                        value={imageSelectValue}
                        outlineShadow
                        full
                        handleChange={(value:string)=>setImageSelectValue(value)}
                    />
                </div>
            </div>
            <div className={`${styles.selection__scroll}`}>
                <div className={`${styles.selection__images}`}>
                    {imageSelectValue===EXAMPLE_IMAGES ?<TryOnSampleImages selectedGender={selectedGender} />: <TryOnImageUpload handleFileOnChange={handleFileOnChange} handleDeleteImage={handleDeleteImage} showType={TRY_ON_FILE_UPLOAD_SHOW_TYPE.NORMAL}/>   }
                </div>

            </div>
            <div className={`${styles.selection__button} flex justify-end`}>
                <CustomButton disabled={isButtonDisabled||approveLoading} loading={approveLoading} handleFunc={handleApprove} buttonText="Test" className='button rounded sm' />
            </div>
        </div>
    )
}

export default TryOnSelection