import ArrowRight from 'assets/icons/arrowRight';
import React from 'react'

interface Capsule{
colour?:string;
bgColor?:string;
name:string;
showIcon?:boolean;
border?:boolean;
}

const Capsule = ({colour="black",bgColor="white",name,showIcon=false, border=false,}:Capsule) => {
  return (
    <span className={`capsule ${border ? `capsuleBorder` : ``}`} style={{color:colour, backgroundColor:bgColor, }}><span>{name}</span>{showIcon && <ArrowRight />}</span>
  )
}

export default Capsule