import { createSlice } from "@reduxjs/toolkit";

interface InitialStateInterface {
	data: any[];
	loading: boolean;
	deleteLoading: boolean;
	createLoading: boolean;
	editToFitmodel:string
}

const InitialState: InitialStateInterface = {
	data: [],
	loading: true,
	deleteLoading: false,
	createLoading: false,
	editToFitmodel:""
};

const fitModelSlice = createSlice({
	name: "fitModel",
	initialState: InitialState,
	reducers: {
		setFitModelsData: (state, { payload }) => {
			state.data = payload;
		},
		setLoading: (state, { payload }) => {
			state.loading = payload;
		},
		setDeleteLoading: (state, { payload }) => {
			state.deleteLoading = payload;
		},
		setCreateLoading: (state, { payload }) => {
			state.createLoading = payload;
		},
		setEditToFitmodel: (state, { payload }) => {
			state.editToFitmodel = payload;
		},
	},
});

export const { setFitModelsData, setLoading, setDeleteLoading,setCreateLoading,setEditToFitmodel } = fitModelSlice.actions;
export default fitModelSlice.reducer;
