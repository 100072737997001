import TryOnOnBoarding from 'component/tryonOnboarding/TryOnOnboarding'
import React from 'react'
import styles from './HomeOnboarding.module.scss';
import HomeSizingOnboarding from 'component/homeSizingOnboarding/HomeSizingOnboarding';

const HomeOnboarding = () => {
  return (
    <div className={`${styles.onboarding}`}>
        <TryOnOnBoarding/> 
        <div className={`${styles.onboarding__box}`}>
        <HomeSizingOnboarding/> 
        </div>
   </div>
    
  )
}

export default HomeOnboarding