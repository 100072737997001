import QuickStartCard from "atoms/quickStartCard/QuickStartCard";
import { ROUTES, SELECT_TYPE, SIZING_QUICK_START_DATA } from "lib/Constants";
import React, { useEffect, useState } from "react";
import styles from "./SizingStart.module.scss";
import { useDispatch } from "react-redux";
import { HANDLE_GET_FIT_MODEL_LIST } from "store/fitModel/action.types";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import LoadingScreen from "component/LoadingScreen";
import { HANDLE_GET_TECHPACK_LIST } from "store/techpack/action.types";
import { HANDLE_GET_MEASUREMENT_LIST } from "store/measurement/action.types";
import { getConfirmedMeasurements } from "lib/utils";
import BackButton from "component/backButton/BackButton";

const SizingStart = () => {
	const dispatch = useDispatch();
	const { loading,data:fitmodelData } = useSelector((state: RootState) => state.fitModel);
    const {data:techpackData} = useSelector((state:RootState)=>state.techpack)
	const {measurementList}= useSelector((state:RootState)=>state.measurement)
	const [confirmedMeasurements, setConfirmedMeasurements] = useState<{ name: string }[]>([]);
  
useEffect(() => {
    if (Object.keys(measurementList).length >0) {
        const confirmed = getConfirmedMeasurements(measurementList);
        setConfirmedMeasurements(confirmed);
    }
}, [measurementList]);

	useEffect(() => {
		dispatch({type:HANDLE_GET_TECHPACK_LIST})
		dispatch({ type: HANDLE_GET_FIT_MODEL_LIST });
		dispatch({type:HANDLE_GET_MEASUREMENT_LIST})
	}, []);

	return loading ? (
		<LoadingScreen customClass="h-full" />
	) : (
        <div className="flex-column align-center justify-center h-full">
            <div className={`${styles.sizingBack}`}>
            <BackButton route={ROUTES.HOME}/>
            </div>
           
            <div className={`${styles.sizingMain} flex align-center justify-center`}>
		<div className={`${styles.sizing} flex-wrap`}>
			{SIZING_QUICK_START_DATA.map((el, index: number) => {
				if (index === 0) {
					return (
						<React.Fragment key={el.id}>
						<div className={`${styles.sizing__col}`}>
							<QuickStartCard key={el.id} imageUrl={el.imgageUrl} para={el.para} heading={el.heading} time={el.time} route={el.route} data={confirmedMeasurements} type={SELECT_TYPE.MESUREMENT}  altRoute={el.altRout} />
						</div>
					</React.Fragment>
					);
				}
				if (index === 1) {
					return (
						<React.Fragment key={el.id}>
							<div className={`${styles.sizing__col}`}>
								<QuickStartCard key={el.id} imageUrl={el.imgageUrl} para={el.para} heading={el.heading} time={el.time} route={el.route} data={fitmodelData} type={SELECT_TYPE.FITMODEL}altRoute={el.altRout} />
							</div>
						</React.Fragment>
					);
				}
				return (
					<React.Fragment key={el.id}>
							<div className={`${styles.sizing__col}`}>
								<QuickStartCard key={el.id} imageUrl={el.imgageUrl} para={el.para} heading={el.heading} time={el.time} route={el.route} data={techpackData} type={SELECT_TYPE.TECHPACK} altRoute={el.altRout} />
							</div>
						</React.Fragment>
				);
			})}
		</div>
        </div>
        </div>
	);
};

export default SizingStart;
