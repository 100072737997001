import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import Capsule from 'atoms/capsule/Capsule';
import CheckEligibiltyButton from 'component/CheckEligibiltyButton';
import { TRY_ON_STATUS, TRY_ON_STATUS_FOR_OPEN } from 'lib/Constants';
import openIcon from "assets/images/open-icon.svg";
import Tick from "assets/images/tick-icon.svg";
import React, { useCallback } from 'react'
import CustomButton from 'atoms/customButton/CustomButton';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useDispatch } from 'react-redux';
import { setSelectedProduct, setShowModal } from 'store/tryOn/reducer';


interface TryOnTableRowsProps{
    rows: any[];
    selected?:string[];
    requiredCheckBox?:boolean;
    handleMeasurementDetails?: (value: string, type?: string, id?: string) => void;
    handleClick:(event: React.MouseEvent<unknown>, id: string) =>void
}

const TryOnTableRows = ({rows,selected,requiredCheckBox,handleMeasurementDetails,handleClick}:TryOnTableRowsProps) => {
   const {showModal} = useSelector((state:RootState) => state.tryOn)
   const dispatch = useDispatch()

    const handleOpen=useCallback((productHandle:string)=>{
        dispatch(setShowModal(true))
        dispatch(setSelectedProduct(productHandle))
    },[showModal])
    
    const renderStatusContent = useCallback((status:{ name: string; color: string; bgColor: string }, productHandle:string) => {
        if (!status) {
            return <CheckEligibiltyButton productName={productHandle} />;
        }
        switch (status.name) {
            case "Sent For Elibility":
                return <span className="eligible-text">Sent for Eligibility</span>;
            case "Not Eligible":
                return <CustomButton className="rounded xs" buttonText="Upload" handleFunc={()=>handleOpen(productHandle)} />;
            default:
                return <Capsule colour={status.color} bgColor={status.bgColor} name={status?.name} />;
        }
    },[handleOpen]);

  return (
    <TableBody>
    {rows?.map((row, index: number) => {
        const isItemSelected = selected?.includes(row?.handle);
        const labelId = `enhanced-table-checkbox-${index}`;
        const status = TRY_ON_STATUS[row?.tryonLaunchStatus] || "";
        return (
            <React.Fragment key={labelId}>    
            <TableRow
            onClick={(event) => row?.tryonLaunchStatus && requiredCheckBox && handleClick?.(event, row?.handle)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row?.handle}
            selected={isItemSelected}
            sx={{ cursor: row?.tryonLaunchStatus && requiredCheckBox ? "pointer" : "default" }}
        >
            {requiredCheckBox && (
                <TableCell>
                    <span className=" customCheckBox flex">
                        <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                                "aria-labelledby": labelId,
                            }}
                        />
                    </span>
                </TableCell>
            )}
            <TableCell>
                <div className={`flex justify-between align-start productCell`}>
                    <span>{row?.title}</span>
                    {TRY_ON_STATUS_FOR_OPEN?.[row?.tryonLaunchStatus] && (
                        <span
                            role="button"
                            className={`openButton`}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                handleMeasurementDetails?.(row?.handle, row?.tryonLaunchStatus, row?.shopifyProductId);
                            }}
                        >
                            <img src={openIcon} alt="icon" /> OPEN
                        </span>
                    )}
                </div>
            </TableCell>
            <TableCell>{row?.productCategory}</TableCell>
            <TableCell>
                <div className="flex gap-10">
                    {renderStatusContent(status,row?.handle)}
                </div>
            </TableCell>
            <TableCell>
                {row?.tryonLaunchStatus ? (
                    row?.ghostImageAvailable ? (
                        <>
                            <span className="available">
                                <img src={Tick} alt="icon" />
                                available
                            </span>
                        </>
                    ) : (
                        <>
                            <span className="not-available"> not available</span>
                        </>
                    )
                ) : (
                    <span className="not-available"> Searching...</span>
                )}
            </TableCell>
            <TableCell>
                <div className="desc">{row?.tryonLaunchStatus ? row?.garmentDescription : "Generating..."}</div>
            </TableCell>
        </TableRow>
        </React.Fragment>
       
        );
    })}
</TableBody>
  )
}

export default TryOnTableRows