import {  call, put, takeLatest } from "redux-saga/effects";
import { addToast } from "store/toaster/reducer";
import { TOAST_TYPES } from "enum/enums";
import { handleErrorMessage } from "lib/utils";
import { HANDLE_GET_GROUP_LIST } from "./action.types";
import groupService from "services/groupService";
import {setGroupList ,setGroupListLoading } from "./reducer";

function* fetchGroupList(): Generator {
  yield put(setGroupListLoading(true));
  try {
    const res: any = yield call(groupService.getGroupList);
    yield put(setGroupList(res?.data));
  } catch (error) {
    yield put(
      addToast({ type: TOAST_TYPES.ERROR, messsage: handleErrorMessage(error) })
    );
  } finally {
    yield put(setGroupListLoading(false));
  }
}

function* groupSaga() {
  yield takeLatest(HANDLE_GET_GROUP_LIST, fetchGroupList);
}

export default groupSaga;
