import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

interface LinearBufferProps{
    value:number
}

export default function LinearBuffer({value=0}:LinearBufferProps) {
  const [progress, setProgress] = React.useState<number>(value);

  const progressRef = React.useRef<NodeJS.Timer>();
  React.useEffect(() => {
    progressRef.current = setInterval(()=>{
     setProgress((prev)=>prev+5)
    },1000)
  });

  React.useEffect(() => {
    if(progress===100){
        clearInterval(progressRef.current);
    }
    return () => {
      clearInterval(progressRef.current);
    };
  }, [progress]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress}  />
    </Box>
  );
}
