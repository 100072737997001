import { configureStore } from '@reduxjs/toolkit'
import authSlice from "./auth/reducer"
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import toasterReducer from "./toaster/reducer" 
import customer from "./customer/reducer";
import fitModel from "./fitModel/reducer"
import techpack from "./techpack/reducer"
import product from "./products/reducer"
import group from "./group/reducer"
import measurement from "./measurement/reducer";
import tryOnReducer from "./tryOn/reducer"
import customReducer from "./custom/reducer"
const sagaMiddleware = createSagaMiddleware()
 const store = configureStore({
    reducer:{
        auth:authSlice,
        toaster:toasterReducer,
        customer:customer,
        fitModel:fitModel,
        techpack:techpack,
        product:product,
        group:group,
        measurement:measurement,
        tryOn:tryOnReducer,
        custom:customReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          thunk: false, // Ensure thunk middleware is included
        }).concat(sagaMiddleware),
    
})
sagaMiddleware.run(rootSaga);
export type RootState = ReturnType<typeof store.getState>; 

export default  store