import { createSlice } from "@reduxjs/toolkit";
import { generateUuid } from "lib/utils";


const searchParams = new URLSearchParams(window.location.search)
interface InitialStateInterface{
    data:any[];
    loading:boolean;
    uploadingLoading:boolean;
    latestTechpackFieldId:string;
    fileName:string;
    downloadUrl:string;
    uploadedFileType:string;
    techPackParsedData:any[];
    submitLoading:boolean;
    isFeedbackSubmited:boolean;
    batchLoading:boolean;
    batchFileUploaded:boolean;
    techpackFileBatchId:string
    uploadedFiles:File[];
    techpackParseDone:boolean

}

const InitialState:InitialStateInterface={
    data:[],
    loading:false,
    uploadingLoading:false,
    latestTechpackFieldId:searchParams.get("techpackId")??"",
    fileName:searchParams.get("fileName")??"",
    downloadUrl:"",
    uploadedFileType:"",
    isFeedbackSubmited:false,
    techPackParsedData:[],
    submitLoading:false,
    batchLoading:false,
    batchFileUploaded:false,
    techpackFileBatchId:generateUuid(),
    uploadedFiles:[],
    techpackParseDone:false
}

const techpackSlice = createSlice({
    name:"techpack",
    initialState: InitialState,
    reducers:{
      setTechpackData:(state,{payload})=>{
        state.data=payload
      },
      setTechPackParsedData:(state,{payload})=>{
        state.techPackParsedData=payload
      },
      setLoading:(state,{payload})=>{
        state.loading=payload
      },
      setUploadLoading:(state,{payload})=>{
        state.uploadingLoading=payload
      },
      setUploadedFileType:(state,{payload})=>{
        state.uploadedFileType=payload
      },
      setLatestTechpackFieldId:(state,{payload})=>{
        state.latestTechpackFieldId=payload
      },
      setFileName:(state,{payload})=>{
        state.fileName=payload
      },
      setDownloadUrl:(state,{payload})=>{
        state.downloadUrl=payload
      },
      setSubmitLoading:(state,{payload})=>{
        state.submitLoading = payload
      },
      setIsFeedbackSubmited:(state,{payload})=>{
        state.isFeedbackSubmited = payload
      },
      setBatchLoading:(state,{payload})=>{
        state.batchLoading = payload
      },
      setBatchFileUploaded:(state,{payload})=>{
        state.batchFileUploaded = payload
      },
      setTechpackFileBatchId:(state,{payload})=>{
        state.techpackFileBatchId = payload
      },
      setUploadedFiles:(state,{payload})=>{
        state.uploadedFiles = payload
      },
      setTechpackParseDone:(state,{payload})=>{
        state.techpackParseDone = payload
      }
    }
})

export const {setTechpackData,setLoading,setUploadLoading,setLatestTechpackFieldId,setFileName,setDownloadUrl,setUploadedFileType,setTechPackParsedData,setSubmitLoading,setIsFeedbackSubmited,setBatchLoading,setBatchFileUploaded,setTechpackFileBatchId,setUploadedFiles,setTechpackParseDone} = techpackSlice.actions
export default techpackSlice.reducer