import React, { useEffect, useState } from "react";
import styles from "./SignupSteps.module.scss";
import CustomRadio from "atoms/customRadio/CustomRadio";
import { Tooltip } from "@mui/material";
import HelpIcon from "assets/icons/helpIcon";
import CustomButton from "atoms/customButton/CustomButton";
import shopify from "assets/images/shopify-icon.svg";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useDispatch } from "react-redux";
import { setShowModal, setStoreUrl } from "store/auth/reducer";
import CustomDialog from "component/CustomDialog";
import Installed from "component/Installed/Installed";
import { SIGN_UP_TAB_VALUE, TOAST_TYPES } from "enum/enums";
import { checkStoreUrl } from "lib/utils";
import { addToast } from "store/toaster/reducer";
import { HANDLE_UPDATE_USER_REGISTER } from "store/auth/action.types";

interface SelectForm {
	isShopify: boolean;
	isCustom: boolean;
	store_url: string;
}

const SignupThirdStep = () => {
	const { showModal,  customerId, step ,websiteUrl,loginLoading} = useSelector((store: RootState) => store.auth);
	const dispatch = useDispatch();
	const [values, setValues] = useState<SelectForm>({
		isShopify: true,
		isCustom: false,
		store_url: "",
	});
	const onChange = (e: any) => {
		const { name, checked, value } = e.target;
		if (name === "isShopify") {
			setValues({ ...values, [name]: checked, isCustom: false });
		} else if (name === "isCustom") {
			setValues({ ...values, [name]: checked, isShopify: false });
		} else {
			setValues({ ...values, [name]: value });
		}
	};

	const handleClose = () => {
		dispatch(setShowModal(!showModal));
	};

	const onSubmit = (e: any) => {
		e.preventDefault();
		if (values.isCustom) {
			window.open("https://www.swanvision.ai/docs", '_blank')
		} else {
			if(!checkStoreUrl(values.store_url)){
				dispatch(addToast({type:TOAST_TYPES.ERROR, message:"Please enter valid url"}));
			}else{
				dispatch(setStoreUrl(values.store_url));
				const payload={
					customerId,
					store_url:values.store_url,
					step:SIGN_UP_TAB_VALUE.THREE
				}
				dispatch({type:HANDLE_UPDATE_USER_REGISTER,payload})
			}
		}
	};
	useEffect(() => {
		if (step === SIGN_UP_TAB_VALUE.THREE) {
			handleClose();
		}
	}, [step]);
	return (
		<div className={`${styles.steps}`}>
			<div className={`${styles.steps__info}`}>
				<div className={`${styles.steps__wrapper} ${styles.large}`}>
					<ul className={`${styles.steps__step}  flex align-center justify-center`}>
						<li>
							<span>1</span>
						</li>
						<li>
							<span className={styles.active}>2</span>
						</li>
						{/* <li>
							<span>3</span>
						</li> */}
					</ul>
					<div className={`${styles.steps__box}`}>
						{<h2>Is {websiteUrl??"store"} on Shopify?</h2>}
						<form onSubmit={onSubmit}>
							<>
								<div className={`${styles.step__radio}`}>
									<CustomRadio onChange={onChange} title="Yes" subtitle="Add Swan’s Shopify app" name="isShopify" id="2" value={values.isShopify} />
									{values.isShopify && (
										<div role="button" className={`${styles.step__shopify} flex justify-between align-center`}>
											<figcaption className="flex align-center">
												<figure>
													<img src={shopify} alt="icon" />
												</figure>
												<input value={values.store_url} placeholder="my shopify url" type="text" onChange={onChange} name="store_url" />
											</figcaption>
											<Tooltip title="my shopify url">
												<span>
													<HelpIcon />
												</span>
											</Tooltip>
										</div>
									)}
									<CustomRadio
										onChange={onChange}
										name="isCustom"
										title="No, another eCommerce platform"
										subtitle="Bring me to the developer docs "
										id="2"
										value={values.isCustom}
									/>
								</div>
								<div className={` ${styles.step__button} ${styles.small}`}>
									<CustomButton type="submit" className="full rounded semibold" buttonText="Next" disabled={loginLoading} loading={loginLoading} />
								</div>
							</>
						</form>
						<CustomDialog open={showModal} className="invite-dialog" handleShowModal={handleClose}>
							<Installed />
						</CustomDialog>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignupThirdStep;
