import { all, call, delay, put, takeLatest } from "redux-saga/effects";
import { AUTH_TYPE, SIGN_UP_TAB_VALUE, TOAST_TYPES } from "enum/enums";
import { getLocalStorageData, handleErrorMessage, JwtTokenValidate, removeLocalStorage } from "lib/utils";
import { setAuth, setCustomerId, setInternalUser, setIsAppInstalled, setLoading, setLoginLoading, setProfileInfo, setShowMessage, setStep } from "./reducer";
import { HANDLE_APP_INSTALL_CHECK, HANDLE_AUTH, HANDLE_TOKEN, HANDLE_UPDATE_USER_REGISTER, HANDLE_USER_LOGIN, HANDLE_USER_REGISTER } from "./action.types";
import { authenticateUser, checkAppInstall, registerUser, UpdateRegisterUser } from "services/authServices";
import { hasCustomAddOnFlag, INTERNAL_JWT_TOKEN, JWT_TOKEN, ROUTES, SUCCESFULLY_EMAIL_SENT } from "lib/Constants";
import { addToast } from "store/toaster/reducer";
import { UserFormData } from "interface/interface";

function* handleToken() {
	try {
		const decodedInternalToken = JwtTokenValidate(getLocalStorageData(INTERNAL_JWT_TOKEN));
		const decodedToken = JwtTokenValidate(getLocalStorageData(JWT_TOKEN));
		if (decodedInternalToken && decodedInternalToken?.properties?.is_internal) {
			yield put(setInternalUser(true));
		}
		if (decodedToken) {
			yield all([put(setProfileInfo(decodedToken?.properties)), put(setAuth(true))]);
		} else {
			yield call(handleAuth, { type: HANDLE_AUTH, payload: AUTH_TYPE.LOGOUT });
		}
	} catch (error) {
		console.error("Error handling token:", error);
	} finally {
		yield put(setLoading(false));
	}
}

function* handleAuth(action: { type: string; payload: AUTH_TYPE }) {
	try {
		if (action.payload === AUTH_TYPE.LOGIN) {
			yield put(setAuth(true));
		} else if (action.payload === AUTH_TYPE.LOGOUT) {
			yield all([put(setAuth(false)), put(setInternalUser(false)), call(removeLocalStorage, JWT_TOKEN), call(removeLocalStorage, INTERNAL_JWT_TOKEN), call(removeLocalStorage, hasCustomAddOnFlag)]);
		}
	} catch (error) {
		console.error("Error handling auth:", error);
	}
}

function* handleUserLogin(action: { type: string; payload: { email: string; showToast: boolean } }): Generator {
	const redirectUrl = `${ROUTES.USER_VERIFY}/?email=${action.payload.email}&auth_type=dashboard_login`;
	yield put(setLoginLoading(true));
	try {
		yield call(authenticateUser, action.payload.email, redirectUrl);
		if (action.payload.showToast) {
			yield put(addToast({ message: SUCCESFULLY_EMAIL_SENT, type: TOAST_TYPES.SUCCESS }));
		} else {
			yield put(setShowMessage(true));
		}
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
	} finally {
		yield put(setLoginLoading(false));
	}
}

function* handleUserRegister(action: { type: string; payload: UserFormData }): Generator {
	yield put(setLoginLoading(true));
	const { step, ...rest } = action.payload;
	try {
		const res: any = yield call(registerUser, rest);
		yield put(setCustomerId(res?.data?.customerId));
		yield put(setStep(step));
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
	} finally {
		yield put(setLoginLoading(false));
	}
}

function* handleUpdateRegisterUser(action: { type: string; payload: UserFormData }): Generator {
	yield put(setLoginLoading(true));
	try {
		const { step, ...rest } = action.payload;
		yield call(UpdateRegisterUser, rest);
		yield put(setStep(step));
	} catch (error) {
		yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
	} finally {
		yield put(setLoginLoading(false));
	}
}

function* handleAppInstallCheck(action: { type: string; payload: string }): Generator {
	yield put(setLoginLoading(true));
  yield put(setIsAppInstalled(false))
	try {
		let maxAttempts = 20;
		while (maxAttempts > 1) {
			try {
        const res: any = yield call(checkAppInstall, action.payload);
        const appInstalled = res?.data?.appInstalled;
        const subscriptionValid = res?.data?.subscriptionValid
        if(subscriptionValid && appInstalled){
          yield put(setStep(SIGN_UP_TAB_VALUE.FOUR));
          break;
        }
        else if (appInstalled) {
          yield put(setIsAppInstalled(true))
        }
        yield delay(5 * 1000);
        maxAttempts--;
			} catch (error) {
				console.log(error, "polling for app install check");
        break;
			}
		}
	} finally {
		yield put(setLoginLoading(false));
	}
}

function* authSaga() {
	yield takeLatest(HANDLE_TOKEN, handleToken);
	yield takeLatest(HANDLE_AUTH, handleAuth);
	yield takeLatest(HANDLE_USER_LOGIN, handleUserLogin);
	yield takeLatest(HANDLE_USER_REGISTER, handleUserRegister);
	yield takeLatest(HANDLE_APP_INSTALL_CHECK, handleAppInstallCheck);
	yield takeLatest(HANDLE_UPDATE_USER_REGISTER, handleUpdateRegisterUser);
}
export default authSaga;
