import Check from "assets/icons/check";
import { CustomRadioProps } from "interface/interface";

const CustomRadio = ({ title, subtitle, name, simple, disabled = false, id = '',value=false,onChange }: CustomRadioProps) => {
    return (
        <>
        <div className={`custom-radio  ${simple ? 'simple' : ''} ${disabled ? 'disabled' : ''}`} >
            <label>
            <input type="radio" name={name} id={id} onChange={onChange} checked={value} />
            {simple ? <>
                <span>{title}</span>
            </> :<>
            <div className="custom-radio__box">
                    <h5>{title}</h5>
                    <p>{subtitle}</p>
                    <span>
                        <Check />
                    </span>
            </div>
            </>}
         
            </label>
        </div>
        </>
    )
}

export default CustomRadio;