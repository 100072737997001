import React from "react";
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { ROUTES } from "./Constants";
import App from "../App";
import LoggedInWrapper from "wrapper/LoggedInWrapper";
import LoggedOutWrapper from "wrapper/LoggedOutWrapper";
import UserVerify from "pages/userVerify/UserVerify";
import CustomerList from "pages/customerList/CustomerList";
import DashboardWrapper from "component/dashboard/Dashboard";
import Analytics from "pages/analytics/Analytics";
import Login from "pages/login/Login";
import Signup from "pages/signup/Signup";
import Products from "pages/products/Products";
import FitModelOnboarding from "pages/fitModelOnboarding/FitModelOnboarding";
import TryOnOnboarding from "pages/tryOnOnboarding/TryOnOnboarding";
import SizingStart from "pages/sizingStart/SizingStart";
import MeasurementDefinition from "pages/measurementDefinition/MeasurementDefinition";
import SignupFirstStep from "pages/signupSteps/SignupFirstStep";
// import SignupSecondStep from "pages/signupSteps/SignupSecondStep";
import SignupThirdStep from "pages/signupSteps/SignupThirdStep";
import SizingOnboarding from "pages/sizingOnboarding/SizingOnboarding";
import MeasurementGroups from "pages/measurementGroups/MeasurementGroups";
import MeasurementTable from "pages/measurementGroups/MeasurementTable";
import TryOnSelection from "pages/tryOnSelection/TryOnSelection";
import TryOnReviewImages from "pages/tryOnStart/TryOnReviewImages";
import CustomDetails from "pages/customDetails/CustomDetails";
import CustomCheckWrapper from "wrapper/CustomCheckWrapper";
import LandingPage from "component/LandingPage";



function ProjectRoutes() {
	const router = createBrowserRouter([
		{
			path: ROUTES.HOME,
			element: <App />,
			children: [
				{
					path: ROUTES.HOME,
					element: (
						<LoggedInWrapper>
							<LandingPage />
						</LoggedInWrapper>
					),
				},
				{
					path: ROUTES.LOGIN,
					element: (
						<LoggedOutWrapper>
							<Login />
						</LoggedOutWrapper>
					),
				},
				{
					path: ROUTES.SIGNUP,
					element: (
						<LoggedOutWrapper>
							<Signup />
						</LoggedOutWrapper>
					),
				},
				{
					path: ROUTES.USER_VERIFY,
					element: <UserVerify />,
				},
				{
					path: ROUTES.ONBOARDING,
					element: <Outlet />,
					children: [
						{
							path: ROUTES.ONBOARDING_FIRST_STEP,
							element: <SignupFirstStep />,
						},
						// {
						// 	path: ROUTES.ONBOARDING_SECOND_STEP,
						// 	element: <SignupSecondStep />,
						// },
						{
							path: ROUTES.ONBOARDING_SECOND_STEP,
							element: <SignupThirdStep />,
						},
					],
				},
				{
					path: ROUTES.CUSTOMERS_LIST,
					element: <CustomerList />,
				},
				{
					path: ROUTES.NOT_MATCH,
					element: (
						<LoggedInWrapper>
							<Navigate to={ROUTES.HOME} replace />
						</LoggedInWrapper>
					),
				},
				{
					path: ROUTES.CUSTOM_DETAILS,
					element: (
						<LoggedInWrapper>
							<DashboardWrapper title="Person 1">
								<CustomCheckWrapper allowCustomRoutes={true} fallBackRoute={ROUTES.HOME}>
									<CustomDetails />
								</CustomCheckWrapper>
							</DashboardWrapper>
						</LoggedInWrapper>
					),
				},
				{
					path: ROUTES.ANALYTICS,
					element: (
						<LoggedInWrapper>
							<DashboardWrapper title="Metrics">
								<CustomCheckWrapper allowCustomRoutes={false}>
									<Analytics />
								</CustomCheckWrapper>
							</DashboardWrapper>
						</LoggedInWrapper>
					),
				},
				{
					path: ROUTES.PRODUCTS,
					element: (
						<LoggedInWrapper>
							<DashboardWrapper title="Products" sync={false} close routes={ROUTES.HOME}>
								<CustomCheckWrapper allowCustomRoutes={false}>
									<Products />
								</CustomCheckWrapper>
							</DashboardWrapper>
						</LoggedInWrapper>
					),
				},
				{
					path: ROUTES.FIT_MODEL_ONBOARDING,
					element: (
						<LoggedInWrapper>
							<DashboardWrapper title="Fit Model Onboarding">
								<CustomCheckWrapper allowCustomRoutes={false}>
									<FitModelOnboarding />
								</CustomCheckWrapper>
							</DashboardWrapper>
						</LoggedInWrapper>
					),
				},
				{
					path: ROUTES.TRY_ON_RECENT_IMAGES,
					element: (
						<LoggedInWrapper>
							<DashboardWrapper title="Try-On onboarding">
								<CustomCheckWrapper allowCustomRoutes={false}>
									<TryOnReviewImages />
								</CustomCheckWrapper>
							</DashboardWrapper>
						</LoggedInWrapper>
					),
				},
				{
					path: ROUTES.TRY_ON_ONBOARDING,
					element: (
						<LoggedInWrapper>
							<DashboardWrapper title="Try-On onboarding">
								<CustomCheckWrapper allowCustomRoutes={false}>
									<TryOnOnboarding />
								</CustomCheckWrapper>
							</DashboardWrapper>
						</LoggedInWrapper>
					),
				},
				{
					path: ROUTES.TRY_ON_SELECTION,
					element: (
						<LoggedInWrapper>
							<DashboardWrapper title="Try-On onboarding">
								<CustomCheckWrapper allowCustomRoutes={false}>
									<TryOnSelection />
								</CustomCheckWrapper>
							</DashboardWrapper>
						</LoggedInWrapper>
					),
				},
				{
					path: ROUTES.SIZING_START,
					element: (
						<LoggedInWrapper>
							<DashboardWrapper title="Quick Start - Sizing">
								<CustomCheckWrapper allowCustomRoutes={false}>
									<SizingStart />
								</CustomCheckWrapper>
							</DashboardWrapper>
						</LoggedInWrapper>
					),
				},
				{
					path: ROUTES.SIZING_ONBOARDING,
					element: (
						<LoggedInWrapper>
							<DashboardWrapper title="Sizing onboarding">
								<CustomCheckWrapper allowCustomRoutes={false}>
									<SizingOnboarding />
								</CustomCheckWrapper>
							</DashboardWrapper>
						</LoggedInWrapper>
					),
				},
				{
					path: ROUTES.MEASUREMENT_GROUPS,
					element: (
						<LoggedInWrapper>
							<DashboardWrapper title="Measurement Groups">
								<CustomCheckWrapper allowCustomRoutes={false}>
									<MeasurementGroups />
								</CustomCheckWrapper>
							</DashboardWrapper>
						</LoggedInWrapper>
					),
				},
				{
					path: ROUTES.MEASUREMENT_TABLE,
					element: (
						<LoggedInWrapper>
							<DashboardWrapper title="Measurement Groups">
								<CustomCheckWrapper allowCustomRoutes={false}>
									<MeasurementTable />
								</CustomCheckWrapper>
							</DashboardWrapper>
						</LoggedInWrapper>
					),
				},
				{
					path: `${ROUTES.MEASUREMENT_DEFINITION}/:id`,
					element: (
						<LoggedInWrapper>
							<DashboardWrapper title="Measurement definition">
								<CustomCheckWrapper allowCustomRoutes={false}>
									<MeasurementDefinition />
								</CustomCheckWrapper>
							</DashboardWrapper>
						</LoggedInWrapper>
					),
				},
				
			],
		},
	]);
	
	return <RouterProvider router={router} />;
}

export default ProjectRoutes;
