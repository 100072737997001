import React, { useMemo } from "react";
import styles from "./Login.module.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { AuthValueInterface } from "interface/interface";
// import FeedbackCarousal from "component/feedbackCarousal/FeedbackCarousal";
import emailIcon from "../../assets/images/send-email-icon.svg";
import CustomButton from "atoms/customButton/CustomButton";
// import singUp from "assets/images/singUp.svg";
import arrowLeft from "assets/images/arrow-left-gray.svg";
import { isValidEmail } from "lib/utils";
import { useDispatch } from "react-redux";
import { HANDLE_USER_LOGIN } from "store/auth/action.types";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { setShowMessage } from "store/auth/reducer";
import { ROUTES } from "lib/Constants";
import { Link, useSearchParams } from "react-router-dom";
import checkIcon from "assets/images/check-verified.svg";
import logo from "assets/images/swan-logo.svg";

const Login = () => {
	const { loginLoading, showMessage } = useSelector((store: RootState) => store.auth);
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setError,
		clearErrors,
	} = useForm<AuthValueInterface>({
		mode: "onChange",
		defaultValues: {
			email: "",
		},
	});
	const [searchParams] = useSearchParams();
	const isAppInstalled = useMemo(() => searchParams.get("appInstalled") || false, [searchParams]);

	const handleUserLogin = async (showToast = false) => {
		dispatch({ type: HANDLE_USER_LOGIN, payload: { showToast, email: getValues("email") } });
	};
	const onSubmit: SubmitHandler<AuthValueInterface> = async (data) => {
		const { email } = data;
		if (!isValidEmail(email.trim())) {
			setError("email", { type: "custom", message: "Please enter a valid email" });
		} else {
			handleUserLogin();
		}
	};
    
	const handleEmailDisplay=(email:any)=>{
		const [localPart, domain] = email.split('@');
		if (localPart.length > 1) {
			const maskedLocalPart = localPart.slice(0, 1) + '*'.repeat(localPart.length - 1);
			return maskedLocalPart + '@' + domain;
		}
		return email;
	}

	return (
		<>
			<div className={`flex-column align-center justify-center ${styles.login}`}>
				<div className={`flex-column align-center justify-center ${styles.login__logo}`}>
					<img width={48} height={42} src={logo} alt="logo" />
				</div>
				{/* <div className={`${styles.login__left}`}> */}
				<div className={`${styles.login__auth}`}>
					{isAppInstalled && <div className={`${styles.login__appInstalled}`}>
						<img src={checkIcon} alt="icon" />
						<h2>App installed</h2>
					</div>}
					{showMessage ? (
						<div className={`${styles.login__message}`}>
							<span className={`${styles.login__icon}`}>
								<img src={emailIcon} alt="Swan" />
							</span>
							<div>
								<h2>Check your email on this device </h2>
								<p>
                                Click the link we sent to {handleEmailDisplay(getValues("email"))} to log in.
								</p>
								{/* <a className={`${styles.login__button}`} href="https://mail.google.com" target="_blank" rel="noreferrer">
									Open email app
								</a> */}
								<p className={`${styles.login__resend}`}>
									{/* Didn’t receive the email?{" "} */}
									<span className={loginLoading ? styles.login__disable : ""} onClick={() => handleUserLogin(true)}>
                                          Resend email
									</span>
								</p>
								<p
									className={`${styles.login__back}`}
									onClick={() => {
										dispatch(setShowMessage(!showMessage));
									}}
								>
									<img src={arrowLeft} alt="Swan" /> Back to Log in
								</p>
							</div>
						</div>
					) : (
						<form onSubmit={handleSubmit(onSubmit)}>
							{/* <div className={`${styles.login__loginHeader}`}>
                    <img src={singUp} alt="Swam" className={` logo`} />
                    <h2>Log in to your account</h2>
                    <p>Welcome back! </p>
                  </div> */}
                  <div className={`${styles.login__heading}`}>
                  {!isAppInstalled && <h2>Welcome back to Swan</h2>}
                  </div>
                  
							<div className={`${styles.login__formGroup}`}>
								<label>Email</label>
								<input
									placeholder="Enter your email"
									type="text"
									{...register("email", {
										required: "Email is required",
									})}
									name="email"
								/>
								{errors.email &&
									(errors.email.message ? (
										<span className={`${styles.error}`}>{errors.email.message}</span>
									) : (
										<span className={`${styles.error}`}>This field is required</span>
									))}
								<CustomButton buttonText="Login" type="submit" className="primary semibold rounded full" disabled={loginLoading} />
								{ !isAppInstalled && <div
									onClick={() => {
										clearErrors();
									}}
									className={`${styles.already}`}
								>
									<p>
										{`Don't have an account?`}{" "}
										<span>
											<Link to={ROUTES.SIGNUP}>Sign up </Link>
										</span>
									</p>
								</div>}
							</div>
						</form>
					)}
				</div>
				{/* <div className={`flex-wrap align-center justify-between ${styles.login__leftFooter}`}>
          <p>© Lir Labs 2024</p>
          <p>
            <a href="mailto:hey@getswan.co">
              <img src={emailIcon} alt="mail" /> hey@getswan.co
            </a>
          </p>
        </div> */}
				{/* </div> */}
				{/* <div className={`${styles.login__right}`}>
        <div className={`${styles.login__rightSlider}`}>
          <FeedbackCarousal />
        </div>
        <div className={` ${styles.login__rightFooter}`}>
          <ul>
            <li>
              <span>Increase conversions & reduce returns</span>
            </li>
            <li>
              <span>Support a sustainable shop</span>
            </li>
            <li>
              <span>Better your customer experience</span>
            </li>
          </ul>
        </div>
      </div> */}
			</div>
		</>
	);
};

export default Login;
