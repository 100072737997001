
import { createSlice } from "@reduxjs/toolkit";
import { SIGN_UP_TAB_VALUE } from "enum/enums";
import { hasCustomAddOnFlag } from "lib/Constants";
import { getLocalStorageData } from "lib/utils";

const searchParams = new URLSearchParams(window.location.search)
interface InitialStateInterface{
    userDetails:Record<string,any>;
    isAuth:boolean;
    loading:boolean;
    isInternalUser:boolean;
    hasCustomAddon:boolean;
    loginLoading:boolean;
    showMessage:boolean;
    email:string;
    customerId:string,
    showModal:boolean,
    step:SIGN_UP_TAB_VALUE | null ,
    storeName:string,
    websiteUrl:string,
    storeUrl:string,
    isAppInstalled:boolean
}

const initialState:InitialStateInterface={
    userDetails:{},
    isAuth:false,
    loading:true,
    isInternalUser:false,
    hasCustomAddon:getLocalStorageData(hasCustomAddOnFlag) ?? false,
    loginLoading:false,
    showMessage:false,
    email:searchParams.get("email")?.replace(/ /g, "+")??"",
    customerId:searchParams.get("customerId")??"",
    showModal:false,
    step:null,
    storeName:"",
    websiteUrl:searchParams.get("websiteUrl")??"",
    storeUrl:searchParams.get("storeUrl") ??"",
    isAppInstalled:false

}


const authSlice = createSlice({
    name:"authInfo",
   initialState,
   reducers:{
    setProfileInfo:(state,{payload})=>{
      state.userDetails=payload
    },
    setLoading:(state,{payload})=>{
        state.loading=payload
    },
    setAuth:(state,{payload})=>{
        state.isAuth = payload
    },
    setInternalUser:(state,{payload})=>{
        state.isInternalUser = payload
    },
    setHasCustomAddOn:(state,{payload})=>{
        state.hasCustomAddon = payload
    },
    setLoginLoading:(state,{payload})=>{
        state.loginLoading = payload
    },
    setShowMessage:(state,{payload})=>{
        state.showMessage = payload
    },
    setEmail:(state,{payload})=>{
        state.email = payload
    },
    setCustomerId:(state,{payload})=>{
        state.customerId = payload
    },
    setShowModal:(state,{payload})=>{
        state.showModal = payload
    },
    setStep:(state,{payload})=>{
        state.step = payload
    },
    setStoreUrl:(state,{payload})=>{
        state.storeUrl = payload
    },
    setStoreDetails:(state,{payload})=>{     
        state.storeName = payload.storeName;
        state.websiteUrl = payload.websiteUrl
    },
    setIsAppInstalled:(state,{payload})=>{     
        state.isAppInstalled = payload;
       
    },
   }
})

export const {setProfileInfo,setLoading,setAuth,setInternalUser,setHasCustomAddOn,setLoginLoading,setShowMessage,setEmail,setCustomerId,setShowModal,setStep,setStoreDetails,setStoreUrl,setIsAppInstalled} = authSlice.actions;

export default authSlice.reducer