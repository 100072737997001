import React, {  useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {  verifyUser } from "services/authServices";
import { hasCustomAddOnFlag, INTERNAL_JWT_TOKEN, JWT_TOKEN, ROUTES } from "lib/Constants";
import { AUTH_TYPE, TOAST_TYPES } from "enum/enums";
import {  JwtTokenValidate, setLocalStorageData } from "lib/utils";
import ShowSuccessMessage from "component/showMessage/ShowMessage";
import LoadingScreen from "component/LoadingScreen";
import { useDispatch } from "react-redux";
import { setHasCustomAddOn, setInternalUser, setProfileInfo } from "store/auth/reducer";
import { HANDLE_AUTH } from "store/auth/action.types";
import { addToast } from "store/toaster/reducer";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const UserVerify = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [verificationFailed, setVerificationFailed] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {email} = useSelector((state:RootState)=>state.auth)

  async function handleVerifyUser(token: string) {
    setLoading(true);
    try {
      const response = await verifyUser(token);
      if (response?.data?.internal) {
        setLocalStorageData(INTERNAL_JWT_TOKEN, response?.data?.token);
        dispatch(setInternalUser(true));
        dispatch({type:HANDLE_AUTH,payload:AUTH_TYPE.LOGIN});
        navigate(ROUTES.CUSTOMERS_LIST);
      } else if (!response?.data?.internal) {
        setLocalStorageData(JWT_TOKEN, response?.data?.token);
        const decodedToken = JwtTokenValidate(response?.data?.token);
        dispatch(setProfileInfo(decodedToken?.properties));
        setLocalStorageData(hasCustomAddOnFlag,decodedToken?.properties?.hasCustomAddon);
        dispatch(setHasCustomAddOn(decodedToken?.properties?.hasCustomAddon));
        dispatch({type:HANDLE_AUTH,payload:AUTH_TYPE.LOGIN});
        navigate(ROUTES.HOME);
      }
    } catch (err: any) {
      if (err?.response?.status === 403) {
        const extractError = JSON.parse(err?.request?.response);  
        if (!extractError?.registered) {
          dispatch(addToast({message:extractError?.message,type:TOAST_TYPES.ERROR}))
          navigate(`${ROUTES.SIGNUP}/?email=${email}`)
        }else if((!extractError?.appInstalled && extractError?.customerId) && extractError?.registered){
           dispatch(addToast({message:extractError?.message,type:TOAST_TYPES.ERROR}))
           navigate(`${ROUTES.ONBOARDING_FIRST_STEP}/?email=${email}&customerId=${extractError?.customerId}`)
        }else{
          setErrorMessage(extractError?.message);
          setVerificationFailed(true);
        }
      } else {
        setVerificationFailed(true);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (searchParams.get("token") && searchParams.get("token") !== "null") {
      handleVerifyUser(searchParams.get("token") ?? "");
    } else {
      setLoading(false);
    }
  }, []);
   
  return (
    <>
      <div className="flex align-center justify-center userVerify w-full">
        {loading ? (
          <div className="flex align-center justify-center">
            <LoadingScreen size={30} />
          </div>
        ) : (
          <div className="flex align-center justify-center w-full">
            {verificationFailed &&  (
              <ShowSuccessMessage  verificationFailed={verificationFailed} errorMessage={errorMessage} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default UserVerify;
