import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.mjs',
//     import.meta.url,
//   ).toString();
 pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;
interface PdfViewerProps {
  fileUrl: string;
  handleTotalContent?:(val:number)=>void
}

const PdfViewer = ({ fileUrl,handleTotalContent }: PdfViewerProps) => {
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    handleTotalContent?.(numPages)
  };

  return (
    <div className='drawer-content'>
         <div className='drawer-content__scroll'>
      <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page className="pdfViewerCustom" pageNumber={pageNumber} />
      </Document>
      </div>
      <div className='drawer-content__nav'>
      
      <button disabled={pageNumber===1} onClick={()=>setPageNumber(pageNumber-1)}><ArrowBackIcon/></button>
      <p> Page {pageNumber} of {numPages} </p>
        <button disabled={pageNumber===numPages} onClick={()=>setPageNumber(pageNumber+1)}><ArrowForwardIcon/></button>
      
      </div>
    </div>
  );
};

export default PdfViewer;
