import { TOAST_TYPES } from "enum/enums";
import { handleErrorMessage } from "lib/utils";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import customService from "services/customService";
import { addToast } from "store/toaster/reducer";
import { setCustomFitData, setExportButtonDisabled, setLoading, setMeasurementEditable, setMeasurementsSubmitLoading, setNoteEditable, setNotesSubmitLoading } from "./reducer";
import { HANDLE_GET_CUSTOM_LIST, HANDLE_CUSTOM_MEASUREMENT_UPDATE, HANDLE_CUSTOM_SCAN_EXPORT, HANDLE_CUSTOM_NOTES_UPDATE, HANDLE_DEBOUNCE_CUSTOM_NOTES_UPDATE, HANDLE_DEBOUNCE_CUSTOM_MEASUREMENT_UPDATE } from "./action.types";


 function* getCustomDataList(action:{type:string,payload:{pageNo:number,pageSize:number}}):Generator {
    yield put(setLoading(true));
    try {
      const res:any = yield call(customService.customScan,action.payload.pageNo, action.payload.pageSize);
      yield put(setCustomFitData(res?.data));
    } catch (error) {
        yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
    } finally {
        yield put(setLoading(false));
    }
  }

  function* getCustomScanExport(action:{type:string,payload:{id:string,email:string}}):Generator {
    yield put(setExportButtonDisabled(true));
    try {
      const res:any = yield call(customService.exportScanData,action.payload.id, action.payload.email);
      yield put(addToast({ message:res?.data?.message , type: TOAST_TYPES.ERROR }));
    } catch (error) {
        yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
    } finally {
        yield put(setExportButtonDisabled(false));
    }
  }
  function* customMeasurementUpdate(action:{type:string,payload:{data:Record<string,any>,id:string}}):Generator {
    yield put(setMeasurementsSubmitLoading(true));
    try {
      yield call(customService.customMeasurementsUpdate,action.payload.data, action.payload.id);
      yield put(setMeasurementEditable(false))
     } catch (error) {
        yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
    } finally {
        yield put(setMeasurementsSubmitLoading(false));
    }
  }

  function* customNoteUpdate(action:{type:string,payload:{notes:string,id:string}}):Generator {
    yield put(setNotesSubmitLoading(true));
    try {
      yield call(customService.customUploadNotes,action.payload.notes, action.payload.id);
      yield put(setNoteEditable(false))
    } catch (error) {
        yield put(addToast({ message: handleErrorMessage(error), type: TOAST_TYPES.ERROR }));
    } finally {
        yield put(setNotesSubmitLoading(false));
    }
  }
  function* debounceCutomeNoteUpdate(action: { type: string; payload:{notes:string,id:string} }) {
    yield delay(2000);
    yield call(customNoteUpdate, { type: HANDLE_CUSTOM_NOTES_UPDATE, payload: action.payload });
  }
  function* debounceCutomeMeasurementUpdate(action: { type: string; payload:{data:Record<string,any>,id:string} }) {
    yield delay(2000);
    yield call(customMeasurementUpdate, { type: HANDLE_CUSTOM_MEASUREMENT_UPDATE, payload: action.payload });
  }
  function* customSaga(){
    yield takeLatest(HANDLE_GET_CUSTOM_LIST,getCustomDataList);
    yield takeLatest(HANDLE_CUSTOM_SCAN_EXPORT,getCustomScanExport);
    yield takeLatest(HANDLE_CUSTOM_MEASUREMENT_UPDATE,customMeasurementUpdate);
    yield takeLatest(HANDLE_CUSTOM_NOTES_UPDATE,customNoteUpdate);
    yield takeLatest(HANDLE_DEBOUNCE_CUSTOM_NOTES_UPDATE,debounceCutomeNoteUpdate);
    yield takeLatest(HANDLE_DEBOUNCE_CUSTOM_MEASUREMENT_UPDATE,debounceCutomeMeasurementUpdate);


  }
  export default customSaga