import React, { useCallback, useEffect, useState } from "react";
import styles from "./TryOnProduct.module.scss";
import CustomButton from "atoms/customButton/CustomButton";
import CustomRadio from "atoms/customRadio/CustomRadio";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import NoDataPlaceHolder from "component/NoDataPlaceHolder";
import LoadingScreen from "component/LoadingScreen";
import { useDispatch } from "react-redux";
import { HANDLE_GET_TRY_ON_FINE_TUNE, HANDLE_GET_TRY_ON_IMAGES_APPROVE } from "store/tryOn/action.types";

interface TryOnProductDetailsProps {
	onClose: () => void;
	statusValue: string;
	productName: string;
}

const TryOnProductDetails = ({ onClose, statusValue, productName }: TryOnProductDetailsProps) => {
	const { detailsData, detailsLoading, detailsError,progressLoading } = useSelector((state: RootState) => state.tryOn);
    const [isPerfect,setIsPerfect] = useState<boolean>(false)
    const [feedBack,setFeedBack] = useState<string>("")
	const dispatch = useDispatch()

	const handleFinetune=useCallback(()=>{
		dispatch({type:HANDLE_GET_TRY_ON_FINE_TUNE,payload:{selectedProductHandles:[productName],info:true,feedback:feedBack}})
	},[feedBack,productName])

	const handleApprove=useCallback(()=>{
		dispatch({type:HANDLE_GET_TRY_ON_IMAGES_APPROVE,payload:{data:[productName],info:false}})
	},[productName])

  useEffect(()=>{
   if(statusValue==="approved" || statusValue==="onboarded"){
	setIsPerfect(true)
   }
  },[statusValue])
  
	return (
		<div className={`${styles.selection} flex-column`}>
			<span className={`${styles.selection__close}`} role="button" onClick={onClose}>
				<Close />
			</span>
			{detailsLoading && (
				<div className="flex align-center justify-center h-full">
					<LoadingScreen height={false} />
				</div>
			)}
			{detailsError && !detailsLoading && (
				<div className="flex align-center justify-center h-full">
					<NoDataPlaceHolder message="No data to show" showOnlyMessage />
				</div>
			)}
			{!detailsLoading && !detailsError && (
				<div className={`${styles.selection__scroll}`}>
					<div className={`${styles.selection__images}`}>
							<div className={`${styles.selection__loopBox}`}>
							<h2>
                                    <span>{productName} </span>
                                    {statusValue==="fine_tuning" && <span style={{color:"#B93815",backgroundColor:"#FFF4ED"}} className={`${styles.selection__tone}`}>Fine tuning</span>}</h2>
								{Object.values(detailsData?.postFineTuningUrls).length>0  && <div className={`${styles.selection__imagesBox}`}>
									{statusValue!=="fine_tuning" && <p>Fine tuned version</p>}
									<ul className="flex-wrap">
                                    {Object.values(detailsData?.postFineTuningUrls)?.map((el: string) => (
											<li key={el}>
												<figure>
													<img src={el} alt="images" />
												</figure>
											</li>
										))}
									</ul>
									{statusValue !=="fine_tuning" && <div className={`${styles.selection__radio} flex-wrap justify-around`}>
										<CustomRadio value={isPerfect} simple name="approved" title="Go live" onChange={(e)=>{
											handleApprove()
											setIsPerfect(e.target.checked)}} />
										<CustomRadio value={!isPerfect} simple name="notApproved" title="Still not perfect" onChange={(e)=>setIsPerfect(!e.target.checked)} />
									</div>}
								</div>}
							</div>
							{!isPerfect && statusValue !=="fine_tuning" && Object.values(detailsData?.postFineTuningUrls).length>0 &&  <>
							<div className={`${styles.selection__textarea}`}>
								<textarea placeholder="Add details on why this product should be fine-tuned" value={feedBack} onChange={(e)=>setFeedBack(e.target.value)}></textarea>
							</div>
							<div className={`${styles.selection__button} flex justify-center`}>
								<CustomButton buttonText="Submit" className="button rounded sm" handleFunc={handleFinetune} disabled={progressLoading|| !feedBack.trim()} loading={progressLoading} />
							</div>
							</>}
							{Object.values(detailsData?.preFineTuningUrls).length > 0 && <div className={`${styles.selection__loopBox}`}>
								<div className={`${styles.selection__imagesBox}`}>
									<p>Previous Version</p>
									<ul className="flex-wrap">
                                    {Object.values(detailsData?.preFineTuningUrls)?.map((el: string) => (
											<li key={el}>
												<figure >
													<img src={el} alt="images" />
												</figure>
											</li>
										))}
									</ul>
								</div>
							</div>}
						</div>
				</div>
			)}
		</div>
	);
};

export default TryOnProductDetails;
