import { Dialog } from "@mui/material";
// import CustomButton from "atoms/customButton/CustomButton";
import Upload from "atoms/upload/Upload";
import React from "react";
import styles from "./SizingOnboardingChild.module.scss";
import rocket from "../../assets/images/rocket-02.svg";
import { useNavigate } from "react-router-dom";
import { ROUTES, TECHPACK_FILE_TYPE } from "lib/Constants";
import { HANDLE_BATCH_FILE_UPLOAD } from "store/techpack/action.types";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import LinearBuffer from "component/analyticTryOnCards/ProgressLoading";
import CustomButton from "atoms/customButton/CustomButton";
// import { Close } from "@mui/icons-material";
import closeIcon from "../../assets/images/x-close.svg";
import FileCard from "component/fileCard/FileCard";

interface SizingBatchUploadProps{ 
	open:boolean; 
	handleClose:()=>void;
	showRedirect?:boolean;
	isToParse?:boolean;
	showFiles?:boolean;
	showDone?:boolean
}

const SizingBatchUpload = ({ open, handleClose,showRedirect=true,isToParse=true,showFiles=false,showDone=false }: SizingBatchUploadProps) => {
	const navigate = useNavigate();
	const dispatch= useDispatch()
	const {batchLoading,batchFileUploaded,uploadedFiles} = useSelector((state:RootState)=>state.techpack)
	const handleFileOnChange = (files:File[]) => {
		const subFiles = files?.reduce((acc:File[],file:File)=>{
			if (file.type === TECHPACK_FILE_TYPE.PDF || file.type === TECHPACK_FILE_TYPE.XLSX){
              acc.push(file)
			}
			return acc
		},[])
		dispatch({ type: HANDLE_BATCH_FILE_UPLOAD, payload: {files:subFiles,isToParse:isToParse} });
	};

	return (
		<Dialog open={open}>
			<div className={`${styles.batchUpload}`}>
            <span className={`${styles.batchUpload__close}`} role="button" onClick={handleClose}><img src={closeIcon} alt="close" /></span>
				<div className={`${styles.batchUpload__header}`}>
					<img src={rocket} alt="icon" />
					<h3>Formatting Done</h3>
					<p>Now, add all your other tech packs. Swan will process them & email you when ready for review.</p>
				</div>
				{ batchLoading ?<div className={`${styles.batchUpload__UploadedBar} progress-bar large`}>
								<LinearBuffer value={0} />
							</div>:<>
                           {(batchFileUploaded && !showFiles) ? <div className={styles.batchUpload__Uploaded}><p>Thank you for uploading. SWAN will process it and notify you over email once done.</p></div>  :<div className={styles.batchUpload__Upload}><Upload id="fileMultiInput" accept=".pdf, .xls, .xlsx" handleFileOnChange={handleFileOnChange} subHeading="PDF or .xlsx" multiple isDragAndDrop /></div>}
				{showRedirect && <div className={`${styles.batchUpload__Btn}`}>
					<span
						role="button"
						onClick={() => {
							handleClose?.();
							navigate(ROUTES.PRODUCTS);
						}}
					>
						Save to continue later
					</span>
				</div>}
				</>}
				{showFiles && <div className={`${styles.batchUpload__Btn}`}>
					{uploadedFiles?.map((file:File,index:number)=>
						// <div className={styles.batchUpload__file} key={index}>
						// 	<span>{file?.name ?? ""}</span>
						// 	<Close />
						// </div>
						<FileCard file={file} key={index}/>
					)}
				</div>}
				{showDone && batchFileUploaded &&  <div className={`${styles.batchUpload__Btn} flex justify-end`}>
					<CustomButton className="button rounded sm" buttonText="Done" handleFunc={handleClose}/>
				</div>}
			</div>
		</Dialog>
	);
};

export default SizingBatchUpload;
