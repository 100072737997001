

import styles from './HomeSizingOnboarding.module.scss';
import * as React from 'react';
import sizing from 'assets/images/sizing-icon.png';
import arrow from 'assets/images/blue-arrow.svg';
import { Link } from 'react-router-dom';
import { ROUTES } from 'lib/Constants';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';





const HomeSizingOnboarding = () => {
  const {onboardingSummaryData} = useSelector((state:RootState)=>state.product)

    return (<>
        <div className={`${styles.sizing} flex-wrap justify-between`}>
         <figcaption>
            <h3>SIZING </h3>
            <p>Reduce returns with accurate sizing for all customers.</p>
            <Link to={ROUTES.SIZING_START}>
            <>
          {onboardingSummaryData?.sizingOnboardedCount > 0 ? <span  className={`${styles.sizing__button}`} role='button'> 
            <span>
            Open <img src={arrow} alt='arrow' />
            </span></span> :<span  className={`${styles.sizing__button}`} role='button'> 14 min onboarding 
            <span>
            Start   <img src={arrow} alt='arrow' />
            </span></span> }
            </>
            </Link>
         </figcaption>
         <figure className='flex align-center justify-center'>
         <img src={sizing} alt='tryOn' />
         </figure>
        </div>

    </>)
}
export default HomeSizingOnboarding;