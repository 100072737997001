import React from "react";
import singUp from "assets/images/singUp.svg";
import { ShowSuccessMessageProps } from "interface/interface";
import CustomButton from "atoms/customButton/CustomButton";

function ShowSuccessMessage({
  registerSuccess = false,
  verificationFailed = false,
  fileUploadSuccess = false,
  errorMessage = "",
  customMessageFlag = false,
}: ShowSuccessMessageProps) {
  return (
    <div className="success-message">
      {!verificationFailed && (
        <div>
          {!customMessageFlag && (
            <>
              <img className="top-img" src={singUp} alt="Swan" />
              <h2>Congrats</h2>
            </>
          )}

          <div>
            {fileUploadSuccess && (
              <div>
                <p>
                  {`Your tech pack is in review at the moment.`} <br /> {`Our team will get back to you with 24 hours or 48 hours .`}
                </p>
              </div>
            )}
          </div>
          <div>
            {registerSuccess && (
              <div>
                <p>You are successfully registered now go to shopify and download our app .</p>
              </div>
            )}
          </div>
        </div>
      )}
      {verificationFailed && (
        <div>
          <img className="top-img" src={singUp} alt="Swan" />
          {errorMessage && <h2 className="success-title">App not installed</h2>}
          <p> {errorMessage ? errorMessage : "Email verification failed or token not found."}</p>
        </div>
      )}

      {(registerSuccess || errorMessage) && (
        <CustomButton
          type="button"
          buttonText="Go to shopify"
          className="button"
          handleFunc={() => (window.location.href = "https://accounts.shopify.com/")}
        />
      )}
    </div>
  );
}

export default ShowSuccessMessage;
