import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { HANDLE_GET_CUSTOM_LIST } from 'store/custom/action.types';
import styles from "./Custom.module.scss"
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import LoadingScreen from 'component/LoadingScreen';
import CustomGroup from 'component/customGroup/CustomGroup';

const Custom = () => {
    const [pageNo] = useState<number>(1);
    const dispatch = useDispatch();
    const { loading } = useSelector((state: RootState) => state.custom)

    useEffect(() => {
        dispatch({ type: HANDLE_GET_CUSTOM_LIST, payload: { pageNo, pageSize: 100 } })
    }, [pageNo])
    
    return (
        <div className={`${styles.custom}`}>

            {loading && (
                <div className="flex align-center justify-center h-full">
                    <LoadingScreen height={false} />
                </div>
            )}
            {!loading && <>
                {/* <div className={`${styles.custom__topGradient}`}>
                    <img src={topHeader} alt="header" />
                </div> */}
                <div className={`${styles.custom__cardMain}`}>
                    <CustomGroup  />

                </div>
            </>}

        </div>
    )
}

export default Custom