import Custom from 'pages/custom/Custom';
import Home from 'pages/home/Home';
import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import DashboardWrapper from './dashboard/Dashboard';

const LandingPage = () => {
const { hasCustomAddon } = useSelector((state: RootState) => state.auth);

  return <DashboardWrapper title={hasCustomAddon ? "Customers" : "Home"}>{hasCustomAddon ? <Custom/> :<Home/>}</DashboardWrapper>
}

export default LandingPage