import React from "react";
import loaderImage from "../assets/images/loader.gif";

const LoadingScreen = ({ size = 100, height = true, customClass }: { size?: number; height?: boolean,customClass?:string }) => {
  return (
    <div className={`flex align-center justify-center ${customClass} ${height ? "h-100" : ""} loader`}>
      <img src={loaderImage} alt="Swan logo" width={size} />
    </div>
  );
};

export default LoadingScreen;
