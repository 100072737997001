import CustomButton from "atoms/customButton/CustomButton";
import styles from './Signup.module.scss';
import Input from "atoms/input/Input";
import { SubmitHandler, useForm } from "react-hook-form";
import React from "react";
import { Link, useNavigate,  } from "react-router-dom";
import { ROUTES } from "lib/Constants";
import { isValidEmail } from "lib/utils";
import { useDispatch } from "react-redux";
import { setEmail } from "store/auth/reducer";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
interface SignUpForm{
    email:string
}
const Signup = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {email ,loginLoading } = useSelector((state:RootState)=>state.auth)
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError
    } = useForm<SignUpForm>({
        defaultValues:{
            email:email
        }
    });

const onSubmit: SubmitHandler<SignUpForm>=async(data)=>{
  const {email} = data;
  if(!isValidEmail(email.trim())){
    setError("email", { type: "custom", message: "Please enter a valid email" });
  }else{
    dispatch(setEmail(email))
    navigate(`${ROUTES.ONBOARDING_FIRST_STEP}/?email=${email}`)
  }

}

    return (
        <div className={`${styles.steps}`}>
            <div className={`${styles.steps__info}`}>
                <div className={`${styles.steps__wrapper}`}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="step1 ">
                        <h1><span>Welcome to Swan </span>Sell more clothes, without the returns.</h1>
                        <Input
                            type="text"
                            label="Email"
                            register={register}
                            inputName="email"
                            singleInput
                            errorMessage={errors.email ? (errors.email.message ? errors.email.message : "This Field is required") : ""}
                        />
                        <div className={` ${styles.steps__button}`}>
                            <CustomButton type="submit" className="full rounded semibold" buttonText="Get started" disabled={loginLoading} />
                        </div>
                        
                        <div className={` ${styles.steps__accountText}`}>
                            Already have an account? <span role="button"><Link to={ROUTES.LOGIN}>Log in</Link></span>
                        </div>
                    </div>
                    </form>
                </div>

            </div>
        
         </div>
        
    )
}
export default Signup;
