import { TableBody, TableCell, TableRow } from "@mui/material";
import { camelCaseToWords, dateFormat } from "lib/utils";
import React from "react";
import openIcon from "assets/images/open-icon.svg";


interface CustomListTableRowsProps {
	rows: any[];
    handleSelectedCustom?:(row:any)=>void
}

const CustomListTableRows = ({ rows,handleSelectedCustom }: CustomListTableRowsProps) => {
	return (
		<TableBody>
			{rows?.map((row,index) => {
				const labelId = `enhanced-table-checkbox-${row?.id}`;
				return (
					<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        <TableCell>
                        <div className="flex align-center">
								<p> {index+1}</p>
							</div>
                        </TableCell>
						<TableCell component="td" id={labelId} role="cursor" scope="row" padding="none" onClick={() => handleSelectedCustom?.(row)}>
                        <div className={`flex justify-between align-start productCell`}>
														<span>{row?.profile?.userName || "-"}</span>
                                                        <span
                            role="button"
                            className={`openButton`}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                handleSelectedCustom?.(row);
                            }}
                        >
                            <img src={openIcon} alt="icon" /> OPEN
                        </span>
													</div>
						
						</TableCell>
						<TableCell component="td" id={labelId} scope="row" padding="none">
							<div className="flex align-center">
								<p>{row?.profile?.userEmail || "-"}</p>
							</div>
						</TableCell>
                        {/* <TableCell>
                        <div className="flex align-center">
								<p>Scanned</p>
							</div>
                        </TableCell> */}
						<TableCell component="td" id={labelId} scope="row" padding="none">
							<div className="flex align-center">
								<span className={`tableChip ${row?.profile?.gender === "female" ? "chipRed" : ``}`}>{camelCaseToWords(row?.profile?.gender)}</span>
							</div>
						</TableCell>
						<TableCell component="td" id={labelId} scope="row" padding="none">
							<div className="flex align-center ">
								<p>{Object.keys(row?.measurements).length || 0}</p>
							</div>
						</TableCell>
						<TableCell component="td" id={labelId} scope="row" padding="none">
							<div className="flex align-center date">
								<p>{dateFormat(row?.profile?.scannedAt)}</p>
							</div>
						</TableCell>
						{/* <TableCell component="td" id={labelId} scope="row" padding="none">
							<div className="flex align-center">
								<p className="note">{row?.notes?.notes || "-"}</p>
							</div>
						</TableCell> */}

						{/* <TableCell component="td" id={labelId} scope="row" padding="none">
							<div role="cursor" className="action">
								<img
									src={edit}
									alt="edit"
									onClick={() => {
										handleSelectedCustom?.(row);
									}}
								/>
							</div>
						</TableCell> */}
					</TableRow>
				);
			})}
		</TableBody>
	);
};

export default CustomListTableRows;
