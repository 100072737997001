import React  from 'react';
import styles from './CustomDetailsChild.module.scss';
// import arrow from 'assets/images/arrow-up-right-blue.svg';
// import sheet from 'assets/images/google-sheets.png';
// import mail from 'assets/images/mail.svg';
// import CustomButton from 'atoms/customButton/CustomButton';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { camelCaseToWords, dateFormat } from 'lib/utils';
// import { useDispatch } from 'react-redux';
// import { HANDLE_CUSTOM_SCAN_EXPORT } from 'store/custom/action.types';



const CustomNameDetailLeft = () => {
// const {userDetails} = useSelector((state:RootState)=>state.auth)
const {selectedValue} = useSelector((state:RootState)=>state.custom);
// const dispatch= useDispatch()
  return (
    <div className={` ${styles.detailLeft}`}>
        <div className={` ${styles.detailLeft__boxBorder}`}>
          <div className={` ${styles.detailLeft__header}`}>
            <h2>{selectedValue?.profile?.userName || '-'}</h2>
          </div>
          <div className={` ${styles.detailLeft__list}`}>
            <h4>Email</h4>
            <p>
              <a className="flex" href={`mailto:${selectedValue?.profile?.userEmail}`}>
                {selectedValue?.profile?.userEmail || '-'} 
              </a>
            </p>
          </div>
          <div className={` ${styles.detailLeft__list}`}>
            <h4>Gender</h4>
            <p>{camelCaseToWords(selectedValue?.profile?.gender)}</p>
          </div>
          <div className={` ${styles.detailLeft__list}`}>
            <h4>Last Scanned on</h4>
            <p>{dateFormat(selectedValue?.profile?.scannedAt)}</p>
          </div>
          {/* <div className={` ${styles.detailLeft__list}`}>
            <h4>Location</h4>
            <p className="flex">London, UK</p>
          </div> */}
       
       
         
        </div>
        {/* <div className={` ${styles.detailLeft__boxBorder}`}>
          <div className={` ${styles.detailLeft__header}`}>
            <h2>Export</h2>
          </div>
          <div className={`flex align-start justify-between ${styles.detailLeft__excelBox}`}>
            <div className={`flex alignStart ${styles.detailLeft__excelBoxLeft} `}>
              <figure>
                <img src={sheet} alt="icon" />
              </figure>
              <figcaption>
                <h5>Google Sheets</h5>
                <p>Standard template</p>
                <p>
                  <a href="mailto:brand@brand.com">
                    <img src={mail} alt="main" />
                    {userDetails?.email ?? ''}
                  </a>
                </p>
              </figcaption>
            </div>
            <div className={` ${styles.detailLeft__buttonBottom} `}>
              <CustomButton buttonText="Export" className={'outline button gray'} disabled={exportButtonDisabled} loading={exportButtonDisabled} handleFunc={()=>dispatch({type:HANDLE_CUSTOM_SCAN_EXPORT,payload:{id:selectedValue?.id,email:userDetails?.email}})}  />
            </div>
          </div>
        </div> */}
        </div>
  );
};

export default CustomNameDetailLeft;
