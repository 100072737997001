
const ArrowRight = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg"
                fill="currentColor" height="10px" width="10px" version="1.1" id="Capa_1" viewBox="0 0 296.375 296.375"
                stroke="currentColor"
                strokeWidth="0.0029637500000000002"
            >
                <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                <g id="SVGRepo_iconCarrier"> <polygon points="215.709,49.188 165.041,49.188 224.686,123.188 0,123.188 0,173.188 226.005,173.188 165.041,247.188 215.709,247.188 296.375,149.27 " /> </g>
            </svg>
        </>
    );
};

export default ArrowRight;
