import { API_END_POINTS } from "lib/Constants"
import UseApiService from "./apiService";
import { TOKEN_DATA_TYPE } from "enum/enums";

class CustomService{
    customScan = (pageNum = 1, pageSize = 10) => {
        const body = {
          url: API_END_POINTS.CUSTOM.customFit,
          headerToken: TOKEN_DATA_TYPE.ACCESS,
          data: {
            pageNum,
            pageSize,
          },
        }
        return UseApiService().get(body);
    }
    customMeasurementsUpdate = (data: Record<string,any>, scanId: string) => {
        const body = {
          url: API_END_POINTS.CUSTOM.customFitMeasurements,
          params:{
            scanId
          },
          data: {
            measurements: data,
          },
          headerToken: TOKEN_DATA_TYPE.ACCESS,
        };
        return UseApiService().post(body);
      };
    exportScanData = (scanId: string, email = '') => {
        const body = {
          url: API_END_POINTS.CUSTOM.customFitExport,
          params: {
            scanId,
            email,
          },
          headerToken: TOKEN_DATA_TYPE.ACCESS,
        };
        return UseApiService().post(body);
      };
      customUploadNotes = (data: string, scanId: string) => {
        const body = {
          url: API_END_POINTS.CUSTOM.customFitNotes,
          data: {
            notes: data,
          },
          params:{
            scanId
          },
          headerToken: TOKEN_DATA_TYPE.ACCESS,
        };
        return UseApiService().post(body);
      };
      
}

const customService = new CustomService();
export default customService;