import { TOKEN_DATA_TYPE } from "enum/enums";
import UseApiService from "./apiService";
import { API_END_POINTS } from "lib/Constants";
import axios from "axios";
import { SubmitFeedbackPayload, TechpackparsePayload, UpdateTechpackparsePayload } from "interface/interface";

class TechpackService {
	getTechpackList(): Promise<any> {
		const body = {
			url: API_END_POINTS.TECHPACK.getList,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
		};
		return UseApiService().get(body);
	}
	uploadTechpackFile(fileName: string,batch="") {
		const body = {
			url: API_END_POINTS.TECHPACK.uploadTechpackFile,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
			data: {
				uploadType: "techpackFile",
				file: fileName,
				...(batch !== null && batch !== undefined && batch !== '' && { batch })
			},
		};
		return UseApiService().get(body);
	}
	uploadFileAWSBucket = (file: File, url: string) => {
		return axios.put(url, file, {
			headers: {
				"Content-Type": file?.type,
			},
		});
	};
	getUploadedFileUrl(techpackFileId: string, fileName: string): Promise<any> {
		const body = {
			url: API_END_POINTS.TECHPACK.uploadedFileUrl,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
			data: {
				uploadType: "techpackFile",
				file: fileName,
				techpackFileId,
			},
		};
		return UseApiService().get(body);
	}

	techpackParse(data: TechpackparsePayload | {techpackFileBatchId:string}) {
		const body = {
			url: API_END_POINTS.TECHPACK.techpackParse,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
			data,
		};
		return UseApiService().post(body);
	}
	updateTechpackParse(data: UpdateTechpackparsePayload) {
		const body = {
			url: API_END_POINTS.TECHPACK.updateTechpackParseDebounce,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
			data,
		};
		return UseApiService().post(body);
	}
	updateTechpack(data: Record<string,any>) {
		const body = {
			url: API_END_POINTS.TECHPACK.techpack,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
			data,
		};
		return UseApiService().post(body);
	}
	getTechpackParseInfo(techPackId:string) {
		const body = {
			url: `${API_END_POINTS.TECHPACK.updateTechpackParse}/${techPackId}`,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
		};
		return UseApiService().get(body);
	}
	submitFeedbackTechpack(data:SubmitFeedbackPayload){
		const body = {
			url: API_END_POINTS.TECHPACK.submitFeedback,
			headerToken: TOKEN_DATA_TYPE.ACCESS,
			data,
		}
		return UseApiService().post(body)
	}
}

const techpackService = new TechpackService();
export default techpackService;
