
const Check = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
<path d="M8.33073 1.5L3.7474 6.08333L1.66406 4" 
stroke="currentColor" 
strokeWidth="1.66667" 
strokeLinecap="round"
strokeLinejoin="round"
/>
</svg>
        </>
    );
};

export default Check;
