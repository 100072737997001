const PlusIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                <path d="M7.4974 1.16406V12.8307M1.66406 6.9974H13.3307"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>

        </>
    );
};

export default PlusIcon;
