

const ArrowDown = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
<path d="M1 1L5.90057 5.66666L10.8011 1" 
stroke="currentColor" 
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round"
/>
</svg>
        </>
    );
};

export default ArrowDown;
